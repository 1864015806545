import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bl-config-generator',
  template: '<bl-app-layout-generator></bl-app-layout-generator>'
})
export class ConfigGeneratorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
