import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-pressure',
  templateUrl: './graphical-pressure.component.html',
  styleUrls: ['./graphical-pressure.component.css']
})
export class GraphicalPressureComponent implements OnInit {
  @Input() reading$: Observable<{ data: any; found: boolean }>;
  faTachometerAlt = faTachometerAlt;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
