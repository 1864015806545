import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-temperature',
  templateUrl: './graphical-temperature.component.html',
  styleUrls: ['./graphical-temperature.component.css']
})
export class GraphicalTemperatureComponent implements OnInit {
  @Input() reading$: Observable<{ data: any; found: boolean }>;
  faThermometerHalf = faThermometerHalf;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
