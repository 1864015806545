import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TempComponent } from './temp/temp.component';
import { ConfigGeneratorComponent } from './config-generator/config-generator';
import { AuthGuard } from '@bertlabs-nova/authentication';
import { AppShellComponent } from './app-shell.component';
import { AppShellResolver } from './app-shell.resolver';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { EquipmentTemplateGeneratorComponent } from './equipment-template-generator/equipment-template-generator.component';
import { GraphicsTemplateGeneratorComponent } from './graphics-template-generator/graphics-template-generator.component';
import { VisualLayerViewerComponent } from './visual-layer-viewer/visual-layer-viewer.component';
import { AHU108ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-108.component';
// import { AHU101ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-111.component';
import { AHU103ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-103.component';
import { AHU104ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-104.component';
import { AHU106ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-106.component';
import { AHU107ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-107.component';
import { AHU109ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-109.component';
import { AHU110ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-110.component';
import { AHU123ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-123.component';
import { AHU114ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-114.component';
import { AHU112ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-112.component';
import { AHU113ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-113.component';
import { AHU115ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-115.component';
import { AHU116ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-116.component';
import { AHU117ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-117.component';
import { AHU118ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-118.component';
import { AHU119ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-119.component';
import { AHU120ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-120.component';
import { AHU121ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-121.component';
import { AHU122ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-122.component';
import { AHU141ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-140-141.component';
import { AHU124ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-124.component';
import { AHU125ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-125.component';
import { AHU126ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-126.component';
import { AHU127ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-127.component';
import { AHU128ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-128.component';
import { AHU129ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-129.component';
import { AHU131ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-131.component';
import { AHU132ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-132.component';
import { AHU133ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-133.component';
import { AHU134ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-134.component';
import { AHU135ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-135.component';
import { AHU137ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-137.component';
import { AHU136ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-136.component';
import { AHU139ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-139.component';
import { AHU142ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-142.component';
import { AHU144ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-144.component';
import { AHU146ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-146.component';
import { AHU155ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-155.component';
import { AHU162ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-162.component';
import { AHU163ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-163.component';
import { AHU165ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-165.component';
import { AHU166ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-166.component';
import { AHU167ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-167.component';
import { AHU168ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-168.component';
import { AHU169ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-169.component';
import { AHU170ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-170.component';
import { AHU171ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-171.component';
import { AHU175ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-175.component';
import { AHU174ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-174.component';
import { AHU173ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-173.component';
import { AHU177ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-177.component';
import { AHU178ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-178.component';
import { AHU179ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-179.component';
import { AHU111ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-111.component';
import { AHU212ClassComponent } from './laurus-labs/ahu-screens/ahu-screen-212.component';
import { AHUCompressionComponent } from './laurus-labs/ahu-screens/common-components/ahu-compression-home.component';
import { AHUCompressionAndBlendingComponent } from './laurus-labs/ahu-screens/common-components/ahu-compression-blending.component';
import { AHUDryGranulationComponent } from './laurus-labs/ahu-screens/common-components/ahu-dry-granulation.component';
import { AHUGranulationComponent } from './laurus-labs/ahu-screens/common-components/ahu-granulation.component';
import { AHUGranulationAndBlendingComponent } from './laurus-labs/ahu-screens/common-components/ahu-granulation-and-blending.component';
import { AHUHomeScreenComponent } from './laurus-labs/ahu-screens/common-components/ahu-home-screen.component';
import { AHUBlendingComponent } from './laurus-labs/ahu-screens/common-components/ahu-blending-screen.component';
import { AHUTabletCoatingComponent } from './laurus-labs/ahu-screens/common-components/ahu-tablet-coating.component';

const appRoutes: Routes = [
  // auth route of "authenticate" added by auth module automatically
  {
    path: '',
    redirectTo: 'app/ahu-home',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'app',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    // resolve: {
    //   data: AppShellResolver
    // },
    children: [
      // {
      //   path: '',
      //   redirectTo: 'ahu-108',
      //   component: TempComponent
      // },
      // {
      //   path: 'config-generator',
      //   component: ConfigGeneratorComponent
      // },
      // {
      //   path: 'app-layout',
      //   component: AppLayoutComponent
      // },
      // {
      //   path: 'common',
      //   component: AHUControlComponent
      // },
      // {
      //   path: 'chiller-home',
      //   component: ChillerHomeComponent
      // },
      // {
      //   path: 'chiller-plant',
      //   component: ChillerPlantComponent
      // },
      // {
      //   path: 'cooling-tower',
      //   component: CoolingTowersComponent
      // },
      // {
      //   path: 'chiller-1',
      //   component: ChillerScreenClassComponent
      // },
      // {
      //   path: 'cooling-tower-1',
      //   component: CoolingTowerMainComponent
      // },
      // {
      //   path: 'primary-pump',
      //   component: PrimaryPumpComponent
      // },
      // {
      //   path: 'secondary-pump',
      //   component: PrimaryPumpComponent
      // },
      // {
      //   path: 'equipment-template-generator',
      //   component: AHU141ClassComponent
      // },
      {
        path: 'ahu-compression',
        component: AHUCompressionComponent
      },
      {
        path: 'ahu-compression-blending',
        component: AHUCompressionAndBlendingComponent
      },
      {
        path: 'ahu-dry-granulation',
        component: AHUDryGranulationComponent
      },
      {
        path: 'ahu-granulation',
        component: AHUGranulationComponent
      },
      {
        path: 'ahu-granulation-and-blending',
        component: AHUGranulationAndBlendingComponent
      },
      {
        path: 'ahu-home',
        component: AHUHomeScreenComponent
      },
      {
        path: 'blending',
        component: AHUBlendingComponent
      },
      {
        path: 'granulation-blending',
        component: AHUDryGranulationComponent
      },
      {
        path: 'tablet-coating',
        component: AHUTabletCoatingComponent
      },
      {
        path: 'compression-tablet-coating',
        component: AHUCompressionAndBlendingComponent
      },
      // {
      //   path: 'ahu-123',
      //   component: AHU123ClassComponent
      // },
      // {
      //   path: 'ahu-111',
      //   component: AHU111ClassComponent
      // },
      // {
      //   path: 'ahu-103',
      //   component: AHU103ClassComponent
      // },
      // {
      //   path: 'ahu-104',
      //   component: AHU104ClassComponent
      // },
      // {
      //   path: 'ahu-106',
      //   component: AHU106ClassComponent
      // },
      // {
      //   path: 'ahu-107',
      //   component: AHU107ClassComponent
      // },
      {
        path: 'ahu-108',
        component: AHU108ClassComponent
      },
      {
        path: 'ahu-103',
        component: AHU103ClassComponent
      },
      {
        path: 'ahu-109',
        component: AHU109ClassComponent
      },
      {
        path: 'ahu-110',
        component: AHU110ClassComponent
      },
      {
        path: 'ahu-111',
        component: AHU111ClassComponent
      },
      {
        path: 'ahu-112',
        component: AHU112ClassComponent
      },
      {
        path: 'ahu-113',
        component: AHU113ClassComponent
      },
      {
        path: 'ahu-171',
        component: AHU171ClassComponent
      },
      // {
      //   path: 'ahu-114',
      //   component: AHU114ClassComponent
      // },
      // {
      //   path: 'ahu-115',
      //   component: AHU115ClassComponent
      // },
      // {
      //   path: 'ahu-116',
      //   component: AHU116ClassComponent
      // },
      {
        path: 'ahu-117',
        component: AHU117ClassComponent
      },
      {
        path: 'ahu-118',
        component: AHU118ClassComponent
      },
      {
        path: 'ahu-119',
        component: AHU119ClassComponent
      },
      // {
      //   path: 'ahu-120',
      //   component: AHU120ClassComponent
      // },
      {
        path: 'ahu-121',
        component: AHU121ClassComponent
      },
      {
        path: 'ahu-122',
        component: AHU122ClassComponent
      },
      // {
      //   path: 'ahu-124',
      //   component: AHU124ClassComponent
      // },
      // {
      //   path: 'ahu-125',
      //   component: AHU125ClassComponent
      // },
      // {
      //   path: 'ahu-126',
      //   component: AHU126ClassComponent
      // },
      // {
      //   path: 'ahu-127',
      //   component: AHU127ClassComponent
      // },
      // {
      //   path: 'ahu-128',
      //   component: AHU128ClassComponent
      // },
      // {
      //   path: 'ahu-129',
      //   component: AHU129ClassComponent
      // },
      // {
      //   path: 'ahu-131',
      //   component: AHU131ClassComponent
      // },
      // {
      //   path: 'ahu-132',
      //   component: AHU132ClassComponent
      // },
      // {
      //   path: 'ahu-133',
      //   component: AHU133ClassComponent
      // },
      // {
      //   path: 'ahu-134',
      //   component: AHU134ClassComponent
      // },
      // {
      //   path: 'ahu-135',
      //   component: AHU135ClassComponent
      // },
      // {
      //   path: 'ahu-136',
      //   component: AHU136ClassComponent
      // },
      // {
      //   path: 'ahu-137',
      //   component: AHU137ClassComponent
      // },
      // {
      //   path: 'ahu-139',
      //   component: AHU139ClassComponent
      // },
      // {
      //   path: 'ahu-140',
      //   component: AHU141ClassComponent
      // },
      // {
      //   path: 'ahu-142',
      //   component: AHU142ClassComponent
      // },
      // {
      //   path: 'ahu-143',
      //   component: AHU134ClassComponent
      // },
      // {
      //   path: 'ahu-144-145',
      //   component: AHU144ClassComponent
      // },
      // {
      //   path: 'ahu-146',
      //   component: AHU146ClassComponent
      // },
      // {
      //   path: 'ahu-155',
      //   component: AHU155ClassComponent
      // },
      // {
      //   path: 'ahu-162',
      //   component: AHU162ClassComponent
      // },
      // {
      //   path: 'ahu-163',
      //   component: AHU163ClassComponent
      // },
      // {
      //   path: 'ahu-165',
      //   component: AHU165ClassComponent
      // },
      // {
      //   path: 'ahu-166',
      //   component: AHU166ClassComponent
      // },
      // {
      //   path: 'ahu-167',
      //   component: AHU167ClassComponent
      // },
      {
        path: 'ahu-168',
        component: AHU168ClassComponent
      },
      {
        path: 'ahu-169',
        component: AHU169ClassComponent
      },
      // {
      //   path: 'ahu-170',
      //   component: AHU170ClassComponent
      // },
      // {
      //   path: 'ahu-171',
      //   component: AHU171ClassComponent
      // },
      {
        path: 'ahu-173',
        component: AHU173ClassComponent
      },
      {
        path: 'ahu-174',
        component: AHU174ClassComponent
      },
      // {
      //   path: 'ahu-176',
      //   component: AHU175ClassComponent
      // },
      // {
      //   path: 'ahu-177',
      //   component: AHU177ClassComponent
      // },
      // {
      //   path: 'ahu-178',
      //   component: AHU178ClassComponent
      // },
      // {
      //   path: 'ahu-179',
      //   component: AHU179ClassComponent
      // }
      {
        path: 'ahu-212',
        component: AHU212ClassComponent
      }
    ]
  }
  // {
  //   path: 'graphics-template-generator',
  //   component: GraphicsTemplateGeneratorComponent
  // },
  // {
  //   path: "vl",
  //   component: VisualLayerViewerComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class AppShellRoutingModule { }
