import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppLayoutGeneratorService } from '../app-layout-generator.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { WrapperTypes, IEquipmentInfo } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.css']
})
export class AddEditModalComponent implements OnInit, OnDestroy {
  sub: Subscription;
  sub2: Subscription;
  data: IEquipmentInfo | null;
  id: string | null;
  selector: string[];
  equipmentTemplates: { label: string; key: string }[];
  @ViewChild('form') form: NgForm;

  get endpointsString() {
    let string = '';
    if (!this.data.endpoints) return '';
    Object.keys(this.data.endpoints).forEach(ep => {
      string += ep + '@' + this.data.endpoints[ep] + ',';
    });
    string = string.slice(0, string.length - 1);
    return string;
  }

  makeEndpointObject() {
    if (this.form.value.endpoints) {
      const epObj = {};
      const eps = this.form.value.endpoints.split(',');
      eps.forEach(ep => {
        const [placeholder, value] = ep.split('@');
        epObj[placeholder.trim()] = value.trim();
      });
      return epObj;
    } else return {};
  }

  submit() {
    const changedChildType =
      this.data['child-type'] !== this.form.value.childType;
    // edit the information of a wrapper using a service method
    if (this.id) {
      const newInfo = {
        label: this.form.value.label,
        clickable: this.form.value.clickable,
        type: this.form.value.type,
        parent: this.data.parent,
        key: this.data.key,
        'child-type': this.form.value.childType
      };

      if (this.form.value.image) {
        console.log('image found');
        newInfo['image'] = this.form.value.image.split(',');
      }

      if (
        this.data.type === WrapperTypes.equipment ||
        this.data.type === WrapperTypes.side ||
        this.data.type === WrapperTypes.home
      ) {
        console.log(this.form.value.equipmentTemplate);
        newInfo['equipmentTemplate'] = this.form.value.equipmentTemplate;
        newInfo['endpoints'] = this.makeEndpointObject();
      }

      if (this.data.type === WrapperTypes.home) {
        newInfo['graphicsTemplate'] = this.form.value.equipmentTemplate;
      }

      this.service.editInfo(this.id, newInfo);
      if (changedChildType && this.selector) {
        this.service
          .getChildren(this.selector)
          .pipe(take(1))
          .subscribe(children => {
            children.forEach(child => {
              this.service
                .getInfo([...this.selector, child])
                .pipe(take(1))
                .subscribe(info => {
                  this.service.editInfo(child, {
                    ...info,
                    type: this.form.value.childType,
                    'child-type':
                      this.form.value.childType === WrapperTypes.equipment
                        ? WrapperTypes.none
                        : this.form.value.childType
                  });
                });
            });
          });
      }
    }
    this.close();
  }

  close() {
    this.service.closeEditModal();
  }

  constructor(public service: AppLayoutGeneratorService) {}

  ngOnInit(): void {
    this.sub2 = this.service.equipmentTemplates$.pipe().subscribe(templates => {
      console.log(templates);
      this.equipmentTemplates = templates;
    });
    // modal link gives the selector for the wrapper that we want to edit
    // it can also emit null value, to close the modal
    this.sub = this.service.modalLink$.subscribe(selector => {
      if (selector) {
        // if selector exists, we get data using the selector from the service
        this.service
          .getInfo(selector)
          .pipe(take(1))
          .subscribe(data => {
            this.data = { ...data } as IEquipmentInfo;
          });
        // we also set the id of the wrapper being edited
        this.id = selector[selector.length - 1];
        this.selector = selector;
      } else {
        // if modalLink gives null value, set data = null
        // this will close the modal using ngIf in template
        this.data = null;
        this.id = null;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }
}
