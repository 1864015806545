import { NgModule, ModuleWithProviders } from '@angular/core';

@NgModule({})
export class IndexDBModule {
  public static forRoot(
    dbName: string,
    version: number,
    objectStores: string[]
  ): ModuleWithProviders {
    return {
      ngModule: IndexDBModule,
      providers: [
        {
          provide: 'index-db-config',
          useValue: {
            dbName,
            version,
            objectStores
          }
        }
      ]
    };
  }
}
