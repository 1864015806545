import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EquipmentTemplateGeneratorComponent } from './equipment-template-generator/equipment-template-generator.component';
import { TemplateRowComponent } from './template-row/template-row.component';
import { EquipmentTemplateEditerComponent } from './equipment-template-editer/equipment-template-editer.component';
import { TemplateHeaderDeviceComponent } from './template-header-device/template-header-device.component';
import { AngularSplitModule } from 'angular-split';
import { ComponentGeneratorModalComponent } from './template-row/component-generator-modal/component-generator-modal.component';
import { ComponentRowComponent } from './template-row/component-generator-modal/component-row/component-row.component';
import { ComponentCellComponent } from './template-row/component-generator-modal/component-cell/component-cell.component';
import { IotDeviceModalComponent } from './template-row/iot-device-modal/iot-device-modal.component';
import { IotDeviceModelIotModelModule } from '@bertlabs-nova/iot-device-model/iot-model';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSplitModule.forRoot(),
    IotDeviceModelIotModelModule
  ],
  declarations: [
    EquipmentTemplateGeneratorComponent,
    TemplateRowComponent,
    EquipmentTemplateEditerComponent,
    TemplateHeaderDeviceComponent,
    ComponentGeneratorModalComponent,
    ComponentRowComponent,
    ComponentCellComponent,
    IotDeviceModalComponent
  ],
  exports: [EquipmentTemplateGeneratorComponent]
})
export class DynamicAppShellEquipmentTemplateGeneratorModule {}
