import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';
import { IEquipmentInfo } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-hvac-equipment-renderer',
  templateUrl: './equipment-renderer.component.html',
  styleUrls: ['./equipment-renderer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentRendererComponent implements OnInit {
  selectedEquipment$: Observable<IEquipmentInfo | null>;

  constructor(
    private _selectorService: SelectorService,
    private _configService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.selectedEquipment$ = this._selectorService.equipmentSelector$.pipe(
      map(equipmentId => {
        if (equipmentId) {
          return this._configService.getInfo(equipmentId) as IEquipmentInfo;
        } else return null;
      })
    );
  }
}
