import { Component, OnInit, ViewChild } from '@angular/core';
import { EquipmentTemplateGeneratorService } from '../equipment-template-generator.service';
import { take } from 'rxjs/operators';
import { IEquipmentTemplateIndexDB } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-equipment-template-editer',
  templateUrl: './equipment-template-editer.component.html',
  styleUrls: ['./equipment-template-editer.component.css']
})
export class EquipmentTemplateEditerComponent implements OnInit {
  private _selectedTemplate: IEquipmentTemplateIndexDB;

  @ViewChild('templateLabel') templateLabel;

  updateTemplateLabel() {
    this.service.editTemplateLabel(
      this._selectedTemplate.key,
      this.templateLabel.nativeElement.value
    );
  }

  goBack() {
    this.service.selectTemplate(null);
  }

  constructor(public service: EquipmentTemplateGeneratorService) {}

  ngOnInit(): void {
    this.service.selectedTemplate$.pipe(take(1)).subscribe(template => {
      this._selectedTemplate = template;
    });
  }
}
