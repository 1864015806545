import {
  IIndexDBConfig,
  INDEXDB_KEYPATH
} from '@bertlabs-nova/types/nova-types';

export const upgradeDB = (e: any, config: IIndexDBConfig) => {
  const db: IDBDatabase = e.target.result;

  console.log('updating DB...');

  config.objectStores.forEach(name => {
    if (!db.objectStoreNames.contains(name))
      db.createObjectStore(name, { keyPath: INDEXDB_KEYPATH });
  });
};
