import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Renderer2,
  AfterContentInit,
  AfterViewInit,
  Optional
} from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-label-card-toggle',
  templateUrl: './graphical-label-card-toggle.component.html',
  styleUrls: ['./graphical-label-card-toggle.component.css']
})
export class GraphicalLabelCardToggleComponent
  implements OnInit, AfterContentInit, AfterViewInit {
  isChecked = true;
  @ViewChild('lbl') lbl;

  @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() label: string;
  @Input() units: string;
  @Input() id: string;
  @Optional() @Input() disabled: boolean = true;

  toggleCheckbox() {
    this.isChecked = !this.isChecked;
    if (this.isChecked) {
      this.renderer.setStyle(
        this.lbl.nativeElement,
        'background',
        this.themeService.theme.secondary
      );
    } else {
      this.renderer.setStyle(
        this.lbl.nativeElement,
        'background',
        this.themeService.theme.red
      );
    }
  }

  constructor(public themeService: ThemeService, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.isChecked = true;
  }

  ngAfterViewInit() {
    if (this.isChecked) {
      this.renderer.setStyle(
        this.lbl.nativeElement,
        'background',
        this.themeService.theme.secondary
      );
    } else {
      this.renderer.setStyle(
        this.lbl.nativeElement,
        'background',
        this.themeService.theme.red
      );
    }
  }
}
