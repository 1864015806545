import { Component, OnInit, Input, Optional, OnDestroy } from '@angular/core';

@Component({
  selector: 'bl-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.css']
})
export class ErrorPopupComponent implements OnInit, OnDestroy {
  @Input() uid: number | string;
  @Input() message: string;
  @Optional() @Input() duration: number;
  @Input() color: string;
  @Input() clickAction: () => void;
  @Input() isPersisted: boolean;
  @Optional() @Input() resolveError: (id: number) => void;

  autoResolveTimeout;

  handleClick() {
    if (this.isPersisted) {
      // do the bound action
      this.clickAction();
      // prevent user from performing the bound action more than once
      this.clickAction = () => {};
    } else {
      this.clickAction();
      this.clickAction = () => {};
      // if not a peristed error, then resolveIt after clicking on it
      if (this.resolveError) this.resolveError(+this.uid);
    }
  }

  constructor() {}

  ngOnInit(): void {
    if (!this.isPersisted && this.duration) {
      // automatically dismiss error after "duration" miliseconds
      this.autoResolveTimeout = setTimeout(() => {
        this.autoResolveTimeout = null;
        this.resolveError(+this.uid);
      }, this.duration);
    }
  }

  ngOnDestroy() {
    if (this.autoResolveTimeout) clearTimeout(this.autoResolveTimeout);
  }
}
