<div class="container">
  <button style="background: sandybrown" (click)="goBack()">Back</button>
  <div>
    <input
      #templateLabel
      type="text"
      [ngModel]="(service.selectedTemplate$ | async).label"
      style="margin-right: 10px"
    />
    <button (click)="updateTemplateLabel()">Save Template Name</button>
  </div>
  <hr />
  <div
    *ngFor="
      let rowData of (service.selectedTemplate$ | async).data;
      let i = index
    "
  >
    <bl-template-row [rowIndex]="i" [rowData]="rowData"></bl-template-row>
  </div>
  <hr />
  <button (click)="service.addRow()">
    Add new Row to template
  </button>
</div>
