import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import {
  IEquipmentTemplateComponentCell,
  IEquipmentTemplateComponentRow,
  IEquipmentTemplateRowDevice
} from '@bertlabs-nova/types/nova-types';
import {
  ComponentGeneratorService,
  Modes
} from '../../../component-generator.service';
import { SplitComponent } from 'angular-split';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bl-component-cell',
  templateUrl: './component-cell.component.html',
  styleUrls: ['./component-cell.component.css']
})
export class ComponentCellComponent implements OnInit, OnDestroy {
  private _sub: Subscription;
  @ViewChild('splitComponent') splitComponent: SplitComponent;

  @Input() isOnlyCell: boolean;
  @Input() cellIndex: number;
  @Input() componentCellData: IEquipmentTemplateComponentCell;
  @Input() rowIndex: number;
  @Input() rowParent: IEquipmentTemplateComponentCell;

  handleClick() {
    switch (this._componentGeneratorService.mode) {
      case Modes.addRow:
        console.log('hellow');

        if (this.componentCellData.isNested) {
          // already nested, just add new row
          this.componentCellData.data.push({
            data: [
              {
                isNested: false,
                data: null,
                width: 100
              }
            ],
            height: 100
          });
        } else {
          // not nested

          // mark nested now
          this.componentCellData.isNested = true;

          // check if any data existed
          if (this.componentCellData.data) {
            // iot device existed here, move it to a new row
            (this.componentCellData
              .data as IEquipmentTemplateComponentRow[]) = [
              {
                data: [
                  {
                    isNested: false,
                    data: this.componentCellData
                      .data as IEquipmentTemplateRowDevice,
                    width: 100
                  }
                ],
                height: 100
              },
              {
                data: [{ isNested: false, data: null, width: 100 }],
                height: 100
              }
            ];
          } else {
            // empty cell
            (this.componentCellData
              .data as IEquipmentTemplateComponentRow[]) = [
              {
                data: [{ isNested: false, data: null, width: 100 }],
                height: 100
              },
              {
                data: [{ isNested: false, data: null, width: 100 }],
                height: 100
              }
            ];
          }
        }

        break;
      case Modes.addColumn:
        (this.rowParent.data as IEquipmentTemplateComponentRow[])[
          this.rowIndex
        ].data.push({
          isNested: false,
          data: null,
          width: 100
        });
        break;
      case Modes.delete:
        if (
          this.rowParent.isMainStructure === true &&
          this.rowParent.data[this.rowIndex].data.length === 1 &&
          (this.rowParent.data as any).length === 1
        )
          break;

        const cellRow = this.rowParent.data[this.rowIndex].data;
        cellRow.splice(this.cellIndex, 1);
        if (cellRow.length === 0) {
          // delete row if last cell of the row was deleted
          (this.rowParent.data as IEquipmentTemplateComponentRow[]).splice(
            this.rowIndex,
            1
          );
          if (
            (this.rowParent.data as any).length === 1 &&
            this.rowParent.isMainStructure !== true
          ) {
            this.rowParent.isNested = false;
            this.rowParent.data = (this.rowParent
              .data as IEquipmentTemplateComponentRow[])[0].data[0].data;
          }
        }
        break;
      case Modes.addDevice:
        if (this.componentCellData.data !== null) {
          // open editing modal
          this._componentGeneratorService.openDeviceModal(
            this.componentCellData
          );
        } else
          this.componentCellData.data = {
            label: 'New Device',
            displayType: 'unselected',
            parameterName: 'parameter_name',
            endpointPlaceholder: 'endpoint_placeholder',
            readingType: 'string',
            units: '',
            alignment: 'left',
            fontColor: 'black',
            fontSizeEm: 0.9
          };

        break;
      default:
        break;
    }
  }

  constructor(private _componentGeneratorService: ComponentGeneratorService) {}

  ngOnInit(): void {
    this._sub = this._componentGeneratorService.saveIndicator$.subscribe(
      save => {
        if (this.splitComponent) {
          this.splitComponent.getVisibleAreaSizes().forEach((size, index) => {
            if (this.componentCellData.isNested)
              (this.componentCellData.data as IEquipmentTemplateComponentRow[])[
                index
              ].height = size as number;
          });
        }
      }
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
