import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import {
  IEquipmentTemplateComponentCell,
  IEquipmentTemplateRowDevice
} from '@bertlabs-nova/types/nova-types';
import { take, map } from 'rxjs/operators';

export enum Modes {
  addRow = 'addRow',
  addColumn = 'addColumn',
  addDevice = 'addDevices',
  delete = 'delete'
}

@Injectable()
export class ComponentGeneratorService {
  public mode: Modes = Modes.addRow;

  private _saveIndicator = new Subject();
  public saveIndicator$ = this._saveIndicator.asObservable();

  save() {
    this._saveIndicator.next(true);
  }

  switchMode(mode: Modes) {
    this.mode = mode;
  }

  // DEVICE MODAL

  private _deviceModal = new BehaviorSubject<IEquipmentTemplateComponentCell | null>(
    null
  );

  public $deviceModal = this._deviceModal.asObservable();

  get deviceModalIsOpen() {
    return this._deviceModal.pipe(
      take(1),
      map(modal => !!modal)
    );
  }

  closeDeviceModal = () => {
    this._deviceModal.next(null);
  };

  openDeviceModal(deviceParent: IEquipmentTemplateComponentCell) {
    this._deviceModal.next(deviceParent as any);
  }

  saveIoTDevice = (data: IEquipmentTemplateRowDevice) => {
    this._deviceModal.pipe(take(1)).subscribe(deviceParent => {
      deviceParent.data = data;
    });
  };

  deleteIoTDevice = () => {
    this._deviceModal.pipe(take(1)).subscribe(deviceParent => {
      deviceParent.data = null;
    });
  };
}
