<as-split #splitComponent direction="horizontal">
  <as-split-area
    [size]="cell.width"
    *ngFor="let cell of componentrowData.data; let i = index"
  >
    <bl-component-cell
      [cellIndex]="i"
      [rowIndex]="rowIndex"
      [rowParent]="parentObject"
      [isOnlyCell]="componentrowData.data.length < 2 ? true : false"
      [componentCellData]="cell"
    ></bl-component-cell>
  </as-split-area>
</as-split>
