<div
  class="rowWrapper"
  [ngStyle]="{
    height: rowData.heightPercent + '%'
  }"
  *ngFor="let rowData of template$ | async"
>
  <div
    style="height: 100%; width: 100%; display: flex;"
    [ngStyle]="{ justifyContent: rowData.justifyContent }"
    *ngIf="rowData.type !== 'graphical'; else graphicalRow"
  >
    <bl-equipment-row
      [rowData]="rowData"
      [endpoint]="(equipmentInfo | async)?.endpoints"
    ></bl-equipment-row>
  </div>
  <ng-template #graphicalRow>
    <bl-equipment-graphics-row
      [rowData]="rowData"
      [endpoint]="(equipmentInfo | async)?.endpoints"
    ></bl-equipment-graphics-row>
  </ng-template>
</div>
