import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient) { }

  rootURL = '/api';

  getAHUValues(requestBody): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
       // 'Access-Control-Allow-Origin': 'https://eda1-119-82-102-250.in.ngrok.io/predict/',
        'Access-Control-Allow-Origin': '*' ,
        'authorization': 'no-auth',
        'content-type': 'application/json'
      })}
    // const _url = 'http://3988-202-131-133-42.in.ngrok.io/predict';
    const _url = 'https://bpsliveatpharma.bertlabs.com/predict';
    return this.http.post(_url, requestBody, httpOptions).
    pipe(
      map((data) => {
        return data;
        console.log(data);
      })
    )
  }

  getOptimisedValues(requestBody): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Access-Control-Allow-Origin': '*' ,
        'authorization': 'no-auth',
        'content-type': 'application/json'
      })}
    const _url = 'https://bpsliveatpharma.bertlabs.com/optimise';
    return this.http.post(_url, requestBody, httpOptions).
    pipe(
      map((data) => {
        return data;
      })
    )
  }
}