import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { IGraphicsTemplateIndexDB } from '@bertlabs-nova/types/nova-types';
import { GraphicsGeneratorService } from '../graphics-generator.service';
import { take, map } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PolygonPointsPipe } from '../polygon-points.pipe';
import { ObjectKeysPipe } from '../object-keys.pipe';

// todo: build as seperate library

@Component({
  selector: 'bl-dynamic-node-mapper',
  templateUrl: './dynamic-node-mapper.component.html',
  styleUrls: ['./dynamic-node-mapper.component.css'],
  providers: [PolygonPointsPipe, ObjectKeysPipe]
})
export class DynamicNodeMapperComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  selectedTemplate: IGraphicsTemplateIndexDB;

  addDeviceModal = new BehaviorSubject<{ x: number; y: number } | null>(null);
  devices = new BehaviorSubject<string[]>([]);

  addStaticNodeModal = new BehaviorSubject<{ x: number; y: number } | null>(
    null
  );
  addClickableAreaModal = new BehaviorSubject<boolean>(false);

  currentArea = new BehaviorSubject<{ x: number; y: number }[]>([]);
  selectedMode = 'device';

  currentAreaPointsString = this.currentArea.asObservable().pipe(
    map(area => {
      let str = '';
      area.forEach(ar => {
        str += ar.x * 100 + ',' + ar.y * 100 + ' ';
      });
      str = str.slice(0, str.length - 1);
      return str;
    })
  );

  selectMode(mode: string) {
    this.selectedMode = mode;
  }

  @ViewChild('templateLabel') templateLabel;
  @ViewChild('backgroundImage') backgroundImage;

  @ViewChild('parameterName') parameterName;
  @ViewChild('staticNodeLabel') staticNodeLabel;
  @ViewChild('staticNodeIsClickable') staticNodeIsClickable;
  @ViewChild('staticNodeNavigateTo') staticNodeNavigateTo;

  @ViewChild('wrapperId') wrapperId;

  @ViewChild('mapperContainer') mapperContainer;
  @ViewChild('imageContainer') imageContainer;

  handleAreaCompletion() {
    this.addClickableAreaModal.next(true);
  }

  handleAreaCancel() {
    this.currentArea.next([]);
  }

  handleClick(e: MouseEvent) {
    let x = e.offsetX;
    let y = e.offsetY;
    const X = this.mapperContainer.nativeElement.clientWidth;
    const Y = this.mapperContainer.nativeElement.clientHeight;

    x = x / X;
    y = y / Y;

    if (this.selectedMode === 'device') {
      this.addDeviceModal.next({
        x,
        y
      });
    } else if (this.selectedMode === 'staticNode') {
      this.addStaticNodeModal.next({ x, y });
    } else if (this.selectedMode === 'constructingArea') {
      this.currentArea.pipe(take(1)).subscribe(currentArea => {
        const newCurrentArea = [...currentArea, { x, y }];
        this.currentArea.next(newCurrentArea);
      });
    }
  }

  handleClickableAreaAddition() {
    this.currentArea.pipe(take(1)).subscribe(pos => {
      this.service.addClickableArea(this.wrapperId.nativeElement.value, pos);
      this.currentArea.next([]);
      this.addClickableAreaModal.next(null);
    });
  }

  handleStaticNodeAddition() {
    this.addStaticNodeModal.pipe(take(1)).subscribe(nodeLocation => {
      this.service.addStaticNode(
        this.staticNodeLabel.nativeElement.value,
        nodeLocation.x,
        nodeLocation.y,
        this.staticNodeIsClickable.nativeElement.value,
        this.staticNodeNavigateTo.nativeElement.value
      );
      this.addStaticNodeModal.next(null);
    });
  }

  handleDeviceAddition() {
    this.addDeviceModal.pipe(take(1)).subscribe(deviceLocation => {
      this.service.addDevice(
        this.parameterName.nativeElement.value,
        deviceLocation.x,
        deviceLocation.y
      );
      this.addDeviceModal.next(null);
    });
  }

  handleDeviceDeletion(device: string) {
    this.service.removeDevice(device);
  }

  handleStaticNodeDeletion(i: number) {
    this.service.removeStaticNode(i);
  }

  handleClickableAreaDeletion(wrapperId: string) {
    this.service.removeClickableArea(wrapperId);
  }

  updateTemplateFields() {
    this.service.editTemplate(
      this.selectedTemplate.key,
      this.templateLabel.nativeElement.value,
      this.backgroundImage.nativeElement.value
    );
  }

  setHeight() {
    if (this.mapperContainer && this.imageContainer) {
      const newHeight = this.imageContainer.nativeElement.clientHeight + 'px';

      this.renderer.setStyle(
        this.mapperContainer.nativeElement,
        'height',
        newHeight
      );
    }
  }

  calcLeft(device: string) {
    return this.selectedTemplate.nodes[device].x * 100 + '%';
  }
  calcTop(device: string) {
    return this.selectedTemplate.nodes[device].y * 100 + '%';
  }

  goBack() {
    this.service.selectTemplate(null);
  }

  constructor(
    public service: GraphicsGeneratorService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.service.selectedTemplate$.pipe(take(1)).subscribe(template => {
      this.selectedTemplate = template;
    });
    this.sub = this.service.selectedTemplate$
      .pipe(
        map(template => {
          if (template) return Object.keys(template.nodes);
        })
      )
      .subscribe(deviceIds => {
        this.devices.next(deviceIds);
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
