import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bl-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() color?: string = 'rgba(0,0,0,0.6)';
  @Input() size?: number | string = 10;

  styleObject() {
    return {
      'font-size': this.size + 'px',
      'border-left': '1.1em solid ' + this.color
    };
  }

  constructor() {}

  ngOnInit(): void {}
}
