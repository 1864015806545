<div *ngIf="!!componentCellData.label">
  {{ componentCellData.label }}
</div>
<div class="structure">
  <bl-component-renderer-row
    [componentRowData]="row"
    [ngStyle]="{ height: row.height }"
    *ngFor="let row of componentCellData.structure.data"
  ></bl-component-renderer-row>
</div>
