import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IEquipmentTemplateRowDevice } from '@bertlabs-nova/types/nova-types';
import { EquipmentTemplateGeneratorService } from '../equipment-template-generator.service';
import { VisualLayerTypes } from '@bertlabs-nova/iot-device-model/iot-model';

@Component({
  selector: 'bl-template-header-device',
  templateUrl: './template-device.component.html',
  styleUrls: ['./template-device.component.css']
})
export class TemplateHeaderDeviceComponent implements OnInit {
  @Input() rowIndex: number;
  @Input() deviceIndex: number;
  @Input() deviceData: IEquipmentTemplateRowDevice;

  @ViewChild('deviceLabel') deviceLabel;
  @ViewChild('parameterName') paramterName;
  @ViewChild('deviceDisplayType') deviceDisplayType;
  @ViewChild('endpointPlaceholder') endpointPlaceholder;
  @ViewChild('readingType') readingType;
  @ViewChild('units') units;
  @ViewChild('rangeLow') rangeLow;
  @ViewChild('rangeHigh') rangeHigh;

  vlt = Object.keys(VisualLayerTypes);

  saveDeviceInfo() {
    // this.service.editHeaderDeviceInfo(this.rowIndex, this.deviceIndex, {
    //   parameterName: this.paramterName.nativeElement.value,
    //   label: this.deviceLabel.nativeElement.value,
    //   displayType: this.deviceDisplayType.nativeElement.value,
    //   endpointPlaceholder: this.endpointPlaceholder.nativeElement.value,
    //   readingType: this.readingType.nativeElement.value,
    //   units: this.units.nativeElement.value,
    //   range:
    //     this.readingType.nativeElement.value === 'numeric'
    //       ? [
    //           +this.rangeLow.nativeElement.value,
    //           +this.rangeHigh.nativeElement.value
    //         ]
    //       : undefined
    // });
  }

  deleteDevice() {
    this.service.removeHeaderDevice(this.rowIndex, this.deviceIndex);
  }

  constructor(public service: EquipmentTemplateGeneratorService) {}

  ngOnInit(): void {}
}
