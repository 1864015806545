<h1>{{ title }}</h1>
<div (click)="onNav()" routerLink="error">Go to error</div>
<div routerLink="config-generator">Config Creator</div>
<div routerLink="app-layout">App Layout</div>
<div routerLink="vl">Visual Layer</div>

<div routerLink="equipment-template-generator">
  Equipment Template Generator
</div>
<div routerLink="graphics-template-generator">Graphics Template Generator</div>

<div (click)="action3()">Show error</div>
<div *blIfRole="['admin']" (click)="action1()">
  Log auth
</div>
<div (click)="action4()">Log Config</div>
<div (click)="action5()">Log User Data From server</div>

<div (click)="action2()">Logout</div>

<hr />
