import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys'
})
export class ObjectKetsPipe implements PipeTransform {
  transform(area: Object): string[] {
    return Object.keys(area);
  }
}
