import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '@bertlabs-nova/utils/logger';

@Injectable()
export class SentryLoggerService implements ErrorHandler {
  constructor(private logger: LoggerService) {}

  handleError(error: any) {
    this.logger.logErrorAndAskForInfo(error);
  }
}
