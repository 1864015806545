import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bl-hvac-equipment-template-generator',
  templateUrl: './equipment-template-generator.component.html',
  styleUrls: ['./equipment-template-generator.component.css']
})
export class EquipmentTemplateGeneratorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
