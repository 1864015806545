<div class="dropdownHeader">
  <div (click)="toggleDropdown()" class="dropdownLabel">
    {{ label }}
    <span *ngIf="open$ | async; else down"> - </span>
    <ng-template #down> + </ng-template>
  </div>
  <div class="dropdownDrawer" *ngIf="open$ | async">
    <ng-content></ng-content>
  </div>
</div>
