import { Component, Input, OnInit } from '@angular/core';
import { interval, Observable } from 'rxjs';
import {
  faClock,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'bl-data-received-time',
  templateUrl: './data-received-time.component.html',
  styleUrls: ['./data-received-time.component.css']
})
export class DataReceivedTimeComponent implements OnInit {

  clock = faClock;
  @Input() reading$: Observable<{ data: any; found: boolean }>;
  reading: string;
  dateToday = new Date(Date.now());

  // Input for time range can also be entered from where the component is called
  // time; 
  @Input() time = [this.dateToday]; 

  constructor() { 
    interval(1000).subscribe(() => {
      // fired every second
      this.reading$.subscribe(data=>{
        this.time = [new Date(Number(data.data))];
        console.log("Data Time.....",this.time)
      })
    });
  }

  ngOnInit(): void {
  }

}
