import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import {
  IEquipmentTemplateComponentRow,
  IEquipmentTemplateComponentCell
} from '@bertlabs-nova/types/nova-types';
import {
  ComponentGeneratorService,
  Modes
} from '../../../component-generator.service';
import { Subscription } from 'rxjs';
import { SplitComponent } from 'angular-split';

@Component({
  selector: 'bl-component-row',
  templateUrl: './component-row.component.html',
  styleUrls: ['./component-row.component.css']
})
export class ComponentRowComponent implements OnInit, OnDestroy {
  private _sub: Subscription;

  @ViewChild('splitComponent') splitComponent: SplitComponent;

  @Input() rowIndex: number;
  @Input() parentObject: IEquipmentTemplateComponentCell;
  @Input() componentrowData: IEquipmentTemplateComponentRow;

  constructor(private _componentGeneratorService: ComponentGeneratorService) {}

  ngOnInit(): void {
    this._sub = this._componentGeneratorService.saveIndicator$.subscribe(
      save => {
        if (this.splitComponent)
          this.splitComponent.getVisibleAreaSizes().forEach((size, index) => {
            this.componentrowData.data[index].width = size as number;
          });
      }
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
