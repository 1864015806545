import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  // reference: https://docs.sentry.io/platforms/javascript/angular/

  constructor() {}

  logErrorAndAskForInfo(error: any) {
    console.error(error);
    // display sentry dialog for getting more info from user
  }

  logError(error: Object) {
    console.warn(error);
    // sentry.captureException(error.originalError || error);
  }
}
