<div class="device">
  <span [ngStyle]="{ color: themeService.theme.grey }" class="label"
    >{{ label }} {{ ' ' }}</span
  >
  <div>
    <span [ngStyle]="{ color: themeService.theme.green }" class="reading">{{
      (reading$ | async)?.data
    }}</span>
    <span [ngStyle]="{ color: themeService.theme.grey }" class="units">{{
      units
    }}</span>
  </div>
  <div class="onoffswitch">
    <input
      #toggle
      type="checkbox"
      name="onoffswitch"
      class="onoffswitch-checkbox"
      [id]="id"
      tabindex="0"
      [checked]="isChecked"
      (change)="toggleCheckbox()"
      [disabled]="disabled"
    />
    <label
      #lbl
      class="onoffswitch-label"
      [ngStyle]="{
        background: themeService.theme.secondary,
        color: isChecked
          ? themeService.theme.secondary
          : themeService.theme.red,
        cursor: disabled ? 'default' : 'pointer'
      }"
      [for]="id"
    ></label>
  </div>
</div>
