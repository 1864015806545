import { NgModule } from '@angular/core';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@bertlabs-nova/authentication';

@NgModule({
  declarations: [ErrorPageComponent],
  imports: [
    RouterModule.forChild([
      {
        path: '**',
        component: ErrorPageComponent,
        canActivate: [AuthGuard]
      }
    ])
  ]
})
export class PageNotFoundRoute {}
