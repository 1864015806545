import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShellComponent } from './app-shell.component';
import { TempComponent } from './temp/temp.component';
import { IfRoleDirectiveModule } from '@bertlabs-nova/authentication';
import { RouterModule } from '@angular/router';

import { AppLayoutGeneratorModule } from '@bertlabs-nova/dynamic-app-shell/app-layout-generator';
import { ConfigGeneratorComponent } from './config-generator/config-generator';
import { AppLayoutDirectiveModule } from '@bertlabs-nova/dynamic-app-shell/app-layout-directive';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { HomeNavigationComponent } from './app-layout/home-navigation/home-navigation.component';
import { SideNavigationComponent } from './app-layout/side-navigation/side-navigation.component';
import { EquipmentRendererComponent } from './app-layout/equipment-renderer/equipment-renderer.component';
import { DropdownHeaderComponent } from './app-layout/side-navigation/dropdown-header/dropdown-header.component';
import { NavigationElementComponent } from './app-layout/side-navigation/navigation-element/navigation-element.component';
import { DynamicAppShellEquipmentTemplateGeneratorModule } from '@bertlabs-nova/dynamic-app-shell/equipment-template-generator';
import { EquipmentTemplateGeneratorComponent } from './equipment-template-generator/equipment-template-generator.component';
import { EquipmentRendererModule } from '@bertlabs-nova/dynamic-app-shell/equipment-renderer';
import { GraphicsTemplateGeneratorComponent } from './graphics-template-generator/graphics-template-generator.component';
import { DynamicNodeMappingDynamicNodeMapperModule } from '@bertlabs-nova/dynamic-node-mapping/dynamic-node-mapper';
import { VisualLayerViewerComponent } from './visual-layer-viewer/visual-layer-viewer.component';
import { IotDeviceModelIotModelModule } from '@bertlabs-nova/iot-device-model/iot-model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UpdatesComponent } from './updates/updates.component';
import { LoginPageComponent } from './laurus-labs/login-component/login-page.component';
import { AHUStatusControlComponent } from './laurus-labs/ahu-screens/common-components/ahu-status-control.component';

@NgModule({
  declarations: [
    AppShellComponent,
    TempComponent,
    ConfigGeneratorComponent,
    HomeNavigationComponent,
    SideNavigationComponent,
    AppLayoutComponent,
    EquipmentRendererComponent,
    DropdownHeaderComponent,
    NavigationElementComponent,
    EquipmentTemplateGeneratorComponent,
    GraphicsTemplateGeneratorComponent,
    VisualLayerViewerComponent,
    UpdatesComponent,
    LoginPageComponent,
    AHUStatusControlComponent
  ],
  imports: [
    CommonModule,
    IfRoleDirectiveModule,
    RouterModule,
    AppLayoutGeneratorModule,
    AppLayoutDirectiveModule,
    DynamicAppShellEquipmentTemplateGeneratorModule,
    EquipmentRendererModule,
    DynamicNodeMappingDynamicNodeMapperModule,
    IotDeviceModelIotModelModule,
    FontAwesomeModule
  ]
})
export class AppShellModule {}
