<div class="errorGenerator">
  <div>
    <bl-error-popup
      *ngFor="let popup of popups"
      [resolveError]="resolveError"
      [uid]="popup[0]"
      [message]="popup[1].message"
      [duration]="popup[1].duration"
      [color]="popup[1].color"
      [clickAction]="popup[1].clickAction"
      [isPersisted]="false"
    ></bl-error-popup>
  </div>
  <div>
    <bl-error-popup
      *ngFor="let popup of persistedPopups"
      [uid]="popup[0]"
      [message]="popup[1].message"
      [color]="popup[1].color"
      [clickAction]="popup[1].clickAction"
      [isPersisted]="true"
    ></bl-error-popup>
  </div>
</div>
