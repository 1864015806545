import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-multi-value',
  templateUrl: './graphical-multi-value.component.html',
  styleUrls: ['./graphical-multi-value.component.css']
})
export class GraphicalMultiValueComponent implements OnInit {
  // @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() label: string;
  // @Input() units: string;
  @Input() placeholder: string;
  @Input() parameterName: string;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {
    // console.log(this.reading$);
  }
}
