import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DynamicNodeMapperComponent } from './dynamic-node-mapper/dynamic-node-mapper.component';
import { GraphicsTemplateGeneratorComponent } from './graphics-template-generator/graphics-template-generator.component';
import { PolygonPointsPipe } from './polygon-points.pipe';
import { ObjectKeysPipe } from './object-keys.pipe';


@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [
    DynamicNodeMapperComponent,
    GraphicsTemplateGeneratorComponent,
    PolygonPointsPipe,
    ObjectKeysPipe
  ],
  exports: [GraphicsTemplateGeneratorComponent]
})
export class DynamicNodeMappingDynamicNodeMapperModule {}
