<div class="templateRow">
  <h3>Row</h3>
  <div class="flexRow">
    <span>
      <div>Label</div>
      <input #rowLabel type="text" [value]="rowData.label" />
    </span>
    <span>
      <div>Height Percent</div>
      <input #rowHeight type="number" [value]="rowData.heightPercent" />
    </span>
    <span>
      <div>Width Percent</div>
      <input #widthPercent type="number" [value]="rowData.widthPercent" />
    </span>
    <span>
      <div>Row Type</div>
      <select #rowType [value]="rowData.type">
        <option value="mainData">Row With Label</option>
        <option value="data">Vanilla Row</option>
        <option value="graphical">Graphical Row</option>
        <option value="componentData">Component Based Row</option>
        <option value="mainComponentData"
          >Component Based Row with label</option
        >
      </select>
    </span>

    <span *ngIf="rowData.type === 'graphical'">
      <div>Graphics Template</div>
      <select #graphicsTemplate [ngModel]="rowData.graphicsTemplate">
        <option
          *ngFor="let template of service.graphicsTemplates$ | async"
          [value]="template.key"
        >
          {{ template.label }}
        </option>
      </select>
    </span>
  </div>
  <div class="flexRow">
    <span>
      <div>Flexbox Justify Content</div>
      <select #justifyContent [ngModel]="rowData.justifyContent">
        <option *ngFor="let item of justifyContentTypes" [value]="item">
          {{ item }}
        </option>
      </select>
    </span>

    <span>
      <div>Flexbox Align Items</div>
      <select #alignItems [ngModel]="rowData.alignItems">
        <option *ngFor="let item of alignItemsTypes" [value]="item">
          {{ item }}
        </option>
      </select>
    </span>

    <span>
      <div>Row Self Alignment</div>
      <select #alignSelf [ngModel]="rowData.alignSelf">
        <option *ngFor="let item of alignSelfTypes" [value]="item">
          {{ item }}
        </option>
      </select>
    </span>
  </div>

  <div *ngIf="rowData.type === 'newRow'">
    Select Row type and click on 'Save Row Data' to start editing the newly
    created row
  </div>

  <div
    *ngIf="
      rowData.type === 'data' ||
      rowData.type === 'mainData' ||
      rowData.type === 'graphical'
    "
  >
    <div>Devices:</div>
    <hr />
    <div class="flexRow" style="justify-content: left">
      <span
        style="margin-right: 10px"
        *ngFor="let deviceData of rowData?.devices; let i = index"
      >
        <div>{{ deviceData.label }}</div>
        <button (click)="openDeviceModal(rowIndex, i, deviceData)">Edit</button>
      </span>
    </div>

    <button style="margin-top: 10px" (click)="addNewDevice()">
      Add Device
    </button>
  </div>
  <hr />
  <div
    *ngIf="
      rowData.type === 'componentData' ||
      rowData.type === 'mainComponentData' ||
      rowData.type === 'graphical'
    "
  >
    <div>Components</div>
    <div class="flexRow" style="justify-content: left">
      <span
        style="margin-right: 10px"
        *ngFor="let component of rowData?.components; let k = index"
      >
        <div>{{ component.label }}</div>
        <button (click)="openComponentModal(rowIndex, k, component)">
          Edit
        </button>
      </span>
    </div>
    <button style="margin-top: 10px" (click)="addNewComponent()">
      Add Component
    </button>
  </div>
  <hr />
  <!-- <div *ngIf="rowData.type === 'mainData'">
    <div>Header Devices:</div>
    <hr />
    <div *ngFor="let headerDeviceData of rowData?.headerDevices; let j = index">
      <bl-template-header-device
        [rowIndex]="rowIndex"
        [deviceIndex]="j"
        [deviceData]="headerDeviceData"
      >
      </bl-template-header-device>
    </div>
    <button (click)="addNewHeaderDevice()">Add Header Device</button>
    <hr />
  </div> -->

  <div class="flexRow">
    <button
      style="background: dodgerblue; color: white"
      (click)="updateRowInfo()"
    >
      Save Row Data
    </button>
    <button style="background: salmon; color: white" (click)="deleteRow()">
      Delete Row
    </button>
  </div>
</div>

<bl-component-generator-modal
  *ngIf="componentModalIsOpen | async"
  [closeComponentModal]="closeComponentModal"
  [rowIndex]="rowIndex"
  [componentData]="($componentGeneratorModal | async).componentData"
  [componentIndex]="($componentGeneratorModal | async).componentIndex"
></bl-component-generator-modal>
<bl-iot-device-modal
  *ngIf="deviceModalIsOpen | async"
  [closeModal]="closeDeviceModal"
  [deviceData]="($deviceModal | async).deviceData"
  [saveDevice]="saveIoTDevice"
  [deleteDevice]="deleteIoTDevice"
>
</bl-iot-device-modal>
