import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'ahu-dry-granulation-component',
    templateUrl: './ahu-dry-granulation.template.html',
    styleUrls: ['../ahu-screen-108.component.css']
})

export class AHUDryGranulationComponent {
    @Input() tabletCoating: boolean = false;
    locationURL: any;
    imageURL: any;

    constructor(private route: ActivatedRoute, private router: Router) {} 
    ngOnInit() {
        this.tabletCoating = true;
        this.locationURL = window.location.href;
    };

    ngOnChange() {
        console.log('inside ngOnChange');
    }

    ngOnUpdate() {
        console.log('inside update **');
    }

    changeRoute() {
        this.router.navigate(['app/ahu-110']);
    }

    navigateBack() {
        this.router.navigate(['app/ahu-home']);
    }
    
    navigateToHome() {
        this.router.navigate(['app/ahu-home']);
    }

    logout() {
        localStorage.removeItem('user');
        location.reload();
    }
}