<div
  class="labelRow"
  *ngIf="rowData.label || mainDataRowLabel"
  [ngStyle]="{ color: themeService.theme.dark }"
>
  <div>{{ rowData.label || mainDataRowLabel }}</div>
  <div>
    <bl-iot-model
      *ngFor="let hd of rowData?.headerDevices"
      [deviceData]="hd"
    ></bl-iot-model>
  </div>
</div>
<div
  *ngIf="rowData.type === 'mainData' || rowData.type === 'data'"
  class="deviceRow"
  [ngStyle]="deviceStyle()"
>
  <div class="iotPlaceholder" *ngFor="let device of rowData.devices">
    <bl-iot-model [deviceData]="device"></bl-iot-model>
  </div>
</div>
<div
  *ngIf="
    rowData.type === 'mainComponentData' || rowData.type === 'componentData'
  "
  class="deviceRow"
  [ngStyle]="deviceStyle()"
>
  <bl-component-renderer
    *ngFor="let component of rowData.components"
    [componentCellData]="component"
  ></bl-component-renderer>
</div>
