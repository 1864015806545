import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'polygonPoints'
})
export class PolygonPointsPipe implements PipeTransform {

  transform(area: {x: number, y: number}[]): string {
    
    let str = '';
    area.forEach(ar => {
      str += (ar.x * 100)+","+(ar.y * 100)+" ";
    });
    str = str.slice(0, str.length - 1);
    
    return str;
  }

}
