import { NgModule, ErrorHandler } from '@angular/core';
import { SentryLoggerService } from './sentry-logger.service';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryLoggerService
    }
  ]
})
export class SentryLoggerModule {}
