import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import {
  IAppLayoutIndexDBObject,
  ConfigurationObjectStores,
  IWrapperInfo,
  IEquipmentInfo,
  IConfigBundle,
  IEquipmentTemplateIndexDB,
  EquipmentTemplateObjectStores,
  IGraphicsTemplateIndexDB,
  GraphicsObjectStores
} from '@bertlabs-nova/types/nova-types';

const configUpdateURL = 'http://localhost:3101/updateConfig';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationPushService {
  pushConfig() {
    // config bundle to be pushed to server
    const config: IConfigBundle = {};
    // add app-layout
    this.idb
      .get<IAppLayoutIndexDBObject>(
        ConfigurationObjectStores['app-layout'],
        'data'
      )
      .subscribe(data => {
        config.appLayout = data;
      });
    // add wrapper info and others...
    this.idb
      .getAll<IWrapperInfo>(ConfigurationObjectStores['wrapper-info'])
      .subscribe(data => {
        config.wrapperInfo = data;
      });
    this.idb
      .getAll<IEquipmentInfo>(ConfigurationObjectStores['equipment-info'])
      .subscribe(data => {
        config.equipmentInfo = data;
      });
    this.idb
      .getAll<IEquipmentTemplateIndexDB>(
        EquipmentTemplateObjectStores['equipment-templates']
      )
      .subscribe(data => {
        config.equipmentTemplate = data;
      });
    this.idb
      .getAll<IGraphicsTemplateIndexDB>(
        GraphicsObjectStores['graphics-templates']
      )
      .subscribe(data => {
        config.graphicsTemplate = data;
      });
    // timeout to ensure all the above calls are completed, refactor to use concat later
    setTimeout(() => {
      console.log(config);
      console.log('Pusing it to server.')
      console.log(configUpdateURL);
      const loggedInUser = JSON.parse(window.localStorage.getItem('user'));
      // post the config to server
      this._http
        .post(configUpdateURL, { config : config, site: loggedInUser['site'], client: loggedInUser['client'] })
        .subscribe(res => {
          console.log(res);
        });
    }, 1000);
  }

  constructor(private idb: IndexDbService, private _http: HttpClient) {}
}
