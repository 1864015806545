import { Component, OnInit } from '@angular/core';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';

@Component({
  selector: 'bl-hvac-home-navigation',
  templateUrl: './home-navigation.component.html',
  styleUrls: ['./home-navigation.component.css']
})
export class HomeNavigationComponent implements OnInit {
  goBack() {
    this.selectorService.goBack();
  }

  constructor(private selectorService: SelectorService) {}

  ngOnInit(): void {}
}
