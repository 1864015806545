import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import {
  IWrapperInfo,
  WrapperTypes,
  IAppLayoutIndexDBObject,
  IEquipmentInfo,
  ConfigurationObjectStores,
  EquipmentTemplateObjectStores,
  IEquipmentTemplateIndexDB,
  IGraphicsTemplateIndexDB,
  GraphicsObjectStores
} from '@bertlabs-nova/types/nova-types';

@Injectable()
export class AppLayoutGeneratorService {
  // subject to hold the app layout (how the wrappers are nested)
  private appLayout = new BehaviorSubject<Object>({});
  // subject to hold information about the wrappers
  // format => wrapperId : wrapperInfo
  private wrapperInfo = new BehaviorSubject<{
    [uniqueId: string]: IWrapperInfo;
  }>({});
  private equipmentTemplates = new BehaviorSubject<
    { label: string; key: string }[]
  >([]);
  private graphicsTemplates = new BehaviorSubject<
    { label: string; key: string }[]
  >([]);
  // subject to hold the selector for the wrapper being edited in the modal
  private modalLink = new Subject<string[] | null>();
  public version: number;

  public isModalOpen$ = this.modalLink.pipe(
    map(val => {
      if (val) return true;
      else return false;
    })
  );

  appLayout$ = this.appLayout.asObservable();
  wrapperInfo$ = this.wrapperInfo.asObservable();
  modalLink$ = this.modalLink.asObservable();
  equipmentTemplates$ = this.equipmentTemplates.asObservable();
  graphicsTemplates$ = this.graphicsTemplates.asObservable();

  // SELECTOR: an array of unique ids which tell us the path to find
  // any wrapper. e.g. [id1, id2, id3] => this is the selector for a wrapper
  // having id3 (last index is current wrapper). To get to the current wrapper,
  // we can do - app-layout[id1][id2] => this will give us object containing wrapper of id3
  // (parent object of current wrapper), to get children of current wrapper, we can just do
  // app-layout[id1][id2][id3]

  // function to generate RFIC compatible uuids (stable on new browsers)
  newUid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  editHomeWrapper(gt: string | null) {
    let graphicsTemplate = gt ? gt : null;

    this.wrapperInfo$.pipe(take(1)).subscribe(info => {
      info['home'] = {
        graphicsTemplate,
        'child-type': WrapperTypes.home,
        clickable: true,
        key: 'home',
        label: 'Home',
        parent: null,
        type: WrapperTypes.home
      };
    });
  }

  // remove a wrapper at given selector
  removeBlock(selector: string[]) {
    this.appLayout$.pipe(take(1)).subscribe(layout => {
      // store reference to layout
      let myObj = layout;
      // for each id in selector array,
      selector.forEach((key, i) => {
        // if we are not on the last id, set myObj = myObj[id]
        // this wil set myObj => parent of wrapper to be deleted
        if (i != selector.length - 1) myObj = myObj[key];
      });
      // delete the wrapper from the app-layout
      delete myObj[selector[selector.length - 1]];
      // emit new layout object (required for onPush)
      this.appLayout.next(layout);
    });
  }

  // add wrapper as a child to given selector
  addBlock(selector: string[]) {
    // generate new uuid
    const uid = this.newUid();
    // empty selector array => root wrapper, so parent = null
    // otherwise, set parent as the last wrapper id in the selector
    // (as we want the new wrapper added as a child to given selector)
    const parent =
      selector.length === 0 ? null : [selector[selector.length - 1]];
    // get current wrapper info
    this.wrapperInfo$.pipe(take(1)).subscribe(info => {
      let newWrapperType: WrapperTypes = WrapperTypes.home;

      if (parent) {
        // if parent is not null
        parent.forEach(par => {
          // for each parent
          if (info[par]) {
            // check if parentinfo exists
            // set the newWrapper's type based on parent's childType
            newWrapperType = info[par]['child-type'];
          }
        });
      }

      // copy the wrapper info and add new wrapper's info
      this.wrapperInfo.next({
        ...info,
        [uid]: {
          label: 'Label',
          clickable: true,
          type: newWrapperType,
          parent,
          key: uid,
          'child-type':
            (newWrapperType as WrapperTypes) === WrapperTypes.equipment
              ? WrapperTypes.none
              : newWrapperType
        }
      });
    });
    // get the current app-layout
    this.appLayout.pipe(take(1)).subscribe(layout => {
      // set reference to layout
      let myObj = layout;
      // for each uid in selector array,
      selector.forEach(key => {
        // do myObj = myObj[uid]
        myObj = myObj[key];
      });
      // this will set myObj = current wrapper
      // set myObj[uid] = {} to add a new wrapper to the app-layout
      // as a child of given selector
      myObj[uid] = {};
      this.appLayout.next(layout);
    });
  }

  // edit info from edit modal
  editInfo(id: string, newInfo: IWrapperInfo) {
    this.wrapperInfo$.pipe(take(1)).subscribe(info => {
      this.wrapperInfo.next({ ...info, [id]: newInfo });
    });
  }

  // emit selector of the wrapper that needs editing
  openEditModal(selector: string[]) {
    this.modalLink.next(selector);
  }

  // emit null to close the modal
  closeEditModal() {
    this.modalLink.next(null);
  }

  isEditModalOpen() {
    return this.modalLink$.pipe(
      map(val => {
        if (val) return true;
        else return false;
      })
    );
  }

  // return uids of children of given selector as observable
  getChildren(selector: string[]): Observable<string[]> {
    return this.appLayout$.pipe(
      map(appLayout => {
        let children = appLayout;
        selector.forEach(key => {
          children = children[key];
        });
        if (typeof children === 'object') return Object.keys(children);
        else return [];
      })
    ) as Observable<string[]>;
  }
  // returns info of given wrapper as observable
  getInfo(selector: string[]) {
    return this.wrapperInfo
      .asObservable()
      .pipe(map(info => info[selector[selector.length - 1]])) as Observable<
      IWrapperInfo
    >;
  }
  // pull data from index-db and load up in the subjects
  // code to save data is in ./app-layout-generator.component.ts
  getLocalStoredData() {
    this.idb
      .get<IAppLayoutIndexDBObject>(
        ConfigurationObjectStores['app-layout'],
        'data'
      )
      .subscribe(data => {
        if (data && data.data) {
          this.appLayout.next(data.data);
          this.version = +data.version;
        }
      });
    this.idb
      .getAll<IWrapperInfo>(ConfigurationObjectStores['wrapper-info'])
      .subscribe(wrapperArray => {
        this.idb
          .getAll<IEquipmentInfo>(ConfigurationObjectStores['equipment-info'])
          .subscribe(equipmentArray => {
            const wrapperInfo = {};
            wrapperArray.forEach(record => {
              wrapperInfo[record.key] = record;
            });
            equipmentArray.forEach(record => {
              wrapperInfo[record.key] = record;
            });
            this.wrapperInfo.next(wrapperInfo);
          });
      });
    this.idb
      .getAll<IEquipmentTemplateIndexDB>(
        EquipmentTemplateObjectStores['equipment-templates']
      )
      .pipe(
        map(templateArray => {
          const newArray: { label: string; key: string }[] = [];
          templateArray.forEach(template => {
            newArray.push({
              label: template.label,
              key: template.key
            });
          });
          return newArray;
        })
      )
      .subscribe(templateArray => {
        console.log(templateArray);
        this.equipmentTemplates.next(templateArray);
      });
    this.idb
      .getAll<IGraphicsTemplateIndexDB>(
        GraphicsObjectStores['graphics-templates']
      )
      .pipe(
        map(templateArray => {
          const newArray: { label: string; key: string }[] = [];
          templateArray.forEach(template => {
            newArray.push({
              key: template.key,
              label: template.label
            });
          });
          return newArray;
        })
      )
      .subscribe(templateArray => {
        this.graphicsTemplates.next(templateArray);
      });
  }

  constructor(private idb: IndexDbService) {}
}
