import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import {
  faClock,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-time-card',
  templateUrl: './graphical-time-card.component.html',
  styleUrls: ['./graphical-time-card.component.css']
})
export class GraphicalTimeCardComponent implements OnInit {
  clock = faClock;

  @Input() reading$: Observable<{ data: any; found: boolean }>;
  
  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
