<div
  class="device"
  [ngStyle]="{ 'border-bottom': '3px solid ' + themeService.theme.green }"
>
  <div class="deviceLabel" [ngStyle]="{ color: themeService.theme.secondary }">
    Ambient Condition
    <!-- {{ label }} -->
  </div>
  <div [ngStyle]="{ color: themeService.theme.dark }">
    <div class="innerDevice">
        <span [ngStyle]="{ color: themeService.theme.grey }" class="innerLabel">
            Temperature
        </span>
        <span [ngStyle]="{ color: themeService.theme.green }" class="reading">
            30
        </span>
    </div>
    <div class="innerDevice">
        <span [ngStyle]="{ color: themeService.theme.grey }" class="innerLabel">
            Humidity
        </span>
        <span [ngStyle]="{ color: themeService.theme.green }" class="reading">
            60
        </span>
    </div>
    <!-- <bl-graphical-pressure></bl-graphical-pressure>
    <bl-graphical-temperature></bl-graphical-temperature> -->
  </div>
</div>
