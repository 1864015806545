import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, distinctUntilChanged, map } from 'rxjs/operators';
import { ConfigurationService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';

@Injectable({
  providedIn: 'root'
})
export class SelectorService {
  // subject to hold the selector pointing to currently active
  // position in the app-layout object
  private _layoutSelector = new BehaviorSubject<string[]>([]);
  // subject to hold a boolean that controls if we show
  // home nav or the side nav + equipment renderer
  private _showSideNavigation = new BehaviorSubject<boolean>(false);
  // subject to hold id of currently active equipment
  // consumed by equipment renderer component
  private _equipmentSelector = new BehaviorSubject<null | string>(null);

  // allow public access as observables
  layoutSelector$ = this._layoutSelector.asObservable();
  // distinct until changed ensures only valid changes trigger
  // change detection
  equipmentSelector$ = this._equipmentSelector
    .asObservable()
    .pipe(distinctUntilChanged());
  showSideNavigation$ = this._showSideNavigation
    .asObservable()
    .pipe(distinctUntilChanged());

  // method to go back one level from current location in app-layout
  goBack() {
    this._layoutSelector.pipe(take(1)).subscribe(layout => {
      const newlayout = [...layout];
      newlayout.pop();
      // side navigation and equipment wrappers do not change
      // layout on clicking on them, hence if we want to go back
      // we must always return to home navigation
      this.shouldShowSideNavigation(false);
      this.changeLayout(newlayout);
      // also, set current equipment as null, as we have shifted to
      // home navigation
      this.changeEquipment(null);
    });
  }

  getEquipmentSelector(eqId: string) {
    return this.layoutSelector$.pipe(
      take(1),
      map(selector => {
        const newSelector = [...selector];
        const recBuildselector = (id: string) => {
          const parent = this._configService.getInfo(id).parent;
          if (
            parent &&
            parent[0] &&
            selector[selector.length - 1] !== parent[0]
          ) {
            recBuildselector(parent[0]);
          }
          newSelector.push(id);
        };

        recBuildselector(eqId);
        return newSelector;
      })
    ) as Observable<string[]>;
  }

  shouldShowSideNavigation(flag: boolean) {
    this._showSideNavigation.next(flag);
  }

  changeLayout(layoutSelector: string[]) {
    console.log(layoutSelector);
    this._layoutSelector.next(layoutSelector);
  }

  changeEquipment(equipment: string | null) {
    this._equipmentSelector.next(equipment);
  }

  constructor(private _configService: ConfigurationService) {}
}
