<div class="ahu-component">
  <div class="top-bar">
    <a class="active" style="position: fixed" href="/home">
      <!-- <img src="../assets/laurus-labs/Image 1.png" /> | -->
      <img
        src="../assets/laurus-labs/Bert Labs.png"
        style="margin-top: 3px;"
        height="50px"
        width="223px"
      />
    </a>
    <h1 style="position: fixed;left: 41%;opacity: 1;color: #3D2C5A;">
      TABLET COATING
    </h1>
    <a style="position: fixed; right: 5%;" (click)="logout()">
      <img src="../assets/laurus-labs/sign-out.png" style="margin-top: 17px;" />
    </a>
  </div>

  <div class="side-nav-bar">
    <div>
      <a (click)="navigateToHome()">
        <img src="../assets/laurus-labs/home-icon.png" class="style-home" />
      </a>
    </div>
    <a (click)="navigateBack()"
      ><img src="../assets/laurus-labs/back-button.png" class="style-home" />
    </a>
    <img
      src="../assets/laurus-labs/set-1.png"
      class="style-home"
      style="margin-top:1087%;"
    />
    <img src="../assets/laurus-labs/settings-icon.png" class="style-home" />
    <!-- <img src="../assets/laurus-labs/info-icon.png" /> -->
  </div>
  <div class="row" style="margin-top: 16%; margin-left:20%;">
    <div class="col" style="margin-left:15%; top:18%;">
      <a routerLink="../ahu-113" (click)="changeRoute()">
        <img
          src="../assets/laurus-labs/ahu-113-direct.png"
          height="50%"
          width="80%"
        />
      </a>
    </div>
  </div>
</div>
