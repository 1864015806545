import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import {
  IEquipmentTemplateComponent,
  IEquipmentTemplateComponentRow,
  IEquipmentTemplateRowDevice,
  IEquipmentTemplateComponentCell
} from '@bertlabs-nova/types/nova-types';
import { EquipmentTemplateGeneratorService } from '../../equipment-template-generator.service';
import {
  ComponentGeneratorService,
  Modes
} from '../../component-generator.service';
import { SplitComponent } from 'angular-split';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'bl-component-generator-modal',
  templateUrl: './component-generator-modal.component.html',
  styleUrls: ['./component-generator-modal.component.css']
})
export class ComponentGeneratorModalComponent implements OnInit, OnDestroy {
  private _sub: Subscription;

  public modes = Modes;

  @Input() closeComponentModal: () => {};
  @Input() rowIndex: number;
  @Input() componentIndex: number;
  @Input() componentData: IEquipmentTemplateComponent;

  @ViewChild('label') label;
  @ViewChild('width') width;
  @ViewChild('height') height;
  @ViewChild('justifyContent') justifyContent;

  @ViewChild('splitComponent') splitComponent: SplitComponent;

  addRowToStructure() {
    (this.componentData.structure
      .data as IEquipmentTemplateComponentRow[]).push({
      data: [
        {
          isNested: false,
          data: null,
          width: 100
        }
      ],
      height: 100
    });
  }

  updateComponentInfo() {
    const info = { ...this.componentData };
    info.label = this.label.nativeElement.value;
    info.width = this.width.nativeElement.value;
    info.height = this.height.nativeElement.value;
    info.justifyContent = this.justifyContent.nativeElement.value;

    this.componentGeneratorService.save();

    info.structure = this.componentData.structure;

    this.service.editComponentInfo(this.rowIndex, this.componentIndex, info);

    this.closeComponentModal();
  }

  deleteComponent() {
    this.service.removeComponent(this.rowIndex, this.componentIndex);
    this.closeComponentModal();
  }

  constructor(
    public service: EquipmentTemplateGeneratorService,
    public componentGeneratorService: ComponentGeneratorService
  ) {}

  ngOnInit(): void {
    this._sub = this.componentGeneratorService.saveIndicator$.subscribe(
      save => {
        this.splitComponent.getVisibleAreaSizes().forEach((size, index) => {
          (this.componentData.structure
            .data as IEquipmentTemplateComponentRow[])[
            index
          ].height = size as number;
        });
      }
    );
    if (
      (this.componentData.structure.data as IEquipmentTemplateComponentRow[])
        .length === 0
    )
      this.addRowToStructure();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
