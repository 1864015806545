<as-split
  #splitComponent
  *ngIf="componentCellData.isNested"
  class="structure"
  direction="vertical"
  restrictMove="true"
>
  <as-split-area
    [size]="row.height"
    *ngFor="let row of componentCellData.data; let i = index"
  >
    <bl-component-row
      [rowIndex]="i"
      [parentObject]="componentCellData"
      [componentrowData]="row"
    ></bl-component-row>
  </as-split-area>
</as-split>

<div class="cell" (click)="handleClick()" *ngIf="!componentCellData.isNested">
  <div *ngIf="componentCellData.data === null">
    EMPTY CELL
  </div>
  <div *ngIf="componentCellData.data !== null">
    {{ componentCellData.data['label'] }}
  </div>
</div>
