import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'ahu-granulation-component',
    templateUrl: './ahu-granulation.template.html',
    styleUrls: ['../ahu-screen-108.component.css']
})

export class AHUGranulationComponent {
    @Input() tabletCoating: boolean = false;
    locationURL: any;
    imageURL: any;

    
    constructor(private route: ActivatedRoute, private router: Router) {} 

    ngOnInit() {
        this.tabletCoating = true;
        this.locationURL = window.location.href;
    };

    ngOnChange() {
        console.log('inside ngOnChange');
    }

    ngOnUpdate() {
        console.log('inside update **');
    }

    navigateBack() {
        this.router.navigate(['app/ahu-home']);
    }

    navigateToHome() {
        this.router.navigate(['app/ahu-home']);
    }

    routeToAHUNumber(ahu) {
        if(ahu == 'ahu-108') {
            this.router.navigate(['app/ahu-108']);
        }
        else if(ahu == 'ahu-109') {
            this.router.navigate(['app/ahu-109']);
        }
        else {
            this.router.navigate(['app/ahu-119']);
        }
    }

    logout() {
        localStorage.removeItem('user');
        location.reload();
    }
}