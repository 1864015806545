import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IEquipmentTemplateRowDevice } from '@bertlabs-nova/types/nova-types';
import { VisualLayerTypes } from '@bertlabs-nova/iot-device-model/iot-model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'bl-iot-device-modal',
  templateUrl: './iot-device-modal.component.html',
  styleUrls: ['./iot-device-modal.component.css']
})
export class IotDeviceModalComponent implements OnInit {
  @Input() deviceData: IEquipmentTemplateRowDevice;

  @Input() saveDevice: (data: IEquipmentTemplateRowDevice) => void;
  @Input() deleteDevice: () => void;
  @Input() closeModal: () => void;

  @ViewChild('deviceLabel') deviceLabel;
  @ViewChild('parameterName') paramterName;
  @ViewChild('deviceDisplayType') deviceDisplayType;
  @ViewChild('endpointPlaceholder') endpointPlaceholder;
  @ViewChild('readingType') readingType;
  @ViewChild('units') units;
  @ViewChild('fontSizeEm') fontSizeEm;
  @ViewChild('fontColor') fontColor;
  @ViewChild('alignment') alignment;

  @ViewChild('rangeLow') rangeLow;
  @ViewChild('rangeHigh') rangeHigh;

  vlt = Object.keys(VisualLayerTypes);

  formGroup: FormGroup;
  flagRanges: FormArray;

  saveDeviceInfo() {
    let shouldCloseModal = true;

    if (
      this.deviceData.displayType !== this.deviceDisplayType.nativeElement.value
    )
      shouldCloseModal = false;

    const flagRanges =
      this.deviceDisplayType.nativeElement.value === VisualLayerTypes.flagRange
        ? this.flagRanges.value
        : undefined;

    console.log(flagRanges);

    const data: IEquipmentTemplateRowDevice = {
      alignment: this.alignment
        ? this.alignment.nativeElement.value
        : this.deviceData.alignment,
      fontColor: this.fontColor
        ? this.fontColor.nativeElement.value
        : this.deviceData.fontColor,
      fontSizeEm: this.fontSizeEm
        ? this.fontSizeEm.nativeElement.value
        : this.deviceData.fontSizeEm,
      parameterName: this.paramterName
        ? this.paramterName.nativeElement.value
        : this.deviceData.parameterName,
      label: this.deviceLabel
        ? this.deviceLabel.nativeElement.value
        : this.deviceData.label,
      displayType: this.deviceDisplayType
        ? this.deviceDisplayType.nativeElement.value
        : this.deviceData.displayType,
      endpointPlaceholder: this.endpointPlaceholder
        ? this.endpointPlaceholder.nativeElement.value
        : this.deviceData.endpointPlaceholder,
      readingType: this.readingType
        ? this.readingType.nativeElement.value
        : this.deviceData.readingType,
      flagRanges,
      units: this.units
        ? this.units.nativeElement.value
        : this.deviceData.units,
      range: this.readingType
        ? this.readingType.nativeElement.value === 'numeric'
          ? [
              +this.rangeLow.nativeElement.value,
              +this.rangeHigh.nativeElement.value
            ]
          : undefined
        : this.deviceData.range
    };

    this.saveDevice(data);
    shouldCloseModal && this.closeModal();
  }

  deleteDeviceInfo() {
    this.deleteDevice();
    this.closeModal();
  }

  get flagRangeControls() {
    return this.flagRanges.controls;
  }

  initFlagRanges() {
    this.flagRanges = new FormArray([]);

    if (this.deviceData.flagRanges) {
      this.deviceData.flagRanges.forEach(fl => {
        this.flagRanges.push(
          new FormGroup({
            rangeLow: new FormControl(fl.rangeLow),
            rangeHigh: new FormControl(fl.rangeHigh),
            flag: new FormControl(fl.flag)
          })
        );
      });
    }

    this.formGroup = new FormGroup({
      flagRanges: this.flagRanges
    });
  }

  addFlagRange() {
    this.flagRanges.push(
      new FormGroup({
        rangeLow: new FormControl(0),
        rangeHigh: new FormControl(0),
        flag: new FormControl('FLAG')
      })
    );
  }

  removeFlagRange(i: number) {
    this.flagRanges.removeAt(i);
  }

  constructor() {}

  ngOnInit(): void {
    console.log(this.deviceData);
    this.initFlagRanges();
  }
}
