import { Component, OnInit } from '@angular/core';
import { EquipmentTemplateGeneratorService } from '../equipment-template-generator.service';
import { map } from 'rxjs/operators';
import { IEquipmentTemplateIndexDB } from '@bertlabs-nova/types/nova-types';
import { BehaviorSubject } from 'rxjs';
import { ComponentGeneratorService } from '../component-generator.service';

@Component({
  selector: 'bl-equipment-template-generator',
  templateUrl: './equipment-template-generator.component.html',
  styleUrls: ['./equipment-template-generator.component.css'],
  providers: [EquipmentTemplateGeneratorService, ComponentGeneratorService]
})
export class EquipmentTemplateGeneratorComponent implements OnInit {
  equipmentTemplates$ = new BehaviorSubject<IEquipmentTemplateIndexDB[]>([]);

  constructor(public service: EquipmentTemplateGeneratorService) {}

  ngOnInit(): void {
    this.service.getLocalStoredData();
    this.service.equipmentTemplates$
      .pipe(
        map(templates => {
          const keys = Object.keys(templates);
          const templatesArray: IEquipmentTemplateIndexDB[] = [];
          keys.forEach(key => {
            templatesArray.push(templates[key]);
          });
          return templatesArray;
        })
      )
      .subscribe(templates => {
        this.equipmentTemplates$.next(templates);
      });
  }
}
