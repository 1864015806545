import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'ahu-compression-component',
    templateUrl: './ahu-compression-home.template.html',
    styleUrls: ['../ahu-screen-108.component.css']
})

export class AHUCompressionComponent {
    @Input() tabletCoating: boolean = false;
    locationURL: any;
    imageURL: any;

    constructor(private router: Router) {} 

    ngOnInit() {
        this.tabletCoating = true;
    };

    ngOnChange() {
        console.log('inside ngOnChange');
    }

    ngOnUpdate() {
        console.log('inside update **');
    }

    navigateBack() {
        this.router.navigate(['app/ahu-home']);
    }

    navigateToHome() {
        this.router.navigate(['app/ahu-home']);
    }

    routeToAHUNumber(ahu) {
        if(ahu === 'ahu-111') {
            this.router.navigate(['app/ahu-111']);
        }
        else if(ahu === 'ahu-112') {
            this.router.navigate(['app/ahu-112']);
        }
        else if(ahu === 'ahu-121') {
            this.router.navigate(['app/ahu-121']);
        }
        else if(ahu === 'ahu-171') {
            this.router.navigate(['app/ahu-171']);
        }
        else {
            this.router.navigate(['app/ahu-122']);
        }
    }

    logout() {
        localStorage.removeItem('user');
        location.reload();
    }
}