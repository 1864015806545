<div
  class="device"
  [ngStyle]="{ 'border-bottom': '3px solid ' + themeService.theme.green }"
>
  <div class="deviceLabel" [ngStyle]="{ color: themeService.theme.secondary }">
    {{ label }}
  </div>
  <!-- <div *ngIf="isNeutral" class="barLabel" [ngStyle]="{ color: themeService.theme.green }">
    COMFORT {{reading}}
  </div>
  <div *ngIf="!isNeutral" class="barLabel" [ngStyle]="{ color: themeService.theme.red }">
    CRITICAL
  </div> -->
  <div class="progressBar" >
    <!-- <div
      *ngIf="comfort"
      [ngStyle]="{
        background: 'green',
        width : progress
      }"
      class="progress"
      ></div>
    <div
      *ngIf="!comfort"
      [ngStyle]="{
        background: 'red',
        width : progress
      }"
      class="progress"
      ></div> -->
    <img src="/assets/comfortBar.png"  class="progress">
    <fa-icon
      [ngStyle]="{ 'margin-left': progress }"
      [icon]="mark"
      class="marker"
    ></fa-icon>
  </div>
</div>
