import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ViewChild,
  Optional,
  AfterViewChecked
} from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'bl-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent implements OnInit, AfterViewChecked {
  @ViewChild('lbl') lbl;

  @Input() reading$: Observable<{ data: any; found: boolean }>;

  isChecked = this.reading$.pipe(map(reading => (reading ? true : false)));

  @Input() id: string;
  @Optional() @Input() disabled: boolean = true;
  @Optional() @Input() color: string = 'green';
  @Optional() @Input() onLabel: string = 'ON';
  @Optional() @Input() offLabel: string = 'OFF';

  toggleCheckbox() {
    // this.isChecked = !this.isChecked;
    // if (this.isChecked) {
    //   this.renderer.setStyle(
    //     this.lbl.nativeElement,
    //     'background',
    //     this.themeService.theme[this.color]
    //   );
    // } else {
    //   this.renderer.setStyle(
    //     this.lbl.nativeElement,
    //     'background',
    //     this.themeService.theme.red
    //   );
    // }
  }

  constructor(public themeService: ThemeService, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.isChecked.pipe(take(1)).subscribe(isChecked => {
      if (this.isChecked) {
        this.renderer.setStyle(
          this.lbl.nativeElement,
          'background',
          this.themeService.theme[this.color]
        );
      } else {
        this.renderer.setStyle(
          this.lbl.nativeElement,
          'background',
          this.themeService.theme.red
        );
      }
    });
  }
}
