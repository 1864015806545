import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Renderer2,
  Optional,
  AfterViewChecked
} from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'bl-graphical-valve-controllable',
  templateUrl: './graphical-valve-controllable.component.html',
  styleUrls: ['./graphical-valve-controllable.component.css']
})
export class GraphicalValveControllableComponent
  implements OnInit, AfterViewChecked {
  faDotCircle = faDotCircle;
  @ViewChild('lbl') lbl;

  @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() id: string;
  @Optional() @Input() disabled: boolean = true;

  isChecked = this.reading$.pipe(map(data => (data ? true : false)));

  toggleCheckbox() {
    // this.isChecked = !this.isChecked;
    // if (this.isChecked) {
    //   this.renderer.setStyle(
    //     this.lbl.nativeElement,
    //     'background',
    //     this.themeService.theme.green
    //   );
    // } else {
    //   this.renderer.setStyle(
    //     this.lbl.nativeElement,
    //     'background',
    //     this.themeService.theme.red
    //   );
    // }
  }

  constructor(public themeService: ThemeService, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.isChecked.pipe(take(1)).subscribe(isChecked => {
      if (this.isChecked) {
        this.renderer.setStyle(
          this.lbl.nativeElement,
          'background',
          this.themeService.theme.green
        );
      } else {
        this.renderer.setStyle(
          this.lbl.nativeElement,
          'background',
          this.themeService.theme.red
        );
      }
    });
  }
}
