import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-label-card',
  templateUrl: './label-card.component.html',
  styleUrls: ['./label-card.component.css']
})
export class LabelCardComponent implements OnInit {
  @Input() label: string;
  @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() units: string;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
