import { Component, Input } from "@angular/core";

@Component({
    selector: 'bl-ahu-status-control-component',
    templateUrl: './ahu-status-control.template.html',
})

export class AHUStatusControlComponent {
    @Input() AHU_number: any;
    @Input() AHU_data: any;
    ngOnInit() {
        console.log('Inside login page component !!')
    };

    ngOnChange() {
        // console.log('when component updates ***');
    }
}