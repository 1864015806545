import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { AppLayoutGeneratorService } from '../app-layout-generator.service';
import { Observable } from 'rxjs';
import { IWrapperInfo } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-layout-block',
  templateUrl: './layout-block.component.html',
  styleUrls: ['./layout-block.component.css']
})
export class LayoutBlockComponent implements OnInit {
  // array having unique ids of children of current wrapper
  children: Observable<string[]>;
  // info of current wrapper
  info: Observable<IWrapperInfo>;
  // selector is an array of unique ids which tell us how to reach
  // the current wrapper if given the app-layout object. We access the objects
  // in the ids mentioned in the array and finally we reach the reqired wrapper
  // whoose id is last in the selector array

  // selector is input by recursive call to this component
  @Input() selector: string[];

  addBlock() {
    this.service.addBlock(this.selector);
  }

  removeBlock() {
    this.service.removeBlock(this.selector);
  }

  openEditModal() {
    this.service.openEditModal(this.selector);
  }

  // to make selector for a child, append child's id to current wrapper's
  // selector array
  makeSelector(id: string) {
    return [...this.selector, id];
  }

  constructor(public service: AppLayoutGeneratorService) {}

  ngOnInit(): void {
    // intialize observables for children and info
    this.children = this.service.getChildren(this.selector);
    this.info = this.service.getInfo(this.selector);
  }
}
