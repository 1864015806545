import { Injectable } from '@angular/core';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import {
  IGraphicsTemplateIndexDB,
  GraphicsObjectStores
} from '@bertlabs-nova/types/nova-types';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class GraphicsGeneratorService {
  private _graphicsTemplates = new BehaviorSubject<{
    [uid: string]: IGraphicsTemplateIndexDB;
  }>({});
  private _selectedTemplate = new BehaviorSubject<IGraphicsTemplateIndexDB | null>(
    null
  );

  selectedTemplate$ = this._selectedTemplate.asObservable();
  graphicsTemplates$ = this._graphicsTemplates.asObservable();

  newUid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  selectTemplate(templateId: string | null) {
    if (templateId)
      this.graphicsTemplates$.pipe(take(1)).subscribe(templates => {
        this._selectedTemplate.next(templates[templateId]);
      });
    else this._selectedTemplate.next(null);
  }

  addDevice(parameterName: number, x: number, y: number) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.nodes[parameterName] = { x, y };
      this._selectedTemplate.next(template);
    });
  }

  removeDevice(parameterName: string) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      delete template.nodes[parameterName];
      this._selectedTemplate.next(template);
    });
  }

  addStaticNode(
    label: string,
    x: number,
    y: number,
    isClickable: boolean,
    navigateTo: string
  ) {
    console.log(label, x, y, isClickable, navigateTo);
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.staticNodes.push({
        label,
        x,
        y,
        isClickable,
        navigateTo
      });
      this._selectedTemplate.next(template);
    });
  }

  removeStaticNode(index: number) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.staticNodes.splice(index, 1);
      this._selectedTemplate.next(template);
    });
  }

  addClickableArea(wrapperId: string, position: { x: number; y: number }[]) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.clickableAreas = {
        ...template.clickableAreas,
        [wrapperId]: position
      };

      this._selectedTemplate.next(template);
    });
  }

  removeClickableArea(wrapperId: string) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      delete template.clickableAreas[wrapperId];
      template.clickableAreas = { ...template.clickableAreas };
      this._selectedTemplate.next(template);
    });
  }

  addNewTemplate() {
    const id = this.newUid();
    this._graphicsTemplates.pipe(take(1)).subscribe(templates => {
      templates[id] = {
        key: id,
        label: 'New Template',
        backgroundImage: '',
        nodes: {},
        clickableAreas: {},
        staticNodes: []
      };
      this._graphicsTemplates.next(templates);
    });
  }

  removeTemplate(templateId: string) {
    this._graphicsTemplates.pipe(take(1)).subscribe(templates => {
      delete templates[templateId];
      this._graphicsTemplates.next(templates);
    });
  }

  editTemplate(templateId: string, label: string, backgroundImage: string) {
    this._graphicsTemplates.pipe(take(1)).subscribe(templates => {
      templates[templateId].label = label;
      templates[templateId].backgroundImage = backgroundImage;
      this._graphicsTemplates.next(templates);
    });
  }

  saveAllTemplates() {
    this._graphicsTemplates.pipe(take(1)).subscribe(templates => {
      this._idb.clear(GraphicsObjectStores['graphics-templates']).subscribe();

      const templatesArray = [];
      Object.keys(templates).forEach(key => {
        templatesArray.push(templates[key]);
      });

      this._idb
        .set(GraphicsObjectStores['graphics-templates'], templatesArray)
        .subscribe();
    });
  }

  // pull data from index-db and load up in the subjects
  // code to save data is in ./app-layout-generator.component.ts
  getLocalStoredData() {
    this._idb
      .getAll<IGraphicsTemplateIndexDB>(
        GraphicsObjectStores['graphics-templates']
      )
      .subscribe(data => {
        if (data) {
          const newTemplates = {};

          data.forEach(template => {
            newTemplates[template.key] = template;
          });

          this._graphicsTemplates.next(newTemplates);
        }
      });
  }

  constructor(private _idb: IndexDbService) {}
}
