<div class="background"></div>
<div class="nova">
  <!-- <img src="../assets/laurus-labs/laurusLogo.png" height="100%"/> -->
  <img src="../assets/laurus-labs/logo.png" height="100%" />
</div>
<div class="avengers">
  <!-- {{ authService.getConfig().appName }} -->
  <!-- Energy Efficiency Improvement -->
</div>
<div class="logo">
  Powered by:
  <br />
  <img src="../assets/laurus-labs/BertLabs_bg.png" />
</div>

<form class="form" #form="ngForm" (ngSubmit)="handleSubmit()">
  <div *ngIf="$error | async" class="error">
    {{ ($error | async).message }}
  </div>
  <span *ngIf="!forgotMode">
    <input ngModel name="email" type="email" placeholder="Username" />
    <input
      ngModel
      autocomplete="off"
      name="password"
      type="password"
      placeholder="Password"
    />
  </span>
  <span *ngIf="forgotMode && !forgotModeSent">
    <div class="infotext">
      Enter your registered email to reset your password
    </div>
    <input email ngModel name="email" type="email" placeholder="Email" />
  </span>
  <span *ngIf="forgotMode && forgotModeSent">
    <div class="infotext" style="margin-top: 75px; text-align: center">
      Sent an email to reset your password
    </div>
  </span>
  <button *ngIf="!forgotModeSent" type="submit">
    {{ forgotMode ? 'Send Reset Link' : 'LOGIN' }}
  </button>
  <div (click)="toggleForgotMode()" class="forgot">
    {{ forgotMode ? 'Go to Login' : 'Forgot Password?' }}
  </div>
</form>

<!-- <div class="background"></div>
<div class="nova">
  <img src="/assets/nova.png" />
</div>
<div class="avengers">
  {{ authService.getConfig().appName }}
</div>
<form class="form" #form="ngForm" (ngSubmit)="handleSubmit()">
  <div *ngIf="$error | async" class="error">
    {{ ($error | async).message }}
  </div>
  <span *ngIf="!forgotMode">
    <input ngModel name="email" type="email" placeholder="Email" />
    <input
      ngModel
      autocomplete="off"
      name="password"
      type="password"
      placeholder="Password"
    />
  </span>
  <span *ngIf="forgotMode && !forgotModeSent">
    <div class="infotext">
      Enter your registered email to reset your password
    </div>
    <input email ngModel name="email" type="email" placeholder="Email" />
  </span>
  <span *ngIf="forgotMode && forgotModeSent">
    <div class="infotext" style="margin-top: 75px; text-align: center">
      Sent an email to reset your password
    </div>
  </span>
  <button *ngIf="!forgotModeSent" type="submit">
    {{ forgotMode ? 'Send Reset Link' : 'LOGIN' }}
  </button>
  <div (click)="toggleForgotMode()" class="forgot">
    {{ forgotMode ? 'Go to Login' : 'Forgot Password?' }}
  </div>
</form>
<div class="logo">
  <img src="/assets/logo.png" />
</div> -->
