<div class="device">
  <span [ngStyle]="{ color: themeService.theme.grey }" class="label"
    >{{ label }} {{ ' ' }}</span
  >
  <span *ngIf="temp || rh" [ngStyle]="{ color: themeService.theme.green }" class="reading">{{
    (reading$ | async)?.data / 100
  }}</span>
  <span *ngIf="generic" [ngStyle]="{ color: themeService.theme.green }" class="reading">{{
    (reading$ | async)?.data
  }}</span>
  <span [ngStyle]="{ color: themeService.theme.grey }" class="units">{{
    units
  }}</span>
</div>
<!-- <div class="device">
  <span [ngStyle]="{ color: themeService.theme.grey }" class="label"
    >{{ label }} {{ ' ' }}</span
  >
  <span [ngStyle]="{ color: themeService.theme.green }" class="reading">{{
    (reading$ | async)?.data / 100
  }}</span>
  <span [ngStyle]="{ color: themeService.theme.grey }" class="units">{{
    units
  }}</span>
</div> -->
