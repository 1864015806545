export type User = IUser | null;

export enum Roles {
  technician = 'technician',
  admin = 'admin',
  viewer = 'viewer',
  overseer = 'overseer'
}

export interface IUser {
  username: string;
  token: string;
  email: string;
  client: string;
  site: string | null;
  role: Roles;
}
