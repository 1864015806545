import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { PopupHandlerService } from '@bertlabs-nova/utils/error-popup-handler';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Socket } from 'ngx-socket-io';
import { isNumber } from 'util';

//const BASE_URL = 'http://localhost:4555/';
const BASE_URL = '';
// const config: SocketIoConfig = { url: 'http://localhost:3002', options: {} };

let sub = [];
// let SOCKET_SWITCH = 0;
// let ch;

interface IActiveEndpoints {
  [endpointPlaceholder: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class NetworkLayerService {
  private _activeEndpoints = new BehaviorSubject<IActiveEndpoints>({});
  private _realtimeData = new BehaviorSubject<{
    [endpointPlaceholder: string]: Object;
  }>({});
  private _historicData = new BehaviorSubject<[]>([]);

  addActiveEndpoints(newEndpoints: IActiveEndpoints) {
    this._activeEndpoints.pipe(take(1)).subscribe(eps => {
      const newEps = { ...eps, ...newEndpoints };
      console.log(newEps);
      this._activeEndpoints.next(newEps);
    });
  }

  removeActiveEndpoints(removeEndpoints: IActiveEndpoints) {
    this._activeEndpoints.pipe(take(1)).subscribe(eps => {
      const newEps = { ...eps };
      Object.keys(removeEndpoints).forEach(ep => {
        if (newEps[ep]) delete newEps[ep];
      });
      this.dumpRealtimeData();
      this.stopRealTimeData();
      this._activeEndpoints.next(newEps);
    });
  }

  getRealtimeData(endpointPlaceholder: string, parameterName: string) {
    return this._realtimeData.asObservable().pipe(
      map(allData => {
        if (
          allData[endpointPlaceholder] &&
          allData[endpointPlaceholder].hasOwnProperty(parameterName)
        ) {
          console.log(allData[endpointPlaceholder][parameterName])
          return {
            data: allData[endpointPlaceholder][parameterName],
            found: true
          };
        } else {
          return {
            data: null,
            found: false
          };
        }
      })
    ) as Observable<{ data: any; found: boolean }>;
  }

  getHistoricData(endpointPlaceholder: string) {
    return this._historicData.asObservable().pipe(
      map(allData => {
        if(allData[endpointPlaceholder])
          return allData;
      })
    ) as Observable<[]>;
  }

  stopRealTimeData(){
    console.log("Closing the channel....")
    for(let i=0;i<sub.length;i++){
      // this.removeSub(ch,sub[i])
    }
    sub = [];
  }

  dumpRealtimeData() {
    this._realtimeData.next({});
  }

  private _fetchDataFromEndpoints(activeEps: IActiveEndpoints) {
    Object.keys(activeEps).forEach(placeholder => {
      this._fetchData(activeEps[placeholder], placeholder);
      this._getHistoricData(placeholder);
      // console.log("Fetching data from socket...");
      // this._fetchDataUsingSockets(activeEps[placeholder], placeholder);
    });
  }

  private _fetchData(url: string, placeholder: string) {
    console.log('fetching data from ', placeholder);
    this._http.get(BASE_URL+url).subscribe( 
      (res: any) => {
        if (res)
          // Limiting the decimal values of each value in result to 2
          for(const key in res){
            // if(isNumber(res[key])){
            if(isNumber(res[key]) && res[key]%1 !== 0){
              // console.log("res[key] is a number.........",res[key])
              res[key] = res[key].toFixed(2); 
              // console.log(res[key]);
            } 
          }
          this._realtimeData.pipe(take(1)).subscribe(rtData => {
            const newData = { ...rtData };
            newData[placeholder] = res;
            this._realtimeData.next(newData);
          });
      },
      err => {
        console.error(err);
        // handle error
      }
    );
  }

  public _getHistoricData(placeholder: string):any{
    let url;
    this._activeEndpoints.pipe(take(1)).subscribe(activeEps => {
      url = activeEps[placeholder];
    });
    url = url + "&historic=1";
    let result:[];
    // console.log('fetching data from ', placeholder);
    this._http.get(BASE_URL + url).subscribe(
      (res: any) => {
        // if (res)
          // Limiting the decimal values of each value in result to 2
          // for(const key in res){
          //   if(isNumber(res[key])){
          //     // console.log("res[key] is a number.........",res[key])
          //     res[key] = res[key].toFixed(2); 
          //     // console.log(res[key]);
          //   } 
          // }
        // console.log("historic data",res);
        result = res;
        this._historicData.pipe(take(1)).subscribe(hisData=>{
          const newData = {...hisData};
          newData[placeholder] = res;
          this._historicData.next(newData);
        })
        // return res;
      },
      err => {
        console.error(err);
        // handle error
      }
    );
    return result;
  }

  // ///////////////////////////////////////////////////////
  // Web socket data functions /////////////////////////////
  // ///////////////////////////////////////////////////////

  // private _fetchDataUsingSockets(url: string, placeholder: string){
  //   console.log('fetching data from ', placeholder, url);
    
  //   if(SOCKET_SWITCH === 0){
  //     console.log('handshaking...');
    
  //     this._socket.emit('handshake', {
  //       client: 'hul',
  //       site: 'ulhi'
  //     });
  //     this.openChannel(url);
  //     // this._socket.emit('openChannel', { topic: url });
      
  //     // this._socket.once('newChannelAdded', channelId => {
  //     //   ch.push(channelId);
  //     //   console.log(channelId);
  //     //   this._socket.on(channelId, data =>{
  //     //     console.log(data)
  //     //     // this.temp_function(data)
  //     //   });
  //     // });
  //     SOCKET_SWITCH = 1;
  //   }else{
  //     this.addSub(url);
  //   }
    
  // }

  // private temp_function(x){
  //   console.log("Printing temp function",x);
  // }

  // private openChannel(channel_topic:string){
  //   this._socket.emit('openChannel', { topic: channel_topic });
    
  //   this._socket.once('newChannelAdded', channelId => {
  //     sub.push(channel_topic);
  //     ch = channelId;
  //     console.log(channelId);
  //     this._socket.on(channelId, data =>{
  //       console.log(data);
  //       // this.temp_function(data);
  //     });
  //   });
  // };

  // private closeChannel(channel_id:string){
  //   this._socket.emit('closeChannel', { channelId: channel_id });
  // };

  // private addSub(topic:string){
  //   this._socket.emit('addSubscriptionToChannel', { channelId: ch, topic });
  // };

  // private removeSub(channel_id:string,topic:string){
  //   this._socket.emit('removeSubscriptionFromChannel', {
  //     channelId: channel_id,
  //     topic
  //   });
  // };

  // //////////////////////////////////////////////////////////////////////////////////

  constructor(
    private _http: HttpClient,
    private _popupService: PopupHandlerService,
    // private _socket: Socket,
  ) {
    // this._activeEndpoints.subscribe(activeEps => {
    //   this._fetchDataFromEndpoints(activeEps);
    // });

    this._realtimeData.subscribe(console.log);

    interval(1000).subscribe(() => {
      // fired every second
      this._activeEndpoints.pipe(take(1)).subscribe(activeEps => {
        this._fetchDataFromEndpoints(activeEps);
      });
    });
    // this._activeEndpoints.pipe(take(1)).subscribe(activeEps => {
    //   this._fetchDataFromEndpoints(activeEps);
    // });
  }
}
