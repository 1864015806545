import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopupHandlerService } from '../utils-popup-handler.service';
import { Subscription } from 'rxjs';
import {
  IBertlabsNovaPopup,
  IBertlabsNovaPersistedPopup
} from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-error-popup-generator',
  templateUrl: './error-popup-generator.component.html',
  styleUrls: ['./error-popup-generator.component.css']
})
export class ErrorPopupGeneratorComponent implements OnInit, OnDestroy {
  private popupSub: Subscription;
  private persistedPopupSub: Subscription;
  private resolvePersistedPopupSub: Subscription;

  private popupIdGen = 0;

  // errors are of the form [errorId, errorObject]
  popups: [number, IBertlabsNovaPopup][] = [];
  persistedPopups: [string, IBertlabsNovaPersistedPopup][] = [];

  constructor(private errorService: PopupHandlerService) {}
  // method to resolve normal errors
  resolveError = (id: number) => {
    this.popups = this.popups.filter(err => err[0] !== id);
  };

  ngOnInit(): void {
    // add error and make new error array
    this.popupSub = this.errorService.popup$.subscribe(err => {
      if (err)
        // generate a unique id and add to array
        this.popups = [...this.popups, [this.popupIdGen++, err]];
    });

    // add persisted error and make new array
    this.persistedPopupSub = this.errorService.persistedPopup$.subscribe(
      err => {
        if (err) this.persistedPopups = [...this.persistedPopups, err];
      }
    );

    // find persisted error with given id, if it exists, remove it
    this.resolvePersistedPopupSub = this.errorService.resolvePersisted$.subscribe(
      id => {
        if (id)
          this.persistedPopups = this.persistedPopups.filter(
            pErr => pErr[0] !== id
          );
      }
    );
  }

  ngOnDestroy(): void {
    // clean subscriptions
    this.popupSub.unsubscribe();
    this.persistedPopupSub.unsubscribe();
    this.resolvePersistedPopupSub.unsubscribe();
  }
}
