import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';

@Component({
  selector: 'bl-hvac-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.css']
})
export class SideNavigationComponent implements OnInit {
  goBack() {
    this.selectorService.goBack();
  }

  constructor(private selectorService: SelectorService) {}

  ngOnInit(): void {}
}
