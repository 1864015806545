<div class="device">
    <div
      class="status"
    >
      <fa-icon
        style="line-height: 0.8em"
        [icon]="clock"
      ></fa-icon>
      Data Received At: {{ time[0]  | date:'dd/MM/yyyy hh:mm:ss a'}}
    </div>
  </div>
