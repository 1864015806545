import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bl-hvac-dropdown-header',
  templateUrl: './dropdown-header.component.html',
  styleUrls: ['./dropdown-header.component.css']
})
export class DropdownHeaderComponent implements OnInit {
  @Input() label: string;

  private _open = new BehaviorSubject<boolean>(false);

  open$ = this._open.asObservable();

  toggleDropdown() {
    this._open.pipe(take(1)).subscribe(isOpen => {
      this._open.next(!isOpen);
    });
  }

  constructor() {}

  ngOnInit(): void {}
}
