import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import {
  faClock,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { interval, Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-realtime',
  templateUrl: './graphical-realtime.component.html',
  styleUrls: ['./graphical-realtime.component.css']
})
export class GraphicalRealtimeComponent implements OnInit {

  clock = faClock;

  // @Input() time: [number,number]; 
  
  // Setting the time from 9AM to 7PM
  dateToday = new Date(Date.now());
  // t1 = this.dateToday.setHours(9,0,0,0);
  // t2 = this.dateToday.setHours(19,0,0,0);

  // Input for time range can also be entered from where the component is called
  @Input() time = [this.dateToday]; 
  
  constructor(public themeService: ThemeService) {
    interval(10000).subscribe(() => {
      // fired every 10 seconds
      this.dateToday = new Date(Date.now());
      this.time = [this.dateToday];
    });
  }

  ngOnInit(): void {}

}
