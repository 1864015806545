import { Injectable, Inject } from '@angular/core';
import { PopupHandlerService } from '@bertlabs-nova/utils/error-popup-handler';
import {
  IIndexDBConfig,
  ConfigurationObjectStores
} from '@bertlabs-nova/types/nova-types';
import { upgradeDB } from './upgradeDB';
import { Subject, Observable, Observer, concat } from 'rxjs';

// the service returns observables that complete, so need to unsubscribe
// or use pipe(take(1)), just consume data directly in subscription and handle
// completion (if required)

// the code for get, set, getAll, etc. cannot be outsourced to external files
// as we need to pass this._db which is undefined on app startup, and due to closure,
// it remains undefined when the request runs (even after this._db has been initialised)

@Injectable({
  providedIn: 'root'
})
export class IndexDbService {
  // subscribe to this subject in the app to handle IDB errors
  error = new Subject<Error>();
  // the IDB instance will be initialised in the constructor
  private _db: IDBDatabase;
  // the subject isReady will complete when the idb has been setup
  // if the idb does not setup, no idb requests will be run
  // also, on startup, any requests to the idb will wait
  // until it has finished setting up (using concat)
  private _isReady = new Subject<void>();
  private _isReady$ = this._isReady.asObservable();

  // we inject the configuration from the app using forRoot method on the indexed-db module
  constructor(
    @Inject('index-db-config') private config: IIndexDBConfig,
    private popupService: PopupHandlerService
  ) {
    this.error.subscribe(err => {
      this.popupService.showPopup({
        message: err.message
      });
    });

    if (!window.indexedDB)
      this.error.next(
        new Error(
          'IndexedDB not available on this browser. Please use latest version of Google Chrome for best experience. Contact Bertlabs support if this error persists.'
        )
      );

    // get site token here and add it to dbName
    // open a request to the database
    const request = window.indexedDB.open(config.dbName, +config.version);
    // handle error on opening
    request.onerror = e => {
      this.error.next(
        new Error(
          'Cannot connect to local storage to fetch data. Please try again'
        )
      );
    };
    // handle database upgrade
    request.onupgradeneeded = (e: any) => {
      upgradeDB(e, config);
    };
    // db opened successfully
    request.onsuccess = e => {
      // initialize db
      this._db = request.result;

      // catch errors with db
      this._db.onerror = e => {
        this.error.next(new Error('Local storage error. Please try again'));
      };
      // complete the subject, signalling that the db is now open
      // to take requests
      console.log('idb ready');
      this._isReady.complete();
    };
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////
  // indexedDB initialised globally in the app
  ////////////////////////////////////////////
  ////////////////////////////////////////////

  logConfig() {
    console.log(this.config);
  }

  // get information of specified keys (in an array) from the specified objectStore
  // template generic support is given to handle type of returned data
  get<T>(
    objectStore: ConfigurationObjectStores | string,
    keys: string[] | string
  ) {
    // after isReady completes, i.e. the db has been setup, subscribe to
    // the observable which runs the query on db, emit result and then complete
    // concat ensures that isReady completes before our queries run, thus the this._db
    // id always well defined (even on app startup) and the queries do not fail
    return concat(
      this._isReady$,
      Observable.create((observer: Observer<T>) => {
        // this code cannot be outsources as the line below needs the value of
        // this._db, free from closure (up-to-date value, after being initialized)
        const transaction = this._db.transaction(objectStore, 'readonly');
        const txn = transaction.objectStore(objectStore);
        // check if user passed an array, then execute multiple requests
        // and emit all responses
        if (Array.isArray(keys)) {
          keys.forEach(key => {
            const op = txn.get(key);

            op.onsuccess = (e: any) => {
              observer.next(e.target.result as T);
            };

            op.onerror = (e: any) => {
              observer.error(
                new Error('Cannot fetch record from local storage')
              );
            };
          });
        } else {
          // if user passed string, just emit one response
          const op = txn.get(keys);

          op.onsuccess = (e: any) => {
            // here we emit the result
            observer.next(e.target.result as T);
          };

          op.onerror = (e: any) => {
            observer.error(new Error('Cannot fetch record from local storage'));
          };
        }

        transaction.oncomplete = e => {
          // complete the observable so that we dont need to unsub when we use it
          observer.complete();
        };
      })
    ) as Observable<T>;
  }

  getAll<T>(objectStore: ConfigurationObjectStores | string) {
    // see comments of get()
    return concat(
      this._isReady$,
      Observable.create((observer: Observer<T[]>) => {
        const transaction = this._db.transaction(objectStore, 'readonly');
        const txn = transaction.objectStore(objectStore);

        const op = txn.getAll();

        op.onsuccess = (e: any) => {
          observer.next(e.target.result);
        };

        op.onerror = (e: any) => {
          observer.error(
            new Error('Cannot get all documents from local storage')
          );
        };

        transaction.oncomplete = e => {
          observer.complete();
        };
      })
    ) as Observable<T[]>;
  }

  // add new entry or update existing entry in the db in given object store
  // the data MUST contain a "key" field which is a unique value (id) this will be used to fetch the
  // data later
  set<T>(objectStore: ConfigurationObjectStores | string, data: T | T[]) {
    // see comments of get()
    return concat(
      this._isReady$,
      Observable.create((observer: Observer<boolean>) => {
        const transaction = this._db.transaction(objectStore, 'readwrite');
        const txn = transaction.objectStore(objectStore);

        if (Array.isArray(data)) {
          data.forEach(d => {
            const op = txn.put(d);

            op.onerror = (e: any) => {
              observer.error(new Error('Cannot write to local storage'));
            };
          });
        } else {
          const op = txn.put(data);

          op.onerror = (e: any) => {
            observer.error(new Error('Cannot write to local storage'));
          };
        }

        transaction.oncomplete = e => {
          observer.next(true);
          observer.complete();
        };
      })
    ) as Observable<boolean>;
  }

  delete(
    objectStore: ConfigurationObjectStores | string,
    keys: string[] | string
  ) {
    // see comments of get()
    return concat(
      this._isReady$,
      Observable.create((observer: Observer<boolean>) => {
        const transaction = this._db.transaction(objectStore, 'readwrite');
        const txn = transaction.objectStore(objectStore);

        if (Array.isArray(keys)) {
          keys.forEach(key => {
            const op = txn.delete(key);

            op.onerror = (e: any) => {
              observer.error(
                new Error('Cannot delete record from local storage')
              );
            };
          });
        } else {
          const op = txn.delete(keys);

          op.onerror = (e: any) => {
            observer.error(
              new Error('Cannot delete record from local storage')
            );
          };
        }

        transaction.oncomplete = e => {
          observer.complete();
        };
      })
    ) as Observable<boolean>;
  }

  clear(objectStore: ConfigurationObjectStores | string) {
    // see comments of get()
    return concat(
      this._isReady$,
      Observable.create((observer: Observer<boolean>) => {
        const transaction = this._db.transaction(objectStore, 'readwrite');
        const txn = transaction.objectStore(objectStore);

        const op = txn.clear();

        op.onerror = (e: any) => {
          observer.error(
            new Error('Cannot clear all documents from local storage')
          );
        };

        transaction.oncomplete = e => {
          observer.complete();
        };
      })
    ) as Observable<boolean>;
  }
}
