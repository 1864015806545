import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'bl-graphical-non-neg-label',
  templateUrl: './graphical-non-neg-label.component.html',
  styleUrls: ['./graphical-non-neg-label.component.css']
})
export class GraphicalNonNegLabelComponent implements OnInit {

  @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() label: string;
  @Input() units: string;

  temp: boolean;
  rh: boolean;
  generic: boolean;

  constructor(public themeService: ThemeService) {
    // this.reading$._subscribe.arguments(data=>{console.log("This is data....",data)});
  }

  ngOnInit(): void {
    // console.log(this.reading$);

    this.temp = false
    this.rh = false
    this.generic = true;

    if(this.label==="Relative Humidity"){
      this.rh = true;
      this.generic = false;
    } 
    else if(this.label==="Temperature"){
      this.temp = true;
      this.generic = false;
    } 
  }

  max(one,two){
    // if(one>two)return one;
    // else return two;
    
    if(one<0){
      return two;
    }else{
      return one;
    }
  }
}
