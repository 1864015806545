import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { interval, Observable } from 'rxjs';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, Color } from 'ng2-charts';
import {
    faChevronLeft,
    faChevronRight
  } from '@fortawesome/free-solid-svg-icons';  
import { NetworkLayerService } from '@bertlabs-nova/iot-device-model/network-layer';

@Component({
  selector: 'bl-graphical-layer',
  templateUrl: './graphical-layer.component.html',
  styleUrls: ['./graphical-layer.component.css']
})
export class GraphicalLayerComponent implements OnInit {
  @Input() label: string;
  @Input() range: [];
  @Input() placeholder: string;

  constructor(
    public themeService: ThemeService,
    private readonly networkService: NetworkLayerService,
  ) {}

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  isMonth: boolean;
  index: number;
  date: number = new Date().getDate();

  historicRes = [];

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      pointRadius: 2
    }
  ];
  public lineChartLabels: Label[] = [];

  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left'
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderWidth: 2
        }
      ]
    }
  };
  public lineChartColors: Color[] = [
    {
      // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  // public lineChartPlugins = [pluginAnnotations];

  public chartBasicValues(){
    this.lineChartData = [
      {
        data: [],
        pointRadius: 2
      }
    ];
    this.lineChartLabels= [];
  
    this.lineChartOptions = {
      responsive: true,
      plugins: {
        datalabels: {
          display: false
        }
      },
      scales: {
        // We use this empty structure as a placeholder for dynamic theming.
        xAxes: [{}],
        yAxes: [
          {
            id: 'y-axis-0',
            position: 'left'
          }
        ]
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: 'March',
            borderWidth: 2
          }
        ]
      }
    };
    this.lineChartColors = [
      {
        // red
        backgroundColor: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }
    ];
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    // public lineChartPlugins = [pluginAnnotations];
  }

  public fetchData(placeholder){
    const res = this.networkService.getHistoricData(placeholder);
    res.subscribe(data=>{
      // console.log("inside sub..",data);
      this.historicRes = data;
      // return data;
    })
    // console.log("Result for historic data...",res)
  }

  public populateChart(){
    this.chartBasicValues();

    if(this.label === "Space Variables"){
      console.log("////// Chosen space variable //////")
      console.log("This is inside space variables...", this.historicRes['branch'])
      let tempData = [];
      let humidityData = [];
      for(let i=0;i<7;i++){
        const val = this.historicRes['branch'][i];
        tempData.push(val['temperature']);
        humidityData.push(val['humidity']);
      }
      console.log("2 Arrays...",tempData,humidityData);
      this.lineChartData[0] = {
        data : tempData,
        // data : [30.1,30.4,29.2,28.6,29.5,30.7],
        label : "Temperature"
      }
      this.lineChartData[1] = {
        data : humidityData,
        // data : [61.1,60.4,59.2,60.6,59.5,58.7],
        label : "Humidity"
      };
    }else if(this.label === "Energy Consumption"){
      let energyData = [];
      for(let i=0;i<7;i++){
        const val = this.historicRes['minion'][i];
        energyData.push(val['T_power']);
      }
      console.log("Energy array...",energyData);
      this.lineChartData[0] = {
        data : energyData,
        // data : this.range,
        label : "Energy Consumption"
      }
    }else{
      this.lineChartData[0]['label'] = this.label;
      this.lineChartData[0]['data'] = this.range;
    }

    this.lineChartLabels = [];
    for(let index=1;index<=this.range.length;index++) {
      this.lineChartLabels.push(index.toString());
    }
  }
  

  ngOnInit() {
    this.index = 0;
    this.isMonth = true;

    // let historicRes:[] = [];
    const In = interval(30000).subscribe(() => {
      // fired every 30 seconds
      this.fetchData(this.placeholder);
      console.log("Result for historic data...",this.historicRes)
      this.populateChart();
    });

    // TODO: Remove hard coding of array and the labels
    // Get historic data from the APIs

    /////// These are the initial dummy values before it gets the real value //////////
    if(this.label === "Space Variables"){
      this.lineChartData[0] = {
        data : [30.1,30.4,29.2,28.6,29.5,30.7],
        label : "Temperature"
      }
      this.lineChartData[1] = {
        data : [61.1,60.4,59.2,60.6,59.5,58.7],
        label : "Humidity"
      };
    }else if(this.label === "Energy Consumption"){
      this.lineChartData[0] = {
        data : this.range,
        label : "Energy Consumption"
      }
    }else{
      this.lineChartData[0]['label'] = this.label;
      this.lineChartData[0]['data'] = this.range;
    } 
    
    for(let index=1;index<=this.range.length;index++) {
      this.lineChartLabels.push(index.toString());
    }
  }
}
