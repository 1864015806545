import { Component } from "@angular/core";

@Component({
    selector: 'bl-LLabs-ahu-component-103',
    templateUrl: './ahu-screen-103.template.html',
    styleUrls: ['./ahu-screen-108.component.css']
})

export class AHU103ClassComponent {
    ngOnInit() {
        console.log('Inside AHU screen component !!')
    };

    ngOnChange() {
        console.log('when component updates ***');
    }
   
}