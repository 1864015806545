import { Component, OnInit, Input, HostBinding } from '@angular/core';
import {
  IEquipmentTemplateRow,
  EquipmentTemplateRowTypes
} from '@bertlabs-nova/types/nova-types';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';
import { ConfigurationService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bl-equipment-row',
  templateUrl: './equipment-row.component.html',
  styleUrls: ['./equipment-row.component.css']
})
export class EquipmentRowComponent implements OnInit {
  @Input() rowData: IEquipmentTemplateRow;
  @Input() endpoint: string;

  @HostBinding('style.width') width = '50%';

  deviceStyle() {
    return {
      'justify-content': this.rowData.justifyContent,
      'align-items': this.rowData.alignItems
    };
  }

  mainDataRowLabel: string;

  constructor(
    public themeService: ThemeService,
    private selectorService: SelectorService,
    private configService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.width = this.rowData.widthPercent + '%';
    if (this.rowData.type === EquipmentTemplateRowTypes.mainData) {
      this.selectorService.equipmentSelector$.pipe(take(1)).subscribe(id => {
        if (id) {
          this.mainDataRowLabel = this.configService.getInfo(id).label || null;
        }
      });
    }
  }
}
