<div class="graphicsContainer">
  <div #mapperContainer class="mainContainer">
    <img
      #imageContainer
      class="imgWrapper"
      [src]="(template | async)?.backgroundImage"
    />
    <div *ngIf="(selector | async) != null" class="svgWrapper">
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon
          class="svgPoly"
          *ngFor="let id of (template | async)?.clickableAreas | objectKeys"
          [blNavigation]="id"
          [attr.points]="
            ((template | async)?.clickableAreas)[id] | polygonPoints
          "
        ></polygon>
      </svg>
    </div>
    <div
      *ngFor="let staticNode of (template | async)?.staticNodes"
      class="staticLabel"
      [ngStyle]="{
        left: staticNode.x * 100 + '%',
        top: staticNode.y * 100 + '%'
      }"
    >
      <span
        *ngIf="staticNode.isClickable"
        [blNavigation]="staticNode.navigateTo"
      >
        {{ staticNode.label }}
      </span>
      <span *ngIf="!staticNode.isClickable">
        {{ staticNode.label }}
      </span>
    </div>
    <div
      class="iotDevice"
      [ngStyle]="{
        left: calcLeft(device.parameterName) | async,
        top: calcTop(device.parameterName) | async
      }"
      *ngFor="let device of rowData.devices"
    >
      <bl-iot-model [deviceData]="device"></bl-iot-model>
    </div>
  </div>
</div>
