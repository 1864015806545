import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-label-card',
  templateUrl: './graphical-label-card.component.html',
  styleUrls: ['./graphical-label-card.component.css']
})
export class GraphicalLabelCardComponent implements OnInit {
  @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() label: string;
  @Input() units: string;

  temp: boolean;
  rh: boolean;
  generic: boolean;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {
    // console.log(this.reading$);

    this.temp = false
    this.rh = false
    this.generic = true;

    if(this.label==="Relative Humidity"){
      this.rh = true;
      this.generic = false;
    } 
    else if(this.label==="Temperature"){
      this.temp = true;
      this.generic = false;
    } 
  }
}
