import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import { ConfigurationPushService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';
import { AppLayoutGeneratorService } from '../app-layout-generator.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  WrapperTypes,
  ConfigurationObjectStores,
  IAppLayoutIndexDBObject,
  IEquipmentInfo
} from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-app-layout-generator',
  templateUrl: './app-layout-generator.component.html',
  styleUrls: ['./app-layout-generator.component.css'],
  providers: [AppLayoutGeneratorService]
})
export class AppLayoutGeneratorComponent implements OnInit {
  // to initialize our recursive block component
  children: Observable<string[]>;

  @ViewChild('gt') gt;

  handleGtUpdate() {
    this.service.editHomeWrapper(this.gt.nativeElement.value || null);
  }

  // add block at root, hence selector should be blank
  addBlock() {
    this.service.addBlock([]);
  }

  logConfig() {
    // define an object to hold the parsed config
    const config = {
      'app-layout': {},
      'wrapper-info': [],
      'equipment-info': []
    };
    // get current value of layout from service
    this.service.appLayout$.pipe(take(1)).subscribe(layout => {
      // get current value of info from service
      this.service.wrapperInfo$.pipe(take(1)).subscribe(info => {
        // here we have access to both info and layout

        // set layout directly without changes
        config['app-layout'] = layout;

        config['wrapper-info'].push(info['home']);

        // recursive function to extract wrapper and equipment info
        // it accepts an object (nested layout object)
        const rec = (obj: Object) => {
          // loop over the keys of the object to give keys (ids of wrappers)
          Object.keys(obj).forEach(key => {
            // check if a given wrapper is of type equipment (using its id)
            if (info[key].type === WrapperTypes.equipment) {
              // push it to equipment-info array, we dont need to
              // call recursive function here as equipment will
              // not have any children
              config['equipment-info'].push({
                key,
                ...info[key],
                // equipment has NO layout children
                'child-type': WrapperTypes.none
              } as IEquipmentInfo);
            } else {
              // if not equipment, push to wrapper-info
              config['wrapper-info'].push({
                key,
                ...info[key]
              });
              // and call the function on all sub-objects in the current wrapper
              rec(obj[key]);
            }
          });
        };
        // call the recursive function on the main layout, this will generate the config
        rec(layout);

        // log the config to save to a local file manually
        console.log(config);
        // save the config to index-db
        this.idb
          .clear(ConfigurationObjectStores['app-layout'])
          .pipe(take(1))
          .subscribe();
        this.idb
          .clear(ConfigurationObjectStores['wrapper-info'])
          .pipe(take(1))
          .subscribe();
        this.idb
          .clear(ConfigurationObjectStores['equipment-info'])
          .pipe(take(1))
          .subscribe();
        this.idb
          .set(ConfigurationObjectStores['app-layout'], {
            key: 'data',
            data: config['app-layout'],
            version: this.service.version
          } as IAppLayoutIndexDBObject)
          .pipe(take(1))
          .subscribe();
        this.idb
          .set(
            ConfigurationObjectStores['wrapper-info'],
            config['wrapper-info']
          )
          .pipe(take(1))
          .subscribe();
        this.idb
          .set(
            ConfigurationObjectStores['equipment-info'],
            config['equipment-info']
          )
          .pipe(take(1))
          .subscribe();
      });
    });
  }

  constructor(
    public service: AppLayoutGeneratorService,
    private idb: IndexDbService,
    public configPushService: ConfigurationPushService
  ) {}

  ngOnInit(): void {
    this.service.getLocalStoredData();
    // this returns Object.keys(app-layout) as observable
    this.children = this.service.getChildren([]);

    // this.service.isEditModalOpen.subscribe(console.log);

    this.service
      .getInfo(['home'])
      .pipe(take(1))
      .subscribe(home => {
        if (!home) this.service.editHomeWrapper(null);
      });
  }
}
