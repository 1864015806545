import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { IEquipmentTemplateComponent } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-component-renderer',
  templateUrl: './component-renderer.component.html',
  styleUrls: ['./component-renderer.component.css']
})
export class ComponentRendererComponent implements OnInit {
  @Input() componentCellData: IEquipmentTemplateComponent;

  @HostBinding('style.height') private _height;
  @HostBinding('style.width') private _width;
  @HostBinding('style.justify-content') private _justify;

  constructor() {}

  ngOnInit(): void {
    this._height = this.componentCellData.height + '%';
    this._width = this.componentCellData.width + '%';
    this._justify = this.componentCellData.justifyContent;
    this.componentCellData.structure.data;
  }
}
