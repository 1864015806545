import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  IBertlabsNovaPopup,
  IBertlabsNovaPersistedPopup,
  PopupColors
} from '@bertlabs-nova/types/nova-types';

// later, we will parse these color codes from the personalisation service

@Injectable({
  providedIn: 'root'
})
export class PopupHandlerService {
  private popupSubject = new Subject<IBertlabsNovaPopup>();
  private persistedPopupSubject = new Subject<
    [string, IBertlabsNovaPersistedPopup]
  >();
  private resolvePersistedPopupSubject = new Subject<string>();

  popup$ = this.popupSubject.asObservable();
  persistedPopup$ = this.persistedPopupSubject.asObservable();
  resolvePersisted$ = this.resolvePersistedPopupSubject.asObservable();

  constructor() {}

  showPopup(error: IBertlabsNovaPopup) {
    const newError: IBertlabsNovaPopup = {
      message: 'Something went wrong. Please try again',
      color: PopupColors.warning,
      duration: 3000,
      clickAction: () => {},
      ...error
    };

    this.popupSubject.next(newError);
  }

  showPersistedPopup(id: string, error: IBertlabsNovaPersistedPopup) {
    this.persistedPopupSubject.next([
      id,
      {
        message: 'Something went wrong. Please try again',
        color: 'red',
        clickAction: () => {},
        ...error
      }
    ]);
  }

  resolvePersistedPopup(id: string) {
    this.resolvePersistedPopupSubject.next(id);
  }
}
