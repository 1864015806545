<button (click)="selectorService.goBack()">Back</button>
<button (click)="con()">Console</button>

<div *ngIf="selectorService.showSideNavigation$ | async; else home">
  <div>
    <bl-hvac-side-navigation></bl-hvac-side-navigation>
    <bl-hvac-equipment-renderer></bl-hvac-equipment-renderer>
  </div>
</div>
<ng-template #home>
  <bl-hvac-home-navigation></bl-hvac-home-navigation>
</ng-template>
