<div class="block">
  <div>
    <span>{{ (info | async)?.label }}</span> |
    <button (click)="openEditModal()">Edit</button>
    <button (click)="removeBlock()">Delete</button>
  </div>
  <!-- children is an observable which contains Object.keys(childrenOfThisWrapper) -->
  <div *ngFor="let key of children | async">
    <!-- We get unique ids of the children in the loop, pass it to makeSelector
    function to generate the selector for layout-block. Below is a recursive call
  to this component -->
    <bl-layout-block [selector]="makeSelector(key)"></bl-layout-block>
  </div>
  <!-- If not equipment, allow adding children -->
  <button *ngIf="(info | async)?.type != 'equipment'" (click)="addBlock()">
    Add
  </button>
</div>
