import { Component, OnInit, Input } from '@angular/core';
import { IEquipmentTemplateComponentRow } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-component-renderer-row',
  templateUrl: './component-renderer-row.component.html',
  styleUrls: ['./component-renderer-row.component.css']
})
export class ComponentRendererRowComponent implements OnInit {
  @Input() componentRowData: IEquipmentTemplateComponentRow;

  constructor() {}

  ngOnInit(): void {}
}
