import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // !!user returns true if user exists, otherwise false
    return this.authService.user$.pipe(
      // only one value is taken otherwise we may get side effects, after taking one value we unsub
      take(1),
      map(user => {
        const isAuth = !!user;
        // allows the marked route to be accessed
        if (isAuth) return true;
        // redirects to urlTree specified
        return this.router.createUrlTree(['/authenticate']);
        // return true;
      })
    );
  }
}
