import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupHandlerService } from '@bertlabs-nova/utils/error-popup-handler';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import { AuthenticationService } from '@bertlabs-nova/authentication';
import { ConfigurationService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';
import { PopupColors } from '@bertlabs-nova/types/nova-types';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'bl-temp',
  templateUrl: './temp.component.html',
  styleUrls: ['./temp.component.css']
})
export class TempComponent implements OnInit {
  title = 'Bert Labs HVAC';
  color: string;

  constructor(
    private router: Router,
    private popupService: PopupHandlerService,
    public authService: AuthenticationService,
    private idb: IndexDbService,
    private configService: ConfigurationService,
    private http: HttpClient
  ) {}

  action1() {
    this.authService.getUser().subscribe(user => console.log(user));
  }

  action2() {
    this.authService.logout();
  }

  action3() {
    const colors = [
      PopupColors.info,
      PopupColors.success,
      PopupColors.critical
    ];
    this.popupService.showPopup({
      message: 'Error Popup',
      color: colors[Math.floor(Math.random() * 3)]
    });
  }

  action4() {
    console.log(
      this.configService.config,
      this.configService.wrapperInfo,
      this.configService.equipmentInfo
    );
  }

  action5() {
    this.http
      .get('http://localhost:3100/userData')
      .subscribe(data => console.log(data));
  }

  onNav() {
    console.log(this.router.url);
  }

  ngOnInit() {}
}
