<div class="device">
  <span [ngStyle]="{ color: themeService.theme.grey }" class="label">
    <fa-icon [icon]="faDotCircle"></fa-icon>
  </span>

  <span class="onoffswitch">
    <input
      #toggle
      type="checkbox"
      name="onoffswitch"
      class="onoffswitch-checkbox"
      [id]="id"
      tabindex="0"
      [checked]="isChecked | async"
      (change)="toggleCheckbox()"
      [disabled]="disabled"
    />
    <label
      [ngStyle]="{
        background: themeService.theme.green,
        cursor: disabled ? 'default' : 'pointer'
      }"
      #lbl
      class="onoffswitch-label"
      [for]="id"
    ></label>
  </span>

  <span
    [ngStyle]="{
      color:
        (isChecked | async) ? themeService.theme.green : themeService.theme.red,
      'font-size': '0.9em'
    }"
    >{{ (isChecked | async) ? 'OPEN' : 'CLOSED' }}</span
  >
</div>
