import { INDEXDB_KEYPATH } from './indexDb.types';
import { IEquipmentTemplateIndexDB } from './equipment-template.types';
import { IGraphicsTemplateIndexDB } from './graphic-template.types';

export interface IConfigBundle {
  appLayout?: IAppLayoutIndexDBObject;
  wrapperInfo?: IWrapperInfo[];
  equipmentInfo?: IEquipmentInfo[];
  equipmentTemplate?: IEquipmentTemplateIndexDB[];
  graphicsTemplate?: IGraphicsTemplateIndexDB[];
}

export enum ConfigurationObjectStores {
  'app-layout' = 'app-layout',
  'iot-metadata' = 'iot-metadata',
  'wrapper-info' = 'wrapper-info',
  'equipment-info' = 'equipment-info'
}

// refer to architecture doc (under dynamic app shell) for explanation on wrapper types
export enum WrapperTypes {
  home = 'home-navigation',
  side = 'side-navigation',
  equipment = 'equipment',
  none = 'none'
}
// info about a specific wrapper, label is the name of the wrapper
// parent is array of ids of the parent of the wrapper
export interface IWrapperCoreInfo {
  [INDEXDB_KEYPATH]: string;
  label: string;
  parent: null | string[];
  'child-type': WrapperTypes;
  type: WrapperTypes;
  clickable: boolean;
  image?: string[];
}

export interface IWrapperInfo extends IWrapperCoreInfo {
  graphicsTemplate?: string;
  equipmentTemplate?: string;
}

export interface IAppLayoutIndexDBObject {
  [INDEXDB_KEYPATH]: 'data';
  data: Object;
  version: number;
}

export interface IEquipmentInfo extends IWrapperInfo {
  equipmentTemplate: string;
  endpoints: { [endpointPlaceholder: string]: string };
}
