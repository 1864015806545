import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodeViewerComponent } from './node-viewer/node-viewer.component';
import { PolygonPointsPipe } from './node-viewer/polygon-points.pipe';
import { AppLayoutDirectiveModule } from '@bertlabs-nova/dynamic-app-shell/app-layout-directive';
import { IotDeviceModelIotModelModule } from '@bertlabs-nova/iot-device-model/iot-model';
import { ObjectKetsPipe } from './node-viewer/objectKeys.pipe';
import { DynamicAppShellNavigationDirectiveModule } from '@bertlabs-nova/dynamic-app-shell/navigation-directive';

@NgModule({
  imports: [
    CommonModule,
    AppLayoutDirectiveModule,
    IotDeviceModelIotModelModule,
    DynamicAppShellNavigationDirectiveModule
  ],
  declarations: [NodeViewerComponent, PolygonPointsPipe, ObjectKetsPipe],
  exports: [NodeViewerComponent]
})
export class DynamicNodeMappingDynamicNodeViewerModule {}
