<form class="ahu-status-control">
    <table >
        <thead class="header"> 
            <th> AHU STATUS & CONTROL </th>
        </thead>
        <tbody>
            <tr>
                <td class="font-text-style"> FAN TRIP STATUS </td>
                <td> <button type="button" class="status-green"> NORMAL </button></td>
                <td> <button type="button" disabled class="status-reset"> RESET </button></td>
            </tr>
            <tr>
                <td class="font-text-style"> FAN RUN STATUS </td>
                <td> <button type="button" class="auto"> AUTO </button></td>
                <td> <button type="button" class="status-green"> ON </button></td>
            </tr>
            <tr>
                <td class="font-text-style"> FAN FAIL ALARM </td>
                <td> <button type="button" class="status-green"> NORMAL </button> </td>
                <td> <button type="button" disabled class="status-reset"> RESET </button> </td>
            </tr>
            <tr>
                <td class="font-text-style"> BUZZER STATUS </td>
                <td> <button class="status-green"> OFF </button></td>
                <td> <button type="button" disabled class="status-reset"> RESET </button></td>
            </tr>
            <tr>
                <td class="font-text-style"> BUZZER </td>
                <td> <button type="button" class="btn-test"> TEST </button></td>
                <td> </td>
            </tr>
            <tr>
                <td> <button type="button" class="btn-btm"> ALARM LIMIT </button> </td>
                <td> <button type="button" class="btn-btm"> SCHEDULING </button> </td>
                <td> <button type="button" class="btn-btm"> TREND </button></td>
            </tr>
        </tbody>
    </table>
</form>
