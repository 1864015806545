import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import {
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { interval, Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bl-graphical-range',
  templateUrl: './graphical-range.component.html',
  styleUrls: ['./graphical-range.component.css']
})
export class GraphicalRangeComponent implements OnInit {
  mark = faCaretUp;

  @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() label: string;
  @Input() range: [number,number];

  isNeutral : boolean;
  progress : any;
  reading : any;
  comfort: boolean;
  constructor(public themeService: ThemeService) {
    interval(1000).subscribe(() => {
      // fired every second
      this.reading$.subscribe(data=>{
        // console.log(data)
        this.reading = data.data / 100;
        // console.log("readings...",this.reading);
        
        // this.progress = (data.data / 100)+'%'
      })
      if(this.reading<23.5){
        this.comfort = false;
        this.progress = "20%";
      }else if(this.reading>25.5){
        this.comfort = false;
        this.progress = "80%";
      }else{
        this.comfort = true;
        this.progress = "50%";
      }

      if(!this.reading){
        this.progress = "0%";
      }
    });
  }

  ngOnInit() {

    this.isNeutral = false;

    // if(this.range[0]/this.range[1] <= 0.5) {
    //   this.isNeutral = true;
    // } 

    // fix progress bar according to the values.
    // this.progress = (this.range[0]/this.range[1])*100 + '%';
  }
}
