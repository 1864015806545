export enum GraphicsObjectStores {
  'graphics-templates' = 'graphics-templates'
}

export interface IGraphicsTemplateIndexDB {
  nodes: {
    [parameterName: string]: {
      x: number;
      y: number;
    };
  };
  staticNodes: {
    label: string;
    x: number;
    y: number;
    isClickable: boolean;
    navigateTo: string;
  }[];
  clickableAreas: {
    [wrapperId: string]: { x: number; y: number }[];
  };
  label: string;
  key: string;
  backgroundImage: string;
}
