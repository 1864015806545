import { Component } from "@angular/core";

@Component({
    selector: 'bl-LLabs-login-component',
    templateUrl: './login-page-component.template.html',
    styleUrls: ['./login-page.component.css']
})

export class LoginPageComponent {
    ngOnInit() {
        console.log('Inside login page component !!')
    };

    ngOnChange() {
        console.log('when component updates ***');
    }
}