import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteLoaderComponent } from './route-loader/route-loader.component';
import { LoaderModule } from '@bertlabs-nova/ui/loader';

@NgModule({
  imports: [CommonModule, LoaderModule],
  declarations: [RouteLoaderComponent],
  exports: [RouteLoaderComponent]
})
export class NavigationRouteLoaderModule {}
