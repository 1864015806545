<div *ngIf="service.selectedTemplate$ | async; else selectTemplate">
  <bl-dynamic-node-mapper></bl-dynamic-node-mapper>
</div>
<ng-template #selectTemplate>
  <div>Templates:</div>
  <div
    class="templateObj"
    *ngFor="let template of graphicsTemplates$ | async; let i = index"
  >
    <div (click)="service.selectTemplate(template.key)">
      {{ template.label }}
    </div>
    <button (click)="service.removeTemplate(template.key)">Delete</button>
  </div>
  <button (click)="service.addNewTemplate()">New Template</button>
  <button (click)="service.saveAllTemplates()">Save Templates</button>
</ng-template>
