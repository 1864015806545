import { Injectable, Inject } from '@angular/core';
import { Observable, Observer, BehaviorSubject, throwError } from 'rxjs';
import { take, map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User, IUser, Roles } from '@bertlabs-nova/types/nova-types';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

interface IAuthConfig {
  authUrl: string;
  appName: string;
  appCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userSubject = new BehaviorSubject<User>(this.localUserData());

  user$ = this.userSubject.asObservable();

  constructor(
    private router: Router,
    private _http: HttpClient,
    @Inject('auth-config') private _config: IAuthConfig
  ) {}

  getConfig() {
    return { ...this._config };
  }

  isAuthenticated() {
    return this.user$.pipe(
      take(1),
      map(user => !!user)
    );
  }

  getUser() {
    return this.user$.pipe(take(1));
  }

  localUserData() {
    const userDataString = localStorage.getItem('user');
    if (!userDataString) return null;
    return JSON.parse(userDataString) as IUser;
  }

  allowedFor(roles: Roles[]) {
    // to conditionally render a component, we can use *ngIf="allowedFor(Roles.admin) | async"
    // in above example the component will be available for only the mentioned roles
    return this.user$.pipe(
      take(1),
      map(user => roles.includes(user.role))
    );
  }

  handleError = (errResponse: HttpErrorResponse) => {
    // handle all possible error messages from the server, throw different errors
    // based on that
    let message = 'Something went wrong please try again';

    switch (errResponse.status) {
      case 0:
        message = 'Cannot connect to the server, try again later';
        break;

      case 401:
        message = 'Email or Password provided are invalid';
        break;
      case 408:
        message =
          'Request timed out. Check your internet connection and try again later';
        break;
    }

    return throwError({
      message,
      status: errResponse.status
    });
  };

  handleAuthentication = (authResponseData: User) => {
    localStorage.setItem('user', JSON.stringify(authResponseData));
    this.userSubject.next(authResponseData);
    this.router.navigate(['']);
  };

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['authenticate']);
  }

  logIn(email: string, password: string): Observable<User> {
    if (email === 'bertlabs' && password === 'bertlabs') {
      return Observable.create(obs => {
        obs.next({
          token: 'ewfwefew',
          username: 'Test',
          email: 'test@bertlabs.com',
          site: 'site1',
          role: Roles.admin,
          client: 'HUL'
        });
        obs.complete();
      }).pipe(tap(this.handleAuthentication));
    }

    // handle signin, return an observable and handle errors, completion on it
    const a: Observable<User> = Observable.create(
      (observer: Observer<IUser>) => {
        // this will be a http service in the future
        // config authurl is - localhost:3100/auth/login
        // AppName  - Building Management
        this._http
          .post(this._config.authUrl, {
            email,
            password
          })
          .subscribe(
            (res: any) => {
              console.log(res);
              observer.next({
                token: res.access_token, // todo Access_token, refresh_token mechanicsm
                ...res.user
              });
              observer.complete();
            },
            err => {
              observer.error(err);
            }
          );
      }
    );

    // a will be a http observable, we will handle errors and set authentication status
    // then let the result pass to the authentication form
    // when this observables completes without error, we will redirect to app
    return a.pipe(
      take(1),
      catchError(this.handleError),
      tap(this.handleAuthentication)
    );
  }
}
