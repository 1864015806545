<div>
  <div>Home Graphics Template</div>
  <input #gt [value]="(service.getInfo(['home']) | async)?.graphicsTemplate" />
  <button (click)="handleGtUpdate()">Update graphics template</button>
  <button (click)="logConfig()">Save Config</button>
</div>
<div>
  <h2>Push config to server</h2>
  <button (click)="configPushService.pushConfig()">Push</button>
</div>
<hr />
<div *ngFor="let key of children | async">
  <bl-layout-block [selector]="[key]"></bl-layout-block>
</div>
<button (click)="addBlock()">Add</button>
<bl-add-edit-modal></bl-add-edit-modal>
