import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'ahu-blending-component',
    templateUrl: './ahu-blending-screen.template.html',
    styleUrls: ['../ahu-screen-108.component.css']
})

export class AHUBlendingComponent {
    @Input() tabletCoating: boolean = false;
    locationURL: any;
    imageURL: any;

    constructor(private router: Router) {} 

    ngOnInit() {
        this.tabletCoating = true;
        this.locationURL = window.location.href;
    };

    ngOnChange() {
        console.log('inside ngOnChange');
    }

    ngOnUpdate() {
        console.log('inside update **');
    }

    routeToAHUNumber(ahu) {
        if(ahu === 'ahu-117') {
            this.router.navigate(['app/ahu-117']);
        }
        if(ahu === 'ahu-212') {
            this.router.navigate(['app/ahu-212']);
        }   
        else {
            this.router.navigate(['app/ahu-118']);
        }
    }

    navigateBack() {
        this.router.navigate(['app/ahu-home']);
    }

    navigateToHome() {
        this.router.navigate(['app/ahu-home']); 
    }

    logout() {
        localStorage.removeItem('user');
        location.reload();
    }
}