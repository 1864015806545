<div class="ahu-component">
  <div class="top-bar">
    <a class="active" style="position: fixed" href="/home">
      <!-- <img src="../assets/laurus-labs/Image 1.png" /> | -->
      <img
        src="../assets/laurus-labs/Bert Labs.png"
        style="margin-top: 3px;"
        height="50px"
        width="223px"
      />
    </a>
    <h1 style="position: fixed;left: 41%;opacity: 1;color: #3D2C5A;">
      GRANULATION
    </h1>
    <a style="position: fixed; right: 5%;" (click)="logout()">
      <img src="../assets/laurus-labs/sign-out.png" style="margin-top: 17px;" />
    </a>
  </div>
  <div class="side-nav-bar">
    <div class="side-nav-bar">
      <div>
        <a (click)="navigateToHome()"
          ><img src="../assets/laurus-labs/home-icon.png" class="style-home" />
        </a>
      </div>
      <a (click)="navigateBack()"
        ><img src="../assets/laurus-labs/back-button.png" class="style-home" />
      </a>
      <img
        src="../assets/laurus-labs/set-1.png"
        class="style-home"
        style="margin-top:1087%;"
      />
      <img src="../assets/laurus-labs/settings-icon.png" class="style-home" />
      <!-- <img src="../assets/laurus-labs/info-icon.png" /> -->
    </div>
  </div>
  <div class="row" style="margin-top: 16%; margin-left:5%;">
    <div class="col" style="margin-left:15%; top:18%;">
      <a routerLink="../ahu-108" (click)="routeToAHUNumber('ahu-108')">
        <img src="../assets/laurus-labs/ahu-108.png" height="40%" width="75%" />
      </a>
    </div>
    <div class="col" style="top:19%; left: 15%;">
      <a routerLink="../ahu-109" (click)="routeToAHUNumber('ahu-109')">
        <img
          src="../assets/laurus-labs/Group 951.png"
          height="40%"
          width="75%"
        />
      </a>
    </div>
    <div class="col" style="top:19%; left: 15%;">
      <a routerLink="../ahu-119" (click)="routeToAHUNumber('ahu-119')">
        <img
          src="../assets/laurus-labs/Group 952.png"
          height="40%"
          width="75%"
        />
      </a>
    </div>
  </div>
</div>
