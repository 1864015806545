import { Component, OnInit } from '@angular/core';
import { ConfigurationUpdateService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';

@Component({
  selector: 'bl-hvac-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private configurationUpdateService: ConfigurationUpdateService) {}

  ngOnInit() {
    this.configurationUpdateService.checkForUpdate();
  }
}
