import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import {
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { interval, Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bl-graphical-ahu-on-off',
  templateUrl: './graphical-ahu-on-off.component.html',
  styleUrls: ['./graphical-ahu-on-off.component.css']
})
export class GraphicalAhuOnOffComponent implements OnInit {

  active : boolean;
  inActive : boolean;
  notAvailable : boolean;
  @Input() reading$: Observable<{ data: any; found: boolean }>;
  
  isNeutral : boolean;
  progress : any;
  reading : any;
  
  constructor(public themeService: ThemeService) {
    this.active = false;
    this.inActive = false;
    this.notAvailable = true;
    interval(1000).subscribe(() => {
      // fired every second
      this.reading$.subscribe(data=>{
        // console.log(data)
        this.reading = data.data
        if(parseInt(this.reading) === 0){
          this.inActive = true;
          this.active = false;
          this.notAvailable = false;
        }else if(parseInt(this.reading) === 1){
          this.active = true;
          this.inActive = false;
          this.notAvailable = false;
        }else{
          this.notAvailable = true;
          this.inActive = false;
          this.active = false;
        }
      })
    });
  }

  ngOnInit() {
  }

}


