import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthFormComponent } from './auth-form/auth-form.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: 'authenticate',
        component: AuthFormComponent
      },
      {
        path: 'authenticate/**',
        redirectTo: 'authenticate'
      }
    ])
  ],
  declarations: [AuthFormComponent]
})
export class AuthenticationModule {
  public static forRoot(
    authUrl: string,
    appName: string,
    appCode: string
  ): ModuleWithProviders {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: 'auth-config',
          useValue: {
            authUrl,
            appName,
            appCode
          }
        }
      ]
    };
  }
}
