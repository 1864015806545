import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  IEquipmentTemplateRow,
  IEquipmentTemplateComponent,
  EquipmentTemplateRowTypes,
  IEquipmentTemplateRowDevice
} from '@bertlabs-nova/types/nova-types';
import { EquipmentTemplateGeneratorService } from '../equipment-template-generator.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'bl-template-row',
  templateUrl: './template-row.component.html',
  styleUrls: ['./template-row.component.css']
})
export class TemplateRowComponent implements OnInit {
  graphicsTemplates: Observable<{ label: string; key: string }[]>;

  public justifyContentTypes = [
    'left',
    'center',
    'flex-end',
    'space-between',
    'space-around'
  ];
  public alignItemsTypes = ['center', 'flex-start', 'flex-end', 'baseline'];
  public alignSelfTypes = ['left', 'center', 'flex-end'];

  // COMPONENT GEENRATOR MODAL

  private _componentGeneratorModal = new BehaviorSubject<{
    componentIndex: number;
    componentData: IEquipmentTemplateComponent;
    rowIndex: number;
  } | null>(null);

  public $componentGeneratorModal = this._componentGeneratorModal.asObservable();

  get componentModalIsOpen() {
    return this._componentGeneratorModal.pipe(
      take(1),
      map(modal => !!modal)
    );
  }

  closeComponentModal = () => {
    console.log('closing');
    this._componentGeneratorModal.next(null);
  };

  openComponentModal(
    rowIndex: number,
    componentIndex: number,
    componentData: IEquipmentTemplateComponent
  ) {
    this._componentGeneratorModal.next({
      componentData,
      componentIndex,
      rowIndex
    });
  }

  // DEVICE MODAL

  private _deviceModal = new BehaviorSubject<{
    rowIndex: number;
    deviceIndex: number;
    deviceData: IEquipmentTemplateRowDevice;
  } | null>(null);

  public $deviceModal = this._deviceModal.asObservable();

  get deviceModalIsOpen() {
    return this._deviceModal.pipe(
      take(1),
      map(modal => !!modal)
    );
  }

  closeDeviceModal = () => {
    this._deviceModal.next(null);
  };

  openDeviceModal(
    rowIndex: number,
    deviceIndex: number,
    deviceData: IEquipmentTemplateRowDevice
  ) {
    this._deviceModal.next({
      rowIndex,
      deviceData,
      deviceIndex
    });
  }

  saveIoTDevice = (data: IEquipmentTemplateRowDevice) => {
    this._deviceModal.pipe(take(1)).subscribe(deviceModal => {
      this.service.editDeviceInfo(
        deviceModal.rowIndex,
        deviceModal.deviceIndex,
        data
      );
      this._deviceModal.next({
        rowIndex: deviceModal.rowIndex,
        deviceData: data,
        deviceIndex: deviceModal.deviceIndex
      });
    });
  };

  deleteIoTDevice = () => {
    this._deviceModal.pipe(take(1)).subscribe(deviceModal => {
      this.service.removeDevice(deviceModal.rowIndex, deviceModal.deviceIndex);
    });
  };

  @Input() rowIndex: number;
  @Input() rowData: IEquipmentTemplateRow;

  @ViewChild('rowLabel') rowLabel;
  @ViewChild('rowType') rowType;
  @ViewChild('rowHeight') rowHeight;
  @ViewChild('graphicsTemplate') graphicsTemplate;
  @ViewChild('justifyContent') justifyContent;
  @ViewChild('alignItems') alignItems;
  @ViewChild('alignSelf') alignSelf;
  @ViewChild('widthPercent') widthPercent;

  updateRowInfo() {
    if (this.rowData.type === EquipmentTemplateRowTypes.graphical)
      this.service.editRowInfo(this.rowIndex, {
        label: this.rowLabel.nativeElement.value,
        type: this.rowType.nativeElement.value,
        heightPercent: +this.rowHeight.nativeElement.value,
        justifyContent: this.justifyContent.nativeElement.value,
        alignItems: this.alignItems.nativeElement.value,
        alignSelf: this.alignSelf.nativeElement.value,
        graphicsTemplate: this.graphicsTemplate.nativeElement.value || null,
        widthPercent: +this.widthPercent.nativeElement.value
      });
    else {
      this.service.editRowInfo(this.rowIndex, {
        label: this.rowLabel.nativeElement.value,
        type: this.rowType.nativeElement.value,
        justifyContent: this.justifyContent.nativeElement.value,
        alignItems: this.alignItems.nativeElement.value,
        alignSelf: this.alignSelf.nativeElement.value,
        heightPercent: +this.rowHeight.nativeElement.value,
        widthPercent: +this.widthPercent.nativeElement.value
      });
    }
  }

  addNewComponent() {
    this.service.addComponent(this.rowIndex);
  }

  addNewDevice() {
    this.service.addDevice(this.rowIndex);
  }

  addNewHeaderDevice() {
    this.service.addHeaderDevice(this.rowIndex);
  }

  deleteRow() {
    this.service.removeRow(this.rowIndex);
  }

  constructor(public service: EquipmentTemplateGeneratorService) {}

  ngOnInit(): void {
    console.log(this.rowData, this.rowIndex);
    this.graphicsTemplate = this.service.graphicsTemplates$;
  }
}
