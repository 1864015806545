import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Renderer2
} from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

@Component({
  selector: 'bl-route-loader',
  templateUrl: './route-loader.component.html',
  styleUrls: ['./route-loader.component.css']
})
export class RouteLoaderComponent implements OnInit {
  @ViewChild('spinnerElement')
  spinnerElement: ElementRef;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {
    router.events.subscribe(this._navigationInterceptor);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private _navigationInterceptor = (event: RouterEvent) => {
    if (event instanceof NavigationStart) {
      // We wanna run this function outside of Angular's zone to
      // bypass change detection
      this.ngZone.runOutsideAngular(() => {
        // For simplicity we are going to turn opacity on / off
        // you could add/remove a class for more advanced styling
        // and enter/leave animation of the spinner
        this.renderer.setStyle(
          this.spinnerElement.nativeElement,
          'display',
          'flex'
        );
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
    }
    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  };

  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {
      // For simplicity we are going to turn opacity on / off
      // you could add/remove a class for more advanced styling
      // and enter/leave animation of the spinner
      this.renderer.setStyle(
        this.spinnerElement.nativeElement,
        'display',
        'none'
      );
    });
  }

  ngOnInit() {}
}
