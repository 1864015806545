import { Injectable, Inject, InjectionToken } from '@angular/core';
import { IAppTheme } from '@bertlabs-nova/types/nova-types';

export const DefaultTheme = new InjectionToken<IAppTheme>('bl-default-theme');

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor(
    @Inject(DefaultTheme)
    private _theme: IAppTheme[]
  ) {}

  get theme() {
    return this._theme[0];
  }

  setTheme(theme: IAppTheme) {
    this._theme = [{ ...theme }];
  }
}
