export enum EquipmentTemplateObjectStores {
  'equipment-templates' = 'equipment-templates'
}

export interface IEquipmentTemplateRowDevice {
  parameterName: string;
  endpointPlaceholder: string;
  fontSizeEm: number;
  fontColor: string;
  alignment: 'left' | 'center' | 'right';
  displayType: string;
  label: string;
  units: string;
  range?: [number, number];
  readingType: 'string' | 'numeric';
  flagRanges?: { rangeLow: number; rangeHigh: number; flag: string }[];
}

export enum EquipmentTemplateRowTypes {
  data = 'data',
  graphical = 'graphical',
  mainData = 'mainData',
  mainComponentData = 'mainComponentData',
  componentData = 'componentData',
  newRow = 'newRow'
}

interface IEquipmentTemplateComponentNestedCell {
  isMainStructure?: boolean;
  isNested: true;
  width: number;
  data: IEquipmentTemplateComponentRow[];
}

interface IEquipmentTemplateComponentIoTCell {
  isMainStructure?: boolean;
  isNested: false;
  width: number;
  data: IEquipmentTemplateRowDevice | null;
}

export type IEquipmentTemplateComponentCell =
  | IEquipmentTemplateComponentNestedCell
  | IEquipmentTemplateComponentIoTCell;

export interface IEquipmentTemplateComponentRow {
  data: IEquipmentTemplateComponentCell[];
  height: number;
}

export interface IEquipmentTemplateComponent {
  label: string;
  width: number;
  justifyContent: string;
  height: number;
  structure: IEquipmentTemplateComponentCell;
}

export interface IEquipmentTemplateRow {
  heightPercent: number;
  widthPercent: number;
  justifyContent: string;
  alignItems: string;
  alignSelf: string;
  label: string;
  headerDevices?: IEquipmentTemplateRowDevice[];
  devices: IEquipmentTemplateRowDevice[];
  components: IEquipmentTemplateComponent[];
  type: EquipmentTemplateRowTypes;
  graphicsTemplate?: string;
}

export interface IEquipmentTemplateIndexDB {
  data: IEquipmentTemplateRow[];
  label: string;
  key: string;
}
