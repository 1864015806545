<hr />
<!-- <div
  class="sampleLayout"
  *blAppLayout="
    let l = label;
    let s = selector;
    onlyDisplay: 'home-navigation';
    isHeader: true
  "
>
  {{ l }}
  <hr />
  <div
    class="sampleLayout"
    *blAppLayout="let cl = label; selector: s; onlyDisplay: 'home-navigation'"
  >
    {{ cl }}
  </div>
</div> -->
<div
  class="sampleLayout"
  *blAppLayout="let label = label; onlyDisplay: 'home-navigation'"
>
  {{ label }}
</div>
