import { Injectable } from '@angular/core';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import { Observable, Observer, concat } from 'rxjs';
import { ConfigurationUpdateService } from './configuration-update.service';
import {
  IAppLayoutIndexDBObject,
  ConfigurationObjectStores,
  IWrapperInfo,
  IEquipmentInfo,
  INDEXDB_KEYPATH
} from '@bertlabs-nova/types/nova-types';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  // not using an observable, also will never change the reference to this object
  // to ensure app layout remains static once loaded (using on-push)

  // config refers to the nested app-layout object which give info about the position
  // of the wrappers
  config: Object = {};
  // gives info about a specific wrapper given it's id
  wrapperInfo: { [uid: string]: IWrapperInfo } = {};
  // gives info about a specific equipment
  equipmentInfo: { [uid: string]: IEquipmentInfo } = {};

  // select a section of the config. The selection is made using
  // the selector, which is an array of uids of wrappers pointing
  // to a specific object whose information is requested
  getConfig(layoutSelector: string[]) {
    let selectedConfig = this.config;
    layoutSelector.forEach(key => {
      selectedConfig = selectedConfig[key];
    });
    return selectedConfig;
  }

  // get children of a specific wrapper pointed to by the selector
  // as an array of the children uids
  getChildren(layoutSelector: string[]) {
    const selectedConfig = this.getConfig(layoutSelector);

    if (typeof selectedConfig === 'object') return Object.keys(selectedConfig);
    else {
      console.error('No Children exist for this wrapper Invalid');
      return [];
    }
  }
  // get the info of an equipment/ wrapper given its uid
  getInfo(uniqueId: string): IWrapperInfo | IEquipmentInfo {
    if (this.wrapperInfo[uniqueId]) return this.wrapperInfo[uniqueId];
    if (this.equipmentInfo[uniqueId]) return this.equipmentInfo[uniqueId];
    else {
      //console.error('No info available for id: ', uniqueId);
      return null;
    }
  }
  // method to load the equipment info stored locally (index-db)
  private loadLocalEquipmentInfo(): Observable<void> {
    // return a completable observable, if it completes, it means config was loaded
    // otherwise handle the error
    return Observable.create((observer: Observer<void>) => {
      // request the data from idb
      this.idb
        .getAll<IEquipmentInfo>(ConfigurationObjectStores['equipment-info'])
        .subscribe(
          data => {
            // if data exists, set wrapper info as object
            if (data) {
              data.forEach(equipmentInfo => {
                // data is an array containing all required objects, we need to
                // parse it to an object to get info of required wrapper using
                // it's uid only

                // equipmentInfo[INDEXDB_KEYPATH] gives the wrapper id
                // create this property on the equipmentInfo Object
                // and set it equal to the equipmentInfo object
                this.equipmentInfo[
                  equipmentInfo[INDEXDB_KEYPATH]
                ] = equipmentInfo;
              });
            }
            // else emit an error
            else observer.error(new Error('No data recieved from index-db'));
          },
          err => {
            // if the idb service threw an error, emit an error
            observer.error(
              new Error(err.message || 'Cannot retrieve data from index-db')
            );
          },
          () => {
            // success!
            observer.complete();
          }
        );
    });
  }
  // method to load the wrapper info stored locally
  private loadLocalWrapperInfo(): Observable<void> {
    // return a completable observable, if it completes, it means config was loaded
    return Observable.create((observer: Observer<void>) => {
      // request the data from idb
      this.idb
        .getAll<IWrapperInfo>(ConfigurationObjectStores['wrapper-info'])
        .subscribe(
          data => {
            // if data exists, set wrapper info as object
            if (data) {
              data.forEach(wrapperInfo => {
                // wrapperInfo[INDEXDB_KEYPATH] gives the wrapper id
                // create this property on the wrapperInfo Object
                // and set it equal to the wrapperInfo object
                this.wrapperInfo[wrapperInfo[INDEXDB_KEYPATH]] = wrapperInfo;
              });
            }
            // else emit an error
            else observer.error(new Error('No data recieved from index-db'));
          },
          err => {
            // if the idb service threw an error, emit an error
            observer.error(
              new Error(err.message || 'Cannot retrieve data from index-db')
            );
          },
          () => {
            // success!
            observer.complete();
          }
        );
    });
  }
  // method to load the app-layout config stored locally
  private loadLocalAppLayout(): Observable<void> {
    // return a completable observable, if it completes, it means config was loaded
    return Observable.create((observer: Observer<void>) => {
      // request the data from idb
      this.idb
        .get<IAppLayoutIndexDBObject>(
          ConfigurationObjectStores['app-layout'],
          'data'
        )
        .subscribe(
          data => {
            // if data exists. set config
            if (data && data.data) this.config = data.data;
            // else emit an error
            else observer.error(new Error('No data recieved from index-db'));
          },
          err => {
            // if the idb service threw an error, emit an error
            observer.error(
              new Error(err.message || 'Cannot retrieve data from index-db')
            );
          },
          () => {
            // success!

            observer.complete();
          }
        );
    });
  }
  // method to load all data stored locally in succession
  private loadLocalConfig() {
    return concat(
      this.loadLocalAppLayout(),
      this.loadLocalWrapperInfo(),
      this.loadLocalEquipmentInfo()
    );
  }

  loadConfig() {
    return Observable.create((observer: Observer<void>) => {
      // subscribe to the localConfig observable
      this.loadLocalConfig().subscribe({
        // if it threw an error, pull information from the server
        // as we could not get data locally
        error: (err: any) => {
          // pull information from server
          // and then observer.complete()
          console.error(err);
          this.configUpdateService.syncConfigFromServer(0).subscribe({
            complete: () => {
              // the server response came through, complete loading process successfully
              observer.complete();
            },
            next: dataFromServer => {
              // set the server response in the service state
              // the data recieved is automatically stored to idb for next use
              this.config = dataFromServer.appLayout.data;
              dataFromServer.equipmentInfo.forEach(data => {
                this.equipmentInfo[data.key] = data;
              });
              dataFromServer.wrapperInfo.forEach(data => {
                this.wrapperInfo[data.key] = data;
              });
            }
          });
        },

        complete: () => {
          // successfully setup the config (from local db)

          observer.complete();
        }
      });
    }) as Observable<void>;
  }

  constructor(
    private idb: IndexDbService,
    private configUpdateService: ConfigurationUpdateService
  ) {}
}
