import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  check = faCheckCircle;
  cross = faTimesCircle;

  @Input() reading$: Observable<{ data: any; found: boolean }>;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
