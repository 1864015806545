import { Component, OnInit, Input, Optional, OnDestroy } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { IEquipmentTemplateRowDevice } from '@bertlabs-nova/types/nova-types';
import { VisualLayerTypes } from '../viusalLayerTypes';
import { NetworkLayerService } from '@bertlabs-nova/iot-device-model/network-layer';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-iot-model',
  templateUrl: './iot-model.component.html',
  styleUrls: ['./iot-model.component.css']
})
export class IotModelComponent implements OnInit, OnDestroy {
  @Input() deviceData: IEquipmentTemplateRowDevice;
  @Optional() @Input() isStatic: boolean;

  vlt = VisualLayerTypes;

  reading$: Observable<{ data: any | number; found: boolean }>;

  constructor(
    public themeService: ThemeService,
    private _networkService: NetworkLayerService
  ) {}

  ngOnInit(): void {
    if (
      !this.isStatic &&
      this.deviceData.endpointPlaceholder &&
      this.deviceData.parameterName &&
      this.deviceData.displayType !== VisualLayerTypes.textLabel
    )
      this.reading$ = this._networkService.getRealtimeData(
        this.deviceData.endpointPlaceholder,
        this.deviceData.parameterName
      );
    else if (this.isStatic) {
      this.reading$ = new Observable(observer => {
        observer.next({
          data: 100,
          found: true
        });
      });
    }
    this.reading$.subscribe(data =>
      console.log(data, this.deviceData.parameterName)
    );
  }

  ngOnDestroy(): void{
    this._networkService.stopRealTimeData()
  }
}
