<div class="ahu-component">
  <div class="top-bar">
    <a class="active" style="position: fixed" href="/home">
      <!-- <img src="../assets/laurus-labs/Image 1.png" /> | -->
      <img
        src="../assets/laurus-labs/Bert Labs.png"
        style="margin-top: 3px;"
        height="50px"
        width="223px"
      />
    </a>
    <h1 style="position: fixed;left: 41%;opacity: 1;color: #3D2C5A;">
      GRANULATION & BLENDING
    </h1>
    <a style="position: fixed; right: 5%;" (click)="logout()">
      <img src="../assets/laurus-labs/sign-out.png" style="margin-top: 17px;" />
    </a>
  </div>
  <div class="side-nav-bar">
    <div>
      <a (click)="navigateToHome()"
        ><img src="../assets/laurus-labs/home-icon.png" class="style-home" />
      </a>
    </div>
    <a (click)="navigateBack()"
      ><img src="../assets/laurus-labs/back-button.png" class="style-home" />
    </a>
    <img
      src="../assets/laurus-labs/set-1.png"
      class="style-home"
      style="margin-top:1087%;"
    />
    <img src="../assets/laurus-labs/settings-icon.png" class="style-home" />
    <!-- <img src="../assets/laurus-labs/info-icon.png" /> -->
  </div>
  <div class="row" style="margin-top: 16%; margin-left:10%;">
    <div class="col" style="margin-left:15%; top:38%;">
      <a routerLink="../ahu-168" (click)="routeToAHUNumber('ahu-168')">
        <img
          src="../assets/laurus-labs/ahu-168 (2).png"
          height="50%"
          width="80%"
        />
      </a>
    </div>

    <div class="col" style="top:19%; left: 15%;">
      <a routerLink="../ahu-169" (click)="routeToAHUNumber('ahu-169')">
        <img
          src="../assets/laurus-labs/Group 984.png"
          height="50%"
          width="80%"
        />
      </a>
    </div>
  </div>
  <ng-template *ngIf="tabletCoating">
    <div class="ahu-component">
      <a class="active" href="/home">
        <!-- <img src="../assets/laurus-labs/Image 1.png" /> | -->
        <img
          src="../assets/laurus-labs/Bert Labs.png"
          style="margin-top: 3px;"
          height="50px"
          width="223px"
        />
      </a>
      <h3><b> COMPRESSION AND TABLET COATING </b></h3>
      <div class="side-nav-bar"></div>
      <div class="row">
        <div class="col" style="margin-left:15%; top:18%;">
          <a href="#">
            <img
              src="../assets/laurus-labs/ahu-173 (2).png"
              height="40%"
              width="95%"
            />
          </a>
        </div>

        <div class="col" style="top:18%; left: 25%;">
          <a href="#">
            <img
              src="../assets/laurus-labs/ahu-174 (2).png"
              height="40%"
              width="95%"
            />
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</div>
