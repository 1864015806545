<div 
    class="device"
    *ngIf="active" 
    [ngStyle]="{ 'background': themeService.theme.green }"
>
</div>
<div 
    class="device"
    *ngIf="inActive" 
    [ngStyle]="{ 'background': 'red' }"
>
</div>
<div 
    class="device"
    *ngIf="notAvailable" 
    [ngStyle]="{ 'background': 'grey' }"
>
</div>
