import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Renderer2,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  IEquipmentTemplateRow,
  IGraphicsTemplateIndexDB,
  GraphicsObjectStores
} from '@bertlabs-nova/types/nova-types';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import { PolygonPointsPipe } from './polygon-points.pipe';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';
import { ConfigurationService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';
import { take, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bl-node-viewer',
  templateUrl: './node-viewer.component.html',
  styleUrls: ['./node-viewer.component.css'],
  providers: [PolygonPointsPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NodeViewerComponent implements OnInit {
  @ViewChild('mapperContainer') mapperContainer;
  @ViewChild('imageContainer') imageContainer;

  @Input() rowData: IEquipmentTemplateRow;
  template = new BehaviorSubject<IGraphicsTemplateIndexDB>({
    backgroundImage: '',
    clickableAreas: {},
    key: '',
    label: '',
    nodes: {},
    staticNodes: []
  });
  selector = new BehaviorSubject<string[] | null>(null);

  calcLeft(parameterName: string) {
    return this.template.pipe(
      map(template => {
        if (template.nodes[parameterName])
          return template.nodes[parameterName].x * 100 + '%';
        else return '0';
      })
    );
  }
  calcTop(parameterName: string) {
    return this.template.pipe(
      map(template => {
        if (template.nodes[parameterName])
          return template.nodes[parameterName].y * 100 + '%';
        else return '0';
      })
    );
  }

  setHeight() {
    if (this.mapperContainer && this.imageContainer) {
      const newHeight = this.imageContainer.nativeElement.clientHeight + 'px';

      this.renderer.setStyle(
        this.mapperContainer.nativeElement,
        'height',
        newHeight
      );
    }
  }

  constructor(
    private _idb: IndexDbService,
    private renderer: Renderer2,
    private _selectorService: SelectorService,
    private _configService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this._idb
      .get<IGraphicsTemplateIndexDB>(
        GraphicsObjectStores['graphics-templates'],
        this.rowData.graphicsTemplate
      )
      .subscribe(template => {
        this.template.next(template);
      });
    this._selectorService.equipmentSelector$
      .pipe(take(1))
      .subscribe(equipmentId => {
        this._selectorService
          .getEquipmentSelector(equipmentId)
          .subscribe(sel => {
            this.selector.next(sel);
          });
      });
    // this._selectorService.layoutSelector$.pipe(take(1)).subscribe(selector => {
    //   this._selectorService.equipmentSelector$.pipe(take(1)).subscribe(equipmentId => {
    //     const newSelector = [...selector];
    //     const recBuildselector = (id: string) => {
    //       const info = this._configService.getInfo(id);
    //       const parent = info ? info.parent : null;
    //       if (parent && parent[0] && selector[selector.length - 1] !== parent[0]) {
    //         recBuildselector(parent[0]);
    //       }
    //       newSelector.push(id);
    //     }

    //     recBuildselector(equipmentId);
    //     this.selector.next(newSelector);
    //   })
    // })
  }
}
