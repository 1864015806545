<div *ngIf="!!data" class="background">
  <div class="modal">
    <form #form="ngForm" (ngSubmit)="submit()">
      <div>Label</div>
      <input [ngModel]="data.label" name="label" type="text" />
      <div>Type</div>
      <div>
        <input
          type="radio"
          [ngModel]="data.type"
          name="type"
          value="home-navigation"
        /><span>Home Navigation</span>
        <input
          type="radio"
          [ngModel]="data.type"
          name="type"
          value="side-navigation"
        /><span>Side Navigation</span>
        <input
          type="radio"
          [ngModel]="data.type"
          name="type"
          value="equipment"
        /><span>Equipment</span>
        <input
          type="radio"
          [ngModel]="data.type"
          name="type"
          value="none"
        /><span>None</span>
      </div>

      <div>
        <div>Images (Optional, seperated by commas)</div>
        <input name="image" [ngModel]="data.image" />
      </div>

      <div *ngIf="data.type === 'home-navigation'">
        <div>Graphics Template (Optional)</div>
        <select name="graphicsTemplate" [ngModel]="data?.graphicsTemplate">
          <option [value]="null">None</option>
          <option
            [value]="gTemplate.key"
            *ngFor="let gTemplate of service.graphicsTemplates$ | async"
          >
            {{ gTemplate.label }}
          </option>
        </select>
      </div>

      <div
        *ngIf="
          data.type === 'equipment' ||
          data.type === 'side-navigation' ||
          data.type === 'home-navigation'
        "
      >
        <div>
          Equipment Template
          <span
            *ngIf="
              data.type === 'home-navigation' || data.type === 'side-navigation'
            "
            >(Optional)</span
          >
        </div>
        <select name="equipmentTemplate" [ngModel]="data.equipmentTemplate">
          <option *ngIf="data.type !== 'equipment'" [value]="null">
            None
          </option>
          <option
            *ngFor="let template of equipmentTemplates"
            [value]="template.key"
            >{{ template.label }}</option
          >
        </select>
        <div>Endpoints (specify as placeholder@url seperated by commas)</div>
        <div style="color: rgba(0,0,0,0.5); font-size: 0.8em;">
          e.g. ahu@data/B1/F2 -> here ahu is placeholder (to be used inside the
          equipment template) and 'data/B1/F2' is endpoint url to fetch the data
          from
        </div>
        <textarea size=100  name="endpoints" [ngModel]="endpointsString" ></textarea>
      </div>

      <div *ngIf="data['child-type'] !== 'none'">
        <div>Child Type</div>
        <div>
          <input
            type="radio"
            [ngModel]="data['child-type']"
            name="childType"
            value="home-navigation"
          /><span>Home Navigation</span>
          <input
            type="radio"
            [ngModel]="data['child-type']"
            name="childType"
            value="side-navigation"
          /><span>Side Navigation</span>
          <input
            type="radio"
            [ngModel]="data['child-type']"
            name="childType"
            value="equipment"
          /><span>Equipment</span>
          <input
            type="radio"
            [ngModel]="data['child-type']"
            name="childType"
            value="none"
          /><span>None</span>
        </div>
      </div>
      <div>Clickable</div>
      <div>
        <input
          type="radio"
          [ngModel]="data.clickable"
          name="clickable"
          [value]="true"
        /><span>True</span>
        <input
          type="radio"
          [ngModel]="data.clickable"
          name="clickable"
          [value]="false"
        /><span>False</span>
      </div>
      <button type="submit">OK</button>
      <button type="button" (click)="close()">Cancel</button>
    </form>
  </div>
</div>
