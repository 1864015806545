<div
  *blAppLayout="
    let label = label;
    let selector = selector;
    let info = info;
    isHeader: true;
    selector: mySelector
  "
>
  <div *ngIf="info.type === 'equipment'; else dropdown">
    <div class="item">
      {{ label }}
    </div>
  </div>
  <ng-template #dropdown>
    <bl-hvac-dropdown-header [label]="label">
      <bl-hvac-navigation-element
        [selector]="selector"
      ></bl-hvac-navigation-element>
    </bl-hvac-dropdown-header>
  </ng-template>
</div>
