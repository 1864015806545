<button (click)="goBack()">Back</button>
<div class="container">
  <div>Label</div>
  <input #templateLabel type="text" [ngModel]="selectedTemplate.label" />
  <div>Background Image URL</div>
  <input
    #backgroundImage
    type="text"
    [ngModel]="selectedTemplate.backgroundImage"
  />
  <div></div>
  <button (click)="updateTemplateFields()">Update Template Fields</button>
  <hr />
  <div>
    <button
      [disabled]="selectedMode === 'device'"
      (click)="selectMode('device')"
    >
      Add New Device Mode
    </button>
    <button
      [disabled]="selectedMode === 'staticNode'"
      (click)="selectMode('staticNode')"
    >
      Add New Static Node Mode
    </button>
    <button
      [disabled]="selectedMode === 'constructingArea'"
      (click)="selectMode('constructingArea')"
    >
      Add New Clickable Area Mode
    </button>
    <div *ngIf="selectedMode === 'constructingArea'">
      <button (click)="handleAreaCompletion()">
        Add current clickable Area
      </button>
      <button (click)="handleAreaCancel()">
        Remove current clickable Area
      </button>
      <div>Active Clickable areas:</div>
      <div
        *ngFor="
          let wrapperId of (service.selectedTemplate$ | async)?.clickableAreas
            | objectKeys;
          let i = index
        "
      >
        <div>Wrapper Id: {{ wrapperId }}</div>
        <button (click)="handleClickableAreaDeletion(wrapperId)">
          Delete Area
        </button>
      </div>
    </div>
  </div>
  <div
    (window:resize)="setHeight()"
    #mapperContainer
    class="mapperContainer"
    *ngIf="selectedTemplate.backgroundImage"
  >
    <img
      #imageContainer
      (load)="setHeight()"
      class="imageContainer"
      [src]="selectedTemplate.backgroundImage"
    />
    <div class="grid"></div>
    <div (click)="handleClick($event)" class="mapper"></div>
    <div class="svgWrapper">
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon
          style="fill: rgba(255,255,255,0.6)"
          [attr.points]="currentArea | async | polygonPoints"
        ></polygon>
        <polygon
          style="fill: rgba(0,0,0,0.4)"
          [attr.points]="
            ((service.selectedTemplate$ | async)?.clickableAreas)[wrapperId]
              | polygonPoints
          "
          *ngFor="
            let wrapperId of (service.selectedTemplate$ | async)
              ?.clickableAreas | objectKeys;
            let clickableIndex = index
          "
        ></polygon>
      </svg>
    </div>

    <div
      class="device"
      [ngStyle]="{ left: calcLeft(device), top: calcTop(device) }"
      *ngFor="let device of devices | async"
      (click)="handleDeviceDeletion(device)"
    >
      {{ device }}
    </div>
    <div
      class="staticLabel"
      *ngFor="
        let staticNode of (service.selectedTemplate$ | async)?.staticNodes;
        let staticNodeindex = index
      "
      (click)="handleStaticNodeDeletion(staticNodeindex)"
      [ngStyle]="{
        left: staticNode.x * 100 + '%',
        top: staticNode.y * 100 + '%'
      }"
    >
      {{ staticNode.label }}
    </div>
  </div>
  <hr />
</div>
<div class="modal" *ngIf="(addDeviceModal | async) !== null">
  <div>Add new Device</div>
  <div>Parameter Name</div>
  <input #parameterName />
  <div></div>
  <button (click)="handleDeviceAddition()">Add</button>
</div>
<div class="modal" *ngIf="(addStaticNodeModal | async) !== null">
  <div>Add new Static Node</div>
  <div>Label</div>
  <input #staticNodeLabel />
  <div>Is Clickable</div>
  <input #staticNodeIsClickable type="checkbox" />
  <div>Navigate To</div>
  <input #staticNodeNavigateTo />
  <div></div>
  <button (click)="handleStaticNodeAddition()">Add</button>
</div>
<div class="modal" *ngIf="addClickableAreaModal | async">
  <div>Add new Clickable Area</div>
  <div>Wrapper Id</div>
  <input #wrapperId />

  <div></div>
  <button (click)="handleClickableAreaAddition()">Add</button>
</div>
