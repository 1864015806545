import { Component, OnInit, Input } from '@angular/core';
import { IEquipmentTemplateComponentCell } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-component-renderer-cell',
  templateUrl: './component-renderer-cell.component.html',
  styleUrls: ['./component-renderer-cell.component.css']
})
export class ComponentRendererCellComponent implements OnInit {
  @Input() cellData: IEquipmentTemplateComponentCell;

  constructor() {}

  ngOnInit(): void {}
}
