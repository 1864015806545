import {
  Component,
  OnInit,
  Input,
  Optional,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'bl-hvac-navigation-element',
  templateUrl: './navigation-element.component.html',
  styleUrls: ['./navigation-element.component.css']
})
export class NavigationElementComponent implements OnInit {
  @Optional() @Input('selector') mySelector: string[];

  constructor() {}

  ngOnInit(): void {}
}
