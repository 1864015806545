<div class="ui-form-new">
    <!-- <img src="../../../hvac/src/assets/chillerPlant.png" height="100%" width="100%" /> -->
    <!-- <img src="../assets/logo-icon.jpg" alt="bakcground" height="100%" width="100%"/> -->
    <!-- <img
      src="https://images.app.goo.gl/NWRHaaabvYefAXYZA"
      height="100%"
      width="100%"
    /> -->
    <img src="../assets/background-login.png" />
    <img src="../assets/Logo-LL.png" />
    <img src="../assets/Bert Labs@2x.png" />
    <img src="../assets/Rectangle 8.png" />
  
      <!-- <img src="../assets/background-login.png" height="150%" width="150%" />
      <img src="../assets/Bert Labs.png" height="150%" width="150%" />
      <img src="../assets/Bert.png" height="150%" width="150%" /> -->
      Powered By:
    <img src="../assets/Bert Labs@2x.png" height="100%" width="100%" />
  
    <div class="login-form">
      <input type="text" placeholder="Username" class="input-field-1" />
      <input type="text" placeholder="Password" class="input-field-2" />
      <a href="#"><button type="button" class="btn-login">Login</button> </a>
      <p class="forgot-text">Forgot Password ?</p>
    </div>
  </div>