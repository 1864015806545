import { Component, OnInit, Input } from '@angular/core';
import { IEquipmentTemplateRow } from '@bertlabs-nova/types/nova-types';

@Component({
  selector: 'bl-equipment-graphics-row',
  templateUrl: './equipment-graphics-row.component.html',
  styleUrls: ['./equipment-graphics-row.component.css']
})
export class EquipmentGraphicsRowComponent implements OnInit {
  @Input() rowData: IEquipmentTemplateRow;
  @Input() endpoint: string;

  constructor() {}

  ngOnInit(): void {}
}
