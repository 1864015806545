import {
  Directive,
  ViewContainerRef,
  TemplateRef,
  OnInit,
  OnDestroy,
  Input,
  Optional,
  Renderer2
} from '@angular/core';
import { ConfigurationService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  WrapperTypes,
  IWrapperInfo,
  IEquipmentInfo
} from '@bertlabs-nova/types/nova-types';
import { take } from 'rxjs/operators';

///////////////////////////
// USAGE: /////////////////
///////////////////////////
/*
The directive can be placed on any element which we want to repeat based on
the app-layout object (a nested object specifying location of each wrapper)

The directive pulls the selector from the selector service. and loops over the
children at the location specified by the selector and generate elements with information
like label, index, IWrapperInfo etc.

The directive also sets up click listeners on the elements rendered to change the
selector so that we can traverse the app-layout by clicking on these dynamic elements

The directive accepts some properties:
1) onlyDisplay: only the specifies wrapper type will be displayed by the directive
it can be used to ensure we do not see side-nav elements when on home navigation screen
2) isHeader: this is used to avoid setting up click listener on the element being rendered
like in case of dropdowns/ other nested cases. We only want click listneres on the nested
children and not on the parent elements (nesting is done by having two nested *blAppLayout directives)
3) selector: we can pass a selector to override the selector from selector service
this is primarily used in case of nested directive usage. We must specify the selectors
on the nested children otherwise they will pull the selector from selector service
and display same elements as the parent directive

e.g. 1 (non-nested) (see hvac home-navigation component for example usage)

// label is a property pulled from context

<div *blAppLayout="let myLabel = label; onlyDisplay: 'home-navigation'">
  {{myLabel}}
</div>
 the above snippet will render navigable home-navigation wrappers

 e.g. 2 (nested usage) (see hvac side-navigation component for example usage)

 <div *blAppLayout="let parentLabel = label; let mySelector = selector; isHeader: true; ">
  {{parentLabel}}

 // below we pass the selector to override selector from selector service
 // to ensure nested children pull info from correct place
 // this will render children of the parent nested inside parent div!

  <div *blAppLayout="let nestedChildLabel = label; selector: mySelector">
    {{nestedChildLabel}}
  </div>
 </div>

*/
///////////////////////////
///////////////////////////

// context to expose directive properties
// these properties can be pulled in template
// from blAppLayout directive e.g. <div *blAppLayout="let i = index"></div>
// then these properties can be used to customise the children
class AppLayoutDirectiveContext {
  constructor(
    public id: string,
    public index: number,
    public label: string,
    public selector: string[],
    public info: IWrapperInfo
  ) {}
}

@Directive({
  selector: '[blAppLayout]'
})
export class AppLayoutDirective implements OnInit, OnDestroy {
  // properties can be set by => *blAppLayout="selector:mySelector"

  // the directive pulls the selector from the selector service by default
  // if we supply a selector, it will use the supplied value only

  // we only want to supply selectors when we want to render children nested
  // inside a *blAppLayout directive (like in case of side-nav dropdowns
  // we have nested *blAppLayout) otherwise the nested directives will show
  // same elements as the parents
  @Optional() @Input() set blAppLayoutSelector(selector: string[]) {
    this._layoutSelector = selector;
  }
  // if set, this property will ensure only the specified wrapper types are
  // visible, other are not rendered on webpage
  @Optional() @Input() set blAppLayoutOnlyDisplay(displayType: WrapperTypes) {
    this._onlyDisplay = displayType;
  }
  // the property must be set to true on parent if we want to render
  // children using nested *blAppLayout directive.
  @Optional() @Input() set blAppLayoutIsHeader(isHeader: boolean) {
    this._isHeader = isHeader;
  }
  // variables to hold subscriptions to clear on unmounting
  private _layoutSubscription: Subscription;
  private _childrenSubscription: Subscription;

  // children observable consisting of array of children uids
  // to be rendered via the directive
  private _children = new BehaviorSubject<string[]>([]);
  // array of methods to remove the listners added to elements
  private _childrenListeners: (() => void)[] = [];

  // the selector that specifies the location in app-layout
  // that this directive will show
  private _layoutSelector: string[];
  private _onlyDisplay: WrapperTypes;
  private _isHeader: boolean;

  constructor(
    private _configService: ConfigurationService,
    private _selectorService: SelectorService,
    private _viewContainer: ViewContainerRef,
    private _template: TemplateRef<AppLayoutDirectiveContext>,
    private _renderer: Renderer2
  ) {}

  ngOnInit() {
    // check if supplied a selector
    console.log(this._layoutSelector)
    if (this._layoutSelector) {
      // if selector exists, set children from this selector
      // this is the case of nested *blAppLayout directive
      // we do not need listener here as these children would
      // mount/ unmount based on top-level *blAppLayout directive
      // running this code everytime a change occurs
      this._children.next(
        this._configService.getChildren(this._layoutSelector)
      );
    } else {
      // if no selector exists, then we pull it from the selectorService
      // we subscribe to the observable to update the directive state when
      // we navigate from one level of nesting to another, as the top-level
      // *blAppLayout will remain mounted on changing the selector
      this._layoutSubscription = this._selectorService.layoutSelector$.subscribe(
        layoutSelector => {
          // update selector
          this._layoutSelector = layoutSelector;
          this._children.next(this._configService.getChildren(layoutSelector));
        }
      );
    }

    this._childrenSubscription = this._children.subscribe(newChildren => {
      // everytime the children to be rendered change:

      // remove all listeners from children mounted in past
      this._childrenListeners.forEach(removeListener => {
        removeListener();
      });
      // clear the childrenListeners array, this will be populated again below
      this._childrenListeners = [];
      // remove all children (as everytime the layout changes, all the wrappers change)
      this._viewContainer.clear();

      console.log(newChildren);

      newChildren.forEach((childId, index) => {
        // for each child uid, get that child's wrapper info
        const info = this._configService.getInfo(childId);

        // if onlyDisplay property does not exist, render all types of elements
        // if it exists, check if the wrapper is of type onlyDisplay
        // if the wrapper is of some other type, do not display it
        if (
          !this._onlyDisplay ||
          (this._onlyDisplay && info.type === this._onlyDisplay)
        ) {
          // create an element in the dom
          const el = this._viewContainer.createEmbeddedView(
            this._template,
            // populate the context using the info of the element
            // to be currently rendered
            new AppLayoutDirectiveContext(
              childId,
              index,
              info.label,
              // this._layoutSelector is selector of parent
              // add childId to the array to make selector for child
              // element
              [...this._layoutSelector, childId],
              info
            )
          ).rootNodes[0];

          // this function will be called when this element is clicked
          let callback: ($event?: any) => void = () => {};

          if (info.type === WrapperTypes.home) {
            // if the wrapper to be rendered is of type home navigation:

            if (info['child-type'] === WrapperTypes.home)
              // if the child type is also home, just update the layout selector
              callback = $event => {
                $event.stopPropagation();
                this._selectorService.changeLayout([
                  ...this._layoutSelector,
                  childId
                ]);
              };
            else if (info['child-type'] === WrapperTypes.side)
              // if child type is side nav, redirect to side nav + equipment render
              // and update the layout selector
              callback = $event => {
                $event.stopPropagation();
                this._selectorService.changeLayout([
                  ...this._layoutSelector,
                  childId
                ]);
                this._selectorService.shouldShowSideNavigation(true);
                const equipmentTemplate = info.equipmentTemplate
                  ? childId
                  : null;
                this._selectorService.changeEquipment(equipmentTemplate);
              };
            else if (info['child-type'] === WrapperTypes.equipment)
              // if child type is equipment, redirect to side nav + equipment render
              // and update the layout selector
              callback = $event => {
                $event.stopPropagation();
                this._selectorService.changeLayout([
                  ...this._layoutSelector,
                  // in this case, it will be an equipment wrapper here
                  // handle this case in side navigation, if equipment wrapper
                  // is provided, do not render the side navigation at all
                  childId
                ]);
                this._selectorService.shouldShowSideNavigation(true);
                const equipmentTemplate = info.equipmentTemplate
                  ? childId
                  : null;
                this._selectorService.changeEquipment(equipmentTemplate);
              };
          } else if (
            info.type === WrapperTypes.side &&
            (info as IEquipmentInfo).equipmentTemplate
          ) {
            callback = $event => {
              $event.stopPropagation();
              this._selectorService.equipmentSelector$
                .pipe(take(1))
                .subscribe(eq => {
                  if (eq === childId) {
                    const equipmentTemplate = this._configService.getInfo(
                      this._layoutSelector[this._layoutSelector.length - 1]
                    ).equipmentTemplate
                      ? this._layoutSelector[this._layoutSelector.length - 1]
                      : null;
                    this._selectorService.changeEquipment(equipmentTemplate);
                  } else this._selectorService.changeEquipment(childId);
                });
            };
          } else if (info.type === WrapperTypes.equipment) {
            // if wrapper to be rendered is of type equipment
            // change the equipment selected based on the wrapperid
            callback = $event => {
              $event.stopPropagation();
              this._selectorService.equipmentSelector$
                .pipe(take(1))
                .subscribe(eq => {
                  if (eq === childId) {
                    const equipmentTemplate = this._configService.getInfo(
                      this._layoutSelector[this._layoutSelector.length - 1]
                    ).equipmentTemplate
                      ? this._layoutSelector[this._layoutSelector.length - 1]
                      : null;
                    this._selectorService.changeEquipment(equipmentTemplate);
                  } else this._selectorService.changeEquipment(childId);
                });
            };
          }

          // if the directive is marked as a header, it means there can be nested
          // children, so do not setup the click listener, we will setup the listener
          // directly on the children themselves

          // but if the children.type !== type, then we must always allow
          // the header to be clicked as it will cause a redirect to side navigation/ equipment
          const isClickable = this._isHeader
            ? info['child-type'] !== info.type ||
              info.type === WrapperTypes.equipment
            : true;

          // if the wrapper is clickable attach listener
          if (info.clickable && isClickable) {
            // if the wrapper is clickable, set up a listener on the wrapper element
            // and store the function to remove the listener in an array

            this._childrenListeners.push(
              // callback is decided above based on type of wrapper
              // and type of children of this wrapper
              this._renderer.listen(el, 'click', callback)
            );
          }
        }
      });
    });
  }

  // clean subscriptions
  ngOnDestroy() {
    if (this._layoutSubscription) this._layoutSubscription.unsubscribe();
    if (this._childrenSubscription) this._childrenSubscription.unsubscribe();
    this._childrenListeners.forEach(removeListener => {
      removeListener();
    });
  }
}
