import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { take, exhaustMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // HERE we are actually returning the http observable due to exhaust map
    return this.authService.user$.pipe(
      take(1),
      exhaustMap(user => {
        // if no user exist, then no auth needs to be added
        if (!user) return next.handle(request);

        // this creates a chain where the earlier observable, the user is replaced by
        // the observable that return from this function body
        // hence we are returning actually next.handle(request), and not
        // userSubject observable when we return from THERE
        const headers = new HttpHeaders({
          authorization: user.token
        });

        const modifiedReq = request.clone({
          headers
        });
        return next.handle(modifiedReq);
      })
    );
  }
}
