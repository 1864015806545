<div class="device">
  <div
    class="status"
  >
    <fa-icon
      style="line-height: 0.8em"
      [icon]="clock"
    ></fa-icon>
    Schedule: {{ (reading$ | async)?.data?.toString() }} Hrs
  </div>
</div>
