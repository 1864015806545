import { Component, OnInit } from '@angular/core';
import { GraphicsGeneratorService } from '../graphics-generator.service';
import { map } from 'rxjs/operators';
import { IGraphicsTemplateIndexDB } from '@bertlabs-nova/types/nova-types';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bl-graphics-template-generator',
  templateUrl: './graphics-template-generator.component.html',
  styleUrls: ['./graphics-template-generator.component.css'],
  providers: [GraphicsGeneratorService]
})
export class GraphicsTemplateGeneratorComponent implements OnInit {
  graphicsTemplates$ = new BehaviorSubject<IGraphicsTemplateIndexDB[]>([]);

  constructor(public service: GraphicsGeneratorService) {}

  ngOnInit(): void {
    this.service.getLocalStoredData();
    this.service.graphicsTemplates$
      .pipe(
        map(templates => {
          const keys = Object.keys(templates);
          const templatesArray: IGraphicsTemplateIndexDB[] = [];
          keys.forEach(key => {
            templatesArray.push(templates[key]);
          });
          return templatesArray;
        })
      )
      .subscribe(templates => {
        this.graphicsTemplates$.next(templates);
      });
  }
}
