<!-- This is Graphical Layer -->
<div class="container">
  <div class="header">
    <span
      [class.activeLink]="isMonth"
      class="duration"
      >{{ label }}</span
    >
  </div>
  <hr />
  <div class="charts"  onclick="window.open('/historic-data','popup','width=1200,height=600,scrollbars=no,resizable=no')">
    <!-- <div class="chart-header">
      <span class="arrow"
        ><fa-icon [icon]="faChevronLeft"></fa-icon></span
      >
      <span class="chart-heading" *ngIf="isMonth">Sept-2020</span>
      <span class="arrow"
        ><fa-icon [icon]="faChevronRight"></fa-icon
      ></span>
    </div> -->
    <div class="monthChart" *ngIf="isMonth">
      <div style="display: block">
        <canvas
          baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
        ></canvas>
      </div>
    </div>
  </div>
</div>
