<div class="device">
  <h4>Device</h4>
  <div>Label</div>
  <input #deviceLabel type="text" [value]="deviceData.label" />
  <div>Endpoint Placeholder</div>
  <input
    #endpointPlaceholder
    type="text"
    [value]="deviceData.endpointPlaceholder"
  />
  <div>Parameter Name</div>
  <input #parameterName type="text" [value]="deviceData.parameterName" />
  <div>Display Type</div>
  <select #deviceDisplayType [value]="deviceData.displayType">
    <option *ngFor="let dp of vlt" [value]="dp">{{ dp }}</option>
  </select>
  <div>Reading Type</div>
  <input #readingType type="text" [value]="deviceData.readingType" />
  <div>Units</div>
  <input #units type="text" [value]="deviceData.units" />

  <div>Range (only if numeric)</div>
  <input
    #rangeLow
    type="number"
    [value]="deviceData?.range && (deviceData?.range)[0]"
  />
  <input
    #rangeHigh
    type="number"
    [value]="deviceData?.range && (deviceData?.range)[1]"
  />

  <button (click)="saveDeviceInfo()">Save Device Info</button>
  <button (click)="deleteDevice()">Delete Device</button>
</div>
