export const VisualLayerTypes = {
  unselected: 'unselected',
  flagRange: 'flagRange',
  textLabel: 'textLabel',
  labelCard: 'labelCard',
  textReading: 'textReading',
  graphicalTemperature: 'graphicalTemperature',
  graphicalPressure: 'graphicalPressure',
  graphicalValveControllable: 'graphicalValveControllable',
  toggle: 'toggle',
  // scheduler: 'scheduler',
  labelCardProgress: 'labelCardProgress',
  status: 'status',
  graphicalLabelCard: 'graphicalLabelCard',
  graphicalLabelCardToggle: 'graphicalLabelCardToggle',
  graphicalCo2Label: 'graphicalCo2Label',
  graphicalRange: 'graphicalRange',
  graphicalTimeCard: 'graphicalTimeCard',
  graphicalRealTime: 'graphicalRealTime',
  graphicalLabelCardProgress: 'graphicalLabelCardProgress',
  graphicalLayer : 'graphicalLayer',
  graphicalMultiValue : 'graphicalMultiValue',
  graphicalRunStatus : 'graphicalRunStatus',
  graphicalSmallRun : 'graphicalSmallRun',
  graphicalAhuOnOff : 'graphicalAhuOnOff',
  graphicalNonNegLabel: 'graphicalNonNegLabel',
  dataReceivedTime: 'dataReceivedTime'
};
