<div class="cont">
  <select #vl>
    <option *ngFor="let a of vlt" [value]="a">{{ a }}</option>
  </select>
  <button (click)="handleChange()">Update</button>
  <hr />
  <div style="margin-bottom: 10px"></div>
  <div class="mini">
    <bl-iot-model [deviceData]="deviceData | async"></bl-iot-model>
  </div>
  <div style="margin-bottom: 10px"></div>
  <div class="medium">
    <bl-iot-model [deviceData]="deviceData | async"></bl-iot-model>
  </div>
  <div style="margin-bottom: 10px"></div>
  <bl-iot-model [deviceData]="deviceData | async"></bl-iot-model>
</div>
