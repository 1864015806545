import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { ErrorPopupGeneratorComponent } from './error-popup-generator/error-popup-generator.component';

@NgModule({
  declarations: [ErrorPopupComponent, ErrorPopupGeneratorComponent],
  imports: [CommonModule],
  exports: [ErrorPopupGeneratorComponent]
})
export class ErrorHandlerModule {}
