import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-co2-label',
  templateUrl: './graphical-co2-label.component.html',
  styleUrls: ['./graphical-co2-label.component.css']
})
export class GraphicalCO2LabelComponent implements OnInit {
  @Input() reading$: Observable<{ data: any; found: boolean }>;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
