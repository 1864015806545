<div class="device">
  <div
    class="status"
    [ngStyle]="{
      color: (reading$ | async)?.data
        ? themeService.theme.green
        : themeService.theme.red
    }"
  >
    <fa-icon
      style="line-height: 1em"
      *ngIf="(reading$ | async)?.data"
      [icon]="check"
    ></fa-icon>
    <fa-icon
      style="line-height: 1em"
      *ngIf="!(reading$ | async).data"
      [icon]="cross"
    ></fa-icon>
  </div>
</div>
