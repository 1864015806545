<div class="modal">
  <div class="container">
    <h4>Edit IoT Device Parameters</h4>
    <div class="flexRow">
      <span>
        <div>Display Type</div>
        <select #deviceDisplayType [ngModel]="deviceData.displayType">
          <option *ngFor="let dp of vlt" [value]="dp">{{ dp }}</option>
        </select>
      </span>
    </div>

    <div *ngIf="deviceData.displayType === 'unselected'" class="selectMessage">
      Select Display Type and click save to edit IoT device parameters
    </div>

    <div *ngIf="deviceData.displayType !== 'unselected'">
      <div class="flexRow">
        <span>
          <div>Label</div>
          <input #deviceLabel type="text" [value]="deviceData.label" />
        </span>
        <span>
          <div>Font Size (em)</div>
          <input #fontSizeEm [value]="deviceData.fontSizeEm" />
        </span>
        <span>
          <div>Font Color</div>
          <input #fontColor [value]="deviceData.fontColor" />
        </span>
        <span>
          <div>Alignment</div>
          <select #alignment [value]="deviceData.alignment">
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </span>
      </div>
    </div>

    <div
      *ngIf="
        deviceData.displayType !== 'unselected' &&
        deviceData.displayType !== 'textLabel'
      "
    >
      <div class="flexRow">
        <span>
          <div>Endpoint Placeholder</div>
          <input
            #endpointPlaceholder
            type="text"
            [value]="deviceData.endpointPlaceholder"
          />
        </span>
        <span>
          <div>Parameter Name</div>
          <input
            #parameterName
            type="text"
            [value]="deviceData.parameterName"
          />
        </span>
      </div>

      <div class="flexRow">
        <span>
          <div>Reading Type</div>
          <select #readingType [value]="deviceData.readingType">
            <option value="numeric">Numeric</option>
            <option value="string">String</option>
          </select>
        </span>
        <span>
          <div>Units</div>
          <input #units type="text" [value]="deviceData.units" />
        </span>
        <span>
          <div>Range</div>
          <input
            #rangeLow
            type="number"
            [value]="deviceData?.range && (deviceData?.range)[0]"
          />
          <input
            #rangeHigh
            type="number"
            [value]="deviceData?.range && (deviceData?.range)[1]"
          />
        </span>
      </div>
    </div>

    <div
      *ngIf="deviceData.displayType === 'flagRange'"
      class="flexRow"
      [formGroup]="formGroup"
    >
      <span>
        <div>
          Flag Ranges: (To display a string when reading is between rangeLow and
          rangeHigh, inclusive)
        </div>
        <div formArrayName="flagRanges">
          <div
            *ngFor="let control of flagRangeControls; let i = index"
            [formGroupName]="i"
          >
            <input formControlName="rangeLow" type="number" />
            <input formControlName="rangeHigh" type="number" />
            <input formControlName="flag" />
            <button (click)="removeFlagRange(i)">Delete Range</button>
          </div>
        </div>
        <div>
          <button (click)="addFlagRange()">Add Range</button>
        </div>
      </span>
    </div>

    <hr />

    <div>
      Sample Display:
    </div>
    <div>
      <bl-iot-model [isStatic]="true" [deviceData]="deviceData"></bl-iot-model>
    </div>

    <div class="flexRow" style="justify-content: left; margin-top: 10px">
      <button
        style="background: dodgerblue; color: white;margin-right: 8px;"
        (click)="saveDeviceInfo()"
      >
        Save Device Info
      </button>
      <button
        style="background: salmon; color: white"
        (click)="deleteDeviceInfo()"
      >
        Delete Device
      </button>
    </div>
  </div>
</div>
<div (click)="closeModal()" class="backdrop"></div>
