<div class="modal">
  <div class="infocontainer">
    <div class="flexRow">
      <span>
        <div>Label</div>
        <input #label [value]="componentData.label" />
      </span>
      <span>
        <div>Width</div>
        <input #width type="number" [value]="componentData.width" />
      </span>
      <span>
        <div>Height</div>
        <input #height type="number" [value]="componentData.height" />
      </span>
      <span>
        <div>Flexbox Justify Content</div>
        <select #justifyContent [value]="componentData.justifyContent">
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="flex-end">Right</option>
          <option value="space-berween">Space Between</option>
          <option value="space-around">Space Around</option>
        </select>
      </span>
    </div>
  </div>

  <div class="container">
    <div class="flexRow" style="width: 90%; margin-bottom: 5px">
      <button
        [ngClass]="{ pressed: componentGeneratorService.mode === modes.addRow }"
        (click)="componentGeneratorService.switchMode(modes.addRow)"
      >
        Add Row Mode
      </button>
      <button
        [ngClass]="{
          pressed: componentGeneratorService.mode === modes.addColumn
        }"
        (click)="componentGeneratorService.switchMode(modes.addColumn)"
      >
        Add Column Mode
      </button>
      <button
        [ngClass]="{
          pressed: componentGeneratorService.mode === modes.addDevice
        }"
        (click)="componentGeneratorService.switchMode(modes.addDevice)"
      >
        Add/ Edit IoT Device Mode
      </button>

      <button
        [ngClass]="{ pressed: componentGeneratorService.mode === modes.delete }"
        (click)="componentGeneratorService.switchMode(modes.delete)"
      >
        Delete Mode
      </button>
    </div>
    <as-split #splitComponent direction="vertical" class="structure">
      <as-split-area
        [size]="row.height"
        *ngFor="let row of componentData.structure.data; let i = index"
      >
        <bl-component-row
          [rowIndex]="i"
          [parentObject]="componentData.structure"
          [componentrowData]="row"
        ></bl-component-row>
      </as-split-area>
    </as-split>
    <div (click)="addRowToStructure()" class="addRowToStructure">
      + Add row to structure
    </div>
  </div>

  <div class="infocontainer">
    <div class="flexRow" style="justify-content: right">
      <button
        style="background: dodgerblue; color: white;margin-right: 8px;"
        (click)="updateComponentInfo()"
      >
        Save
      </button>
      <button
        style="background: salmon; color: white"
        (click)="deleteComponent()"
      >
        Delete Component
      </button>
    </div>
  </div>
</div>
<bl-iot-device-modal
  *ngIf="componentGeneratorService.deviceModalIsOpen | async"
  [deviceData]="(componentGeneratorService.$deviceModal | async).data"
  [saveDevice]="componentGeneratorService.saveIoTDevice"
  [deleteDevice]="componentGeneratorService.deleteIoTDevice"
  [closeModal]="componentGeneratorService.closeDeviceModal"
>
</bl-iot-device-modal>
<div (click)="closeComponentModal()" class="backdrop"></div>
