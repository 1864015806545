import { Component, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../service/app.service";

@Component({
    selector: 'bl-LLabs-ahu-component',
    templateUrl: './ahu-screen-169.template.html',
    styleUrls: ['./ahu-screen-108.component.css']
})

export class AHU169ClassComponent {
    @ViewChild('rat') rat: ElementRef;
    @ViewChild('rrh') rrh: ElementRef;
    @ViewChild('chw_valve') chw_valve: ElementRef;
    @ViewChild('hw_valve') hw_valve: ElementRef;
    @ViewChild('supply_air') supply_air: ElementRef;
    @ViewChild('chw_inlet_temp') chw_inlet_temp: ElementRef;
    // rat: any;
    // rrh: any; 
    // chw_valve: any;
    // hw_valve: any;
    // supply_air: any;
    // chw_inlet_temp: any;

    rh: any;
    hw: any;
    cw: any;
    cvc: any;
    hvc: any;
    ahu_heat_load: any;
    supplyAirFr: any;
    coolingWaterTemp: any;



    // output values binding

    outCMH: any;
    outPercent: any;
    outletRH: any;
    outletPercent2: any;
    outletPercent3: any;

    rrh_predict: any;
    rat_predict: any;
    hw_valve_predict: any;
    chw_valve_predict: any;
    supply_air_predict: any;

    rrh_optimise: any;
    rat_optimise: any;
    hw_valve_optimise: any;
    chw_valve_optimise: any;

    chiller_TR_value: any;
    chiller_kW_value: any;
    chiller_Pump_value: any;
    hot_Water_Pump_value: any;
    coalConsumption_value: any;

    invalidSupplyAir: boolean = true;
    min_val: number = 9000;
    max_val: number = 10000;

    min_val_rat: number = 20;
    max_val_rat: number = 24;
    min_val_rrh: number = 45;
    max_val_rrh: number = 53;
    min_val_chwValve: number = 0;
    max_val_chwValve: number = 100;
    min_hw_val: number = 0;
    max_hw_val: number = 100;
    min_chw_inlet: number = 5;
    max_chw_inlet: number = 7;

    invalidRat: boolean = true;
    invalidRrh: boolean = true;
    invalidChwValve: boolean = true;
    invalidHwValve: boolean = true;
    invalidChillWaterInlet: boolean = true;

    constructor(
        private appService: AppService,
        private router: Router
    ) { }



    ngOnInit() {
        // this.cvc = 99.99987030029297;
        // this.hvc = 99.9998779296875;
        // this.rat = 23.00002670288086;
        // this.rh = 45.999847412109375;
        this.invalidSupplyAir = true;
    }

    ngOnChange() {
        console.log('when component updates ***');
    }

    navigateToHome() {
        this.router.navigate(['app/ahu-home']);
    }

    navigateBack() {
        this.router.navigate(['app/ahu-granulation-and-blending']);
    }

    validateSupplyAir(value: any) {
        if (value.target.value < this.min_val || value.target.value > this.max_val) {
            this.invalidSupplyAir = true;
            document.getElementById('error').style.display = 'block';
            console.log(document.getElementById('error').style.display);
            return this.invalidSupplyAir;
        }
        document.getElementById('error').style.display = 'none';
        // document.getElementById('error').hidden = false;
        this.invalidSupplyAir = false;
        return this.invalidSupplyAir;
    }

    validateRAT(value: any) {
        if (value.target.value <  this.min_val_rat || value.target.value > this.max_val_rat) {
            this.invalidRat = true;
            document.getElementById('error1').style.display = 'block';
            console.log(document.getElementById('error1').style.display);
            return this.invalidRat;
        }
        document.getElementById('error1').style.display = 'none';
        // document.getElementById('error').hidden = false;
        this.invalidRat = false;
        return this.invalidRat;
    }
    validateRRH(value: any) {
        if (value.target.value <  this.min_val_rrh || value.target.value > this.max_val_rrh) {
            this.invalidRrh = true;
            document.getElementById('error2').style.display = 'block';
            console.log(document.getElementById('error2').style.display);
            return this.invalidRrh;
        }
        document.getElementById('error2').style.display = 'none';
        // document.getElementById('error').hidden = false;
        this.invalidRrh = false;
        return this.invalidRrh;
    }
    validateChwValve(value: any) {
        if (value.target.value <  this.min_val_chwValve || value.target.value > this.max_val_chwValve) {
            this.invalidChwValve = true;
            document.getElementById('error3').style.display = 'block';
            console.log(document.getElementById('error3').style.display);
            return this.invalidChwValve;
        }
        document.getElementById('error3').style.display = 'none';
        // document.getElementById('error').hidden = false;
        this.invalidChwValve = false;
        return this.invalidChwValve;
    }
    validateHwValve(value: any) {
        if (value.target.value <  this.min_hw_val || value.target.value > this.max_hw_val) {
            this.invalidHwValve = true;
            document.getElementById('error4').style.display = 'block';
            console.log(document.getElementById('error4').style.display);
            return this.invalidHwValve;
        }
        document.getElementById('error4').style.display = 'none';
        // document.getElementById('error').hidden = false;
        this.invalidHwValve = false;
        return this.invalidHwValve;
    }
    validateChillWaterInlet(value: any) {
        if (value.target.value <  this.min_chw_inlet || value.target.value > this.max_chw_inlet) {
            this.invalidChillWaterInlet = true;
            document.getElementById('error5').style.display = 'block';
            console.log(document.getElementById('error5').style.display);
            return this.invalidChillWaterInlet;
        }
        document.getElementById('error5').style.display = 'none';
        // document.getElementById('error').hidden = false;
        this.invalidChillWaterInlet = false;
        return this.invalidChillWaterInlet;
    }

    fetchValues() {
        console.log("in");

        if (!(this.invalidSupplyAir || this.invalidRat || this.invalidChwValve || this.invalidHwValve || this.invalidRrh || this.invalidChillWaterInlet)) {
            this.clearOutputValues();

            let requestBody: any = {
                "RAT": +this.rat.nativeElement.value,
                "RRH": +this.rrh.nativeElement.value,
                "Cooling Valve Control": +this.chw_valve.nativeElement.value,
                "Heating Valve Control": +this.hw_valve.nativeElement.value,
                "Supply Air FR": +this.supply_air.nativeElement.value,
                "Cooling Water Inlet Temp": +this.chw_inlet_temp.nativeElement.value,
                "ahu_no.": 169
            };

            this.appService.getAHUValues(requestBody).subscribe(data => {
                console.log(data);
                this.rrh_predict = data.RRH.toFixed(1);
                this.rat_predict = data.RAT.toFixed(1);
                this.hw_valve_predict = this.hw_valve.nativeElement.value;
                this.chw_valve_predict = this.chw_valve.nativeElement.value;
                this.supply_air_predict = this.supply_air.nativeElement.value;
            });
        }

    }

    fetchOptimisedValues() {

        if (!(this.invalidSupplyAir || this.invalidRat || this.invalidChwValve || this.invalidHwValve || this.invalidRrh || this.invalidChillWaterInlet)) {

            let requestBody: any = {
                // "RAT": +this.rat.nativeElement.value,
                // "RRH": +this.rrh.nativeElement.value,
                // "Cooling Valve Control": +this.chw_valve.nativeElement.value,
                // "Heating Valve Control": +this.hw_valve.nativeElement.value,
                "Supply Air FR": +this.supply_air.nativeElement.value,
                // "Cooling Water Inlet Temp": +this.chw_inlet_temp.nativeElement.value,
                "ahu_no.": 169
            };

            this.appService.getOptimisedValues(requestBody).subscribe(data => {
                console.log(data);
                this.rrh_optimise = data.RRH.toFixed(1);
                this.rat_optimise = data.RAT.toFixed(1);
                this.hw_valve_optimise = data['Heating Valve Control'].toFixed(1);
                this.chw_valve_optimise = data['Cooling Valve Control'].toFixed(1);
                this.kpiFormCalculations(this.chw_valve_optimise, this.hw_valve_optimise)
                this.supply_air_predict = this.supply_air.nativeElement.value;
            });
        }

    }

    clearOutputValues() {
        this.rrh_optimise = "";
        this.rat_optimise = "";
        this.hw_valve_optimise = "";
        this.chw_valve_optimise = "";
        this.supply_air_predict = "";
    }

    kpiFormCalculations(_chwValve, _hwValve) {
        this.chiller_TR_value =
          ((65.06598 * (100 - this.chw_valve_optimise)) / 2110.11).toFixed(3);
        this.chiller_kW_value =
        ((65.06598 * (100 - this.chw_valve_optimise)) / 2110.11).toFixed(3);
        this.chiller_Pump_value =
          ((1 - Math.pow(((167.98 - 5.18 * (1 - this.chw_valve_optimise/100)) / 167.98) , 3)) *
          100).toFixed(3);
        this.hot_Water_Pump_value =
          ((1 - Math.pow(((33.0106 - 0.73 * (1 - this.hw_valve_optimise/100)) / 33.0106) , 3)) *
          100).toFixed(3);
        this.coalConsumption_value =
          ((244.4671 * (100 - this.hw_valve_optimise)) / 11054.8031).toFixed(3);
    }

    logout() {
        localStorage.removeItem('user');
        location.reload();
    }
}