import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IotModelComponent } from './iot-model/iot-model.component';
import { ChartsModule } from 'ng2-charts';
import { LabelCardComponent } from './iot-model/visual-layer/label-card/label-card.component';
import { GraphicalTemperatureComponent } from './iot-model/visual-layer/graphical-temperature/graphical-temperature.component';
import { GraphicalPressureComponent } from './iot-model/visual-layer/graphical-pressure/graphical-pressure.component';
import { GraphicalValveControllableComponent } from './iot-model/visual-layer/graphical-valve-controllable/graphical-valve-controllable.component';
import { ToggleComponent } from './iot-model/visual-layer/toggle/toggle.component';
// import { SchedulerComponent } from './iot-model/visual-layer/scheduler/scheduler.component';
import { LabelCardProgressComponent } from './iot-model/visual-layer/label-card-progress/label-card-progress.component';
import { StatusComponent } from './iot-model/visual-layer/status/status.component';
import { GraphicalLabelCardComponent } from './iot-model/visual-layer/graphical-label-card/graphical-label-card.component';
import { GraphicalLabelCardProgressComponent } from './iot-model/visual-layer/graphical-label-card-progress/graphical-label-card-progress.component';
import { GraphicalLabelCardToggleComponent } from './iot-model/visual-layer/graphical-label-card-toggle/graphical-label-card-toggle.component';
// import { GraphicalStaticLabelComponent } from './iot-model/visual-layer/graphical-static-label/graphical-static-label.component';
import { GraphicalCO2LabelComponent } from './iot-model/visual-layer/graphical-co2-label/graphical-co2-label.component';
import { GraphicalRangeComponent } from './iot-model/visual-layer/graphical-range/graphical-range.component';
import { GraphicalTimeCardComponent } from './iot-model/visual-layer/graphical-time-card/graphical-time-card.component';
import { GraphicalLayerComponent } from './iot-model/visual-layer/graphical-layer/graphical-layer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GraphicalMultiValueComponent } from './iot-model/visual-layer/graphical-multi-value/graphical-multi-value.component';
import { GraphicalRealtimeComponent } from './iot-model/visual-layer/graphical-realtime/graphical-realtime.component';
import { GraphicalRunStatusComponent } from './iot-model/visual-layer/graphical-run-status/graphical-run-status.component';
import { GraphicalSmallRunComponent } from './iot-model/visual-layer/graphical-small-run/graphical-small-run.component';
import { GraphicalAhuOnOffComponent } from './iot-model/visual-layer/graphical-ahu-on-off/graphical-ahu-on-off.component';
import { GraphicalNonNegLabelComponent } from './iot-model/visual-layer/graphical-non-neg-label/graphical-non-neg-label.component';
import { DataReceivedTimeComponent } from './iot-model/visual-layer/data-received-time/data-received-time.component';

@NgModule({
  imports: [CommonModule, ChartsModule,FontAwesomeModule],
  declarations: [
    IotModelComponent,
    //ChartsModule,
    LabelCardComponent,
    GraphicalTemperatureComponent,
    GraphicalPressureComponent,
    GraphicalValveControllableComponent,
    ToggleComponent,
    // SchedulerComponent,
    LabelCardProgressComponent,
    StatusComponent,
    GraphicalLabelCardComponent,
    GraphicalLabelCardToggleComponent,
    GraphicalTimeCardComponent,
    // GraphicalStaticLabelComponent,
    GraphicalCO2LabelComponent,
    GraphicalLabelCardProgressComponent,
    GraphicalLayerComponent,
    GraphicalRangeComponent,
    GraphicalMultiValueComponent,
    GraphicalRealtimeComponent,
    GraphicalRunStatusComponent,
    GraphicalSmallRunComponent,
    GraphicalAhuOnOffComponent,
    GraphicalNonNegLabelComponent,
    DataReceivedTimeComponent
  ],
  exports: [IotModelComponent,ChartsModule]
})
export class IotDeviceModelIotModelModule {}
