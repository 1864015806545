<div
    class="device"
    [ngStyle]="{ 'border-bottom': '3px solid ' + themeService.theme.green }"
    >
    <span [ngStyle]="{ color: themeService.theme.grey }" class="label"
      >{{ label }} {{ ' ' }}</span
    >
    <span [ngStyle]="{ color: themeService.theme.green }" class="reading">{{
      progress
    }}</span>
    <span [ngStyle]="{ color: themeService.theme.grey }" class="units">{{
      units
    }}</span>
  </div>
  