import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  Renderer2,
  HostListener,
  ElementRef
} from '@angular/core';
import { ConfigurationService } from '@bertlabs-nova/dynamic-app-shell/configuration-service';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';
import { Subscription } from 'rxjs';
import { WrapperTypes, IEquipmentInfo } from '@bertlabs-nova/types/nova-types';
import { take } from 'rxjs/operators';

///////////////////////////
// USAGE: /////////////////
///////////////////////////

@Directive({
  selector: '[blNavigation]'
})
export class NavigationDirective implements OnInit, OnDestroy {
  @Input('blNavigation') navigateTo: string;

  private _callback: () => void;

  private _selectorSub: Subscription;
  private _layoutSub: Subscription;

  @HostListener('click')
  onClick() {
    console.log("Navgating to...",this.navigateTo)
    // $event.stopPropagation();
    if (this._callback) this._callback();
  }

  constructor(
    private _configService: ConfigurationService,
    private _selectorService: SelectorService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngOnInit() {
    if (this.navigateTo) {
      console.log("Navigating properly.....");
      
      const info = this._configService.getInfo(this.navigateTo);

      if (!info) return;

      const selector: string[] = [];

      let curr = this.navigateTo;
      while (curr) {
        const info = this._configService.getInfo(curr);

        if (info.type === WrapperTypes.home) selector.push(curr);

        if (info && info.parent) {
          curr = info.parent[0];
        } else curr = null;
      }
      selector.reverse();

      if (info.type === WrapperTypes.home) {
        // if the wrapper to be rendered is of type home navigation:

        if (info['child-type'] === WrapperTypes.home)
          // if the child type is also home, just update the layout selector
          this._callback = () => {
            this._selectorService.changeLayout(selector);
            this._selectorService.changeEquipment(null);
            this._selectorService.shouldShowSideNavigation(false);
          };
        else if (
          info['child-type'] === WrapperTypes.side ||
          info['child-type'] === WrapperTypes.equipment
        )
          // if child type is side nav, redirect to side nav + equipment render
          // and update the layout selector
          this._callback = () => {
            this._selectorService.changeLayout(selector);
            const equipmentTemplate = info.equipmentTemplate
              ? this.navigateTo
              : null;
            this._selectorService.changeEquipment(equipmentTemplate);
            this._selectorService.shouldShowSideNavigation(true);
          };
      } else if (
        info.type === WrapperTypes.side ||
        info.type === WrapperTypes.equipment
      ) {
        this._callback = () => {
          this._selectorService.changeLayout(selector);
          if ((info as IEquipmentInfo).equipmentTemplate) {
            this._selectorService.changeEquipment(this.navigateTo);
          }
          this._selectorService.shouldShowSideNavigation(true);
        };
      }

      this._selectorSub = this._selectorService.equipmentSelector$.subscribe(
        id => {
          if (id && id === this.navigateTo) {
            this._renderer.addClass(this._elementRef, 'clickableActive');
          } else {
            this._renderer.removeClass(this._elementRef, 'clickableActive');
          }
        }
      );

      this._layoutSub = this._selectorService.layoutSelector$.subscribe(
        selector => {
          if (
            selector &&
            selector.length > 0 &&
            selector[selector.length - 1] === this.navigateTo
          ) {
            this._renderer.addClass(this._elementRef, 'clickableActive');
          } else {
            this._renderer.removeClass(this._elementRef, 'clickableActive');
          }
        }
      );
    }
  }

  ngOnDestroy() {
    this._layoutSub && this._layoutSub.unsubscribe();
    this._selectorSub && this._selectorSub.unsubscribe();
  }
}
