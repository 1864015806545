<ng-container *ngIf="deviceData.displayType === vlt.textReading">
  <span
    [ngStyle]="{
      'font-size': deviceData.fontSizeEm + 'em',
      color: deviceData.fontColor,
      width: '100%',
      'text-align': deviceData.alignment
    }"
  >
    {{ (reading$ | async)?.data }}
    <span style="font-size: 0.8em">
      {{ deviceData.units }}
    </span>
  </span>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.textLabel">
  <span
    [ngStyle]="{
      'font-size': deviceData.fontSizeEm + 'em',
      color: deviceData.fontColor,
      width: '100%',
      'text-align': deviceData.alignment
    }"
  >
    {{ deviceData.label }}
  </span>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.labelCard">
  <bl-label-card
    [label]="deviceData.label"
    [reading$]="reading$"
    [units]="deviceData.units"
  ></bl-label-card>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalTemperature">
  <bl-graphical-temperature [reading$]="reading$"></bl-graphical-temperature>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalPressure">
  <bl-graphical-pressure [reading$]="reading$"></bl-graphical-pressure>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalValveControllable">
  <bl-graphical-valve-controllable
    [id]="deviceData.endpointPlaceholder + deviceData.parameterName"
    [reading$]="reading$"
  ></bl-graphical-valve-controllable>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.toggle">
  <bl-toggle
    [reading$]="reading$"
    [color]="'green'"
    [id]="deviceData.endpointPlaceholder + deviceData.parameterName"
  ></bl-toggle>
</ng-container>

<!-- <ng-container *ngIf="deviceData.displayType === vlt.scheduler">
  <bl-scheduler></bl-scheduler>
</ng-container> -->

<ng-container *ngIf="deviceData.displayType === vlt.labelCardProgress">
  <bl-label-card-progress
    [label]="deviceData.label"
    [reading$]="reading$"
    [units]="'%'"
    [range]="deviceData.range"
  ></bl-label-card-progress>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.status">
  <bl-status [reading$]="reading$"></bl-status>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.dataReceivedTime">
  <!-- <bl-status [reading$]="reading$"></bl-status> -->
  <bl-data-received-time [reading$]="reading$"></bl-data-received-time>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalLabelCard">
  <bl-graphical-label-card
    [units]="deviceData.units"
    [reading$]="reading$"
    [label]="deviceData.label"
  ></bl-graphical-label-card>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalNonNegLabel">
  <bl-graphical-non-neg-label
    [units]="deviceData.units"
    [reading$]="reading$"
    [label]="deviceData.label"
  ></bl-graphical-non-neg-label>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalLabelCardToggle">
  <bl-graphical-label-card-toggle
    [id]="deviceData.endpointPlaceholder + deviceData.parameterName"
    [units]="deviceData.units"
    [reading$]="reading$"
    [label]="deviceData.label"
  ></bl-graphical-label-card-toggle>
</ng-container>

<!-- <ng-container *ngIf="deviceData.displayType === vlt.graphicalStaticLabel">
  <bl-graphical-static-label></bl-graphical-static-label>
</ng-container> -->

<ng-container *ngIf="deviceData.displayType === vlt.graphicalCo2Label">
  <bl-graphical-co2-label [reading$]="reading$"></bl-graphical-co2-label>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalRange">
  <bl-graphical-range
  [reading$]="reading$"
  [label]="deviceData.label" 
  [range]="deviceData.range"
  ></bl-graphical-range>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalTimeCard">
  <bl-graphical-time-card
  [reading$]="reading$"
  ></bl-graphical-time-card>
  <!-- <bl-graphical-time-card [time]="[1604560064354,1604670064354]"></bl-graphical-time-card> -->
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalRealTime">
  <bl-graphical-realtime></bl-graphical-realtime>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalLabelCardProgress">
  <bl-graphical-label-card-progress
    [label]="'Energy Label'" 
    [range]="[60,100]"
    [units]="'%'"
  ></bl-graphical-label-card-progress>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalLayer">
  <bl-graphical-layer
    [label]="deviceData.label"
    [placeholder]="deviceData.endpointPlaceholder"
    [range]="[100,90.6,110,101,95,101]"
  ></bl-graphical-layer>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalMultiValue">
  <!-- [reading$]="reading$"  -->
  <bl-graphical-multi-value
    [label]="deviceData.label"
    [placeholder]="deviceData.endpointPlaceholder"
    [parameterName]="deviceData.parameterName"
  ></bl-graphical-multi-value>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalRunStatus">
  <bl-graphical-run-status
  [reading$]="reading$"
  ></bl-graphical-run-status>
</ng-container>

<ng-container *ngIf="deviceData.displayType === vlt.graphicalSmallRun">
  <bl-graphical-small-run
  [reading$]="reading$"
  ></bl-graphical-small-run>
</ng-container>


<ng-container *ngIf="deviceData.displayType === vlt.graphicalAhuOnOff">
  <bl-graphical-ahu-on-off
  [reading$]="reading$"
  ></bl-graphical-ahu-on-off>
</ng-container>
