import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentRendererComponent } from './equipment-renderer/equipment-renderer.component';
import { EquipmentRowComponent } from './equipment-row/equipment-row.component';
import { EquipmentGraphicsRowComponent } from './equipment-graphics-row/equipment-graphics-row.component';
import { DynamicNodeMappingDynamicNodeViewerModule } from '@bertlabs-nova/dynamic-node-mapping/dynamic-node-viewer';
import { IotDeviceModelIotModelModule } from '@bertlabs-nova/iot-device-model/iot-model';
import { ComponentRendererComponent } from './component-renderer/component-renderer.component';
import { ComponentRendererRowComponent } from './component-renderer/component-renderer-row/component-renderer-row.component';
import { ComponentRendererCellComponent } from './component-renderer/component-renderer-cell/component-renderer-cell.component';

@NgModule({
  imports: [
    CommonModule,
    DynamicNodeMappingDynamicNodeViewerModule,
    IotDeviceModelIotModelModule
  ],
  declarations: [
    EquipmentRendererComponent,
    EquipmentRowComponent,
    EquipmentGraphicsRowComponent,
    ComponentRendererComponent,
    ComponentRendererRowComponent,
    ComponentRendererCellComponent
  ],
  exports: [EquipmentRendererComponent]
})
export class EquipmentRendererModule {}
