<div class="ahu-component">
  <div class="top-bar">
    <a class="active" style="position: fixed" href="/home">
      <!-- <img src="../assets/laurus-labs/Image 1.png" /> | -->
      <img
        src="../assets/laurus-labs/Bert Labs.png"
        style="margin-top: 3px;"
        height="50px"
        width="223px"
      />
    </a>
    <h1 style="position: fixed;left: 41%;opacity: 1;color: #3D2C5A;">
      AHU 118
    </h1>
    <a style="position: fixed; right: 5%;" (click)="logout()">
      <img src="../assets/laurus-labs/sign-out.png" style="margin-top: 17px;" />
    </a>
  </div>
  <div style="margin-top: 5%;">
    <img
      src="../assets/laurus-labs/ahu-118.png"
      style="margin-top: 3px; margin-left:7%;"
      height="90%"
      width="90%"
    />
  </div>
  <div
    class="container ahu-108-savings"
    style="position: relative; top: -79%; margin-left: 52%;"
  >
    <img src="../assets/laurus-labs/ahu-savings-form.png" />
    <div style="position: absolute; top: 39%; left: 4.5%;">
      {{chiller_TR_value}}
    </div>
    <div style="position: absolute; top: 39%; left: 21%;">
      {{chiller_kW_value}}
    </div>
    <div style="position: absolute; top: 39%; left: 35%;">
      {{chiller_Pump_value}}
    </div>
    <div style="position: absolute; top: 69%; left: 4.5%;">
      {{hot_Water_Pump_value}}
    </div>
    <div style="position: absolute; top: 69%; left: 21%;">
      {{coalConsumption_value}}
    </div>
  </div>

  <div class="container" style="position: relative; top: -82%; height: 15px;">
    <div style="position: absolute; bottom: -396%; left: 13.2%; ">
      {{ rrh_optimise ? rrh_optimise : rrh_predict }}
    </div>
    <div style="position: absolute; bottom: -674%; left: 13.2%;">
      {{ rat_optimise ? rat_optimise : rat_predict }}
    </div>
    <div style="position: absolute; bottom: -737%; left: 72%;">
      {{ supply_air_predict }}
    </div>
    <div style="position: absolute; bottom: -2448%; left: 39.2%;">
      {{ chw_valve_optimise ? chw_valve_optimise : chw_valve_predict }}
    </div>
    <div style="position: absolute; bottom: -2448%; left: 58.2%;">
      {{ hw_valve_optimise ? hw_valve_optimise : hw_valve_predict }}
    </div>
  </div>

  <div class="container ahu-108-new" style="position: relative; top: -33.5%">
    <img src="../assets/laurus-labs/ahu-new-input-form.png" />
    <form>
      <div class="input-value-form">
        <div class="btn">
          <input
            type="text"
            class="form-control no-outline"
            name="rat"
            #rat
            (change)="validateRAT($event)"
          />
          <input
            type="text"
            class="form-control no-outline"
            name="rrh"
            #rrh
            (change)="validateRRH($event)"
          />
          <!-- <input
              type="text"
              class="form-control no-outline"
              placeholder="AHU heat load"
              name="ahu_heatLoad"
            /> -->
          <input
            type="text"
            class="form-control no-outline"
            name="cool_wv"
            #chw_valve
            (change)="validateChwValve($event)"
          />
          <input
            type="text"
            class="form-control no-outline"
            name="heat_valve_control"
            #hw_valve
            (change)="validateHwValve($event)"
          />
          <input
            type="text"
            class="form-control no-outline"
            name="supply_air_FR"
            (change)="validateSupplyAir($event)"
            #supply_air
          />
          <input
            type="text"
            class="form-control no-outline"
            name="cool_w_inlet"
            #chw_inlet_temp
            (change)="validateChillWaterInlet($event)"
          />
        </div>
      </div>
    </form>

    <div class="button-position">
      <button class="btn-submit" type="submit" (click)="fetchValues()">
        Predict
      </button>
      <br />
      <button class="btn-opt" type="submit" (click)="fetchOptimisedValues()">
        Optimise
      </button>
    </div>
    <div
      id="error"
      style="font-size: 9px; color: #ff4040; position: absolute; bottom: 23.5%; left: 36%;"
    >
      *Enter between {{ min_val }} to {{ max_val }}
    </div>
    <div
      id="error1"
      style="font-size: 9px; color: #ff4040; position: absolute; bottom: 23.5%; left: 1%;"
    >
      *Enter between {{ min_val_rat }} to {{ max_val_rat }}
    </div>
    <div
      id="error2"
      style="font-size: 9px; color: #ff4040; position: absolute; bottom: 23.5%; left: 9%;"
    >
      *Enter between {{ min_val_rrh }} to {{ max_val_rrh }}
    </div>
    <div
      id="error3"
      style="font-size: 9px; color: #ff4040; position: absolute; bottom: 23.5%; left: 18%;"
    >
      *Enter between {{ min_val_chwValve }} to {{ max_val_chwValve }}
    </div>
    <div
      id="error4"
      style="font-size: 9px; color: #ff4040; position: absolute; bottom: 23.5%; left: 28%;"
    >
      *Enter between {{ min_hw_val }} to {{ max_hw_val }}
    </div>
    <div
      id="error5"
      style="font-size: 9px; color: #ff4040; position: absolute; bottom: 23.5%; left: 46%;"
    >
      *Enter between {{ min_chw_inlet }} to {{ max_chw_inlet }}
    </div>

    <div class="side-nav-bar">
      <div>
        <a (click)="navigateToHome()"
          ><img src="../assets/laurus-labs/home-icon.png" class="style-home" />
        </a>
      </div>
      <a (click)="navigateBack()"
        ><img src="../assets/laurus-labs/back-button.png" class="style-home" />
      </a>
      <img
        src="../assets/laurus-labs/set-1.png"
        class="style-home"
        style="margin-top:1087%;"
      />
      <img src="../assets/laurus-labs/settings-icon.png" class="style-home" />
      <!-- <img src="../assets/laurus-labs/info-icon.png" /> -->
    </div>
  </div>
</div>
