import { Component, OnInit } from '@angular/core';
import { SelectorService } from '@bertlabs-nova/dynamic-app-shell/selector-service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bl-hvac-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {
  con() {
    this.selectorService.layoutSelector$
      .pipe(take(1))
      .subscribe(a => console.log(a));
  }

  constructor(public selectorService: SelectorService) {}

  ngOnInit(): void {}
}
