<div class="row" *ngIf="cellData.isNested">
  <bl-component-renderer-row
    [ngStyle]="{ width: row.height }"
    [componentRowData]="row"
    *ngFor="let row of cellData.data"
  ></bl-component-renderer-row>
</div>
<div class="device" *ngIf="!cellData.isNested">
  <bl-iot-model
    *ngIf="cellData.data"
    [deviceData]="cellData.data"
  ></bl-iot-model>
</div>
