import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLayoutGeneratorComponent } from './app-layout-generator/app-layout-generator.component';
import { LayoutBlockComponent } from './layout-block/layout-block.component';
import { AddEditModalComponent } from './add-edit-modal/add-edit-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    AppLayoutGeneratorComponent,
    LayoutBlockComponent,
    AddEditModalComponent
  ],
  exports: [AppLayoutGeneratorComponent]
})
export class AppLayoutGeneratorModule {}
