import { Component, OnInit, ViewChild } from '@angular/core';
import { IEquipmentTemplateRowDevice } from '@bertlabs-nova/types/nova-types';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { VisualLayerTypes } from '@bertlabs-nova/iot-device-model/iot-model';

@Component({
  selector: 'bl-hvac-visual-layer-viewer',
  templateUrl: './visual-layer-viewer.component.html',
  styleUrls: ['./visual-layer-viewer.component.css']
})
export class VisualLayerViewerComponent implements OnInit {
  @ViewChild('vl') vl;

  vlt = Object.keys(VisualLayerTypes);

  deviceData = new BehaviorSubject<IEquipmentTemplateRowDevice>({
    displayType: 'text',
    endpointPlaceholder: 'test',
    label: 'Test',
    parameterName: 'testParam',
    range: [0, 100],
    readingType: 'numeric',
    units: '',
    alignment: 'left',
    fontColor: 'black',
    fontSizeEm: 1
  });

  handleChange() {
    this.deviceData.pipe(take(1)).subscribe(data => {
      this.deviceData.next({
        ...data,
        displayType: this.vl.nativeElement.value
      });
    });
  }

  constructor() {}

  ngOnInit(): void {}
}
