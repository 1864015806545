import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bl-hvac-graphics-template-generator',
  templateUrl: './graphics-template-generator.component.html',
  styleUrls: ['./graphics-template-generator.component.css']
})
export class GraphicsTemplateGeneratorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
