import { Injectable } from '@angular/core';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import {
  IEquipmentTemplateIndexDB,
  EquipmentTemplateRowTypes,
  EquipmentTemplateObjectStores,
  IEquipmentTemplateRowDevice,
  IGraphicsTemplateIndexDB,
  GraphicsObjectStores,
  IEquipmentTemplateComponent
} from '@bertlabs-nova/types/nova-types';
import { BehaviorSubject } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable()
export class EquipmentTemplateGeneratorService {
  private _equipmentTemplates = new BehaviorSubject<{
    [uid: string]: IEquipmentTemplateIndexDB;
  }>({});
  private _selectedTemplate = new BehaviorSubject<IEquipmentTemplateIndexDB | null>(
    null
  );
  private _graphicsTemplates = new BehaviorSubject<
    { label: string; key: string }[]
  >([]);

  selectedTemplate$ = this._selectedTemplate.asObservable();
  equipmentTemplates$ = this._equipmentTemplates.asObservable();
  graphicsTemplates$ = this._graphicsTemplates.asObservable();

  newUid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  selectTemplate(templateId: string | null) {
    if (templateId)
      this._equipmentTemplates.pipe(take(1)).subscribe(templates => {
        this._selectedTemplate.next(templates[templateId]);
      });
    else this._selectedTemplate.next(null);
  }

  addRow() {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data.push({
        label: 'Label',
        heightPercent: 30,
        type: EquipmentTemplateRowTypes.newRow,
        devices: [],
        components: [],
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'left',
        widthPercent: 100
      });
      this._selectedTemplate.next(template);
    });
  }

  removeRow(index: number) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data.splice(index, 1);
      this._selectedTemplate.next(template);
    });
  }

  editRowInfo(
    index: number,
    newInfo: {
      label: string;
      type: EquipmentTemplateRowTypes;
      graphicsTemplate?: string;
      heightPercent: number;
      justifyContent: string;
      alignItems: string;
      alignSelf: string;
      widthPercent: number;
    }
  ) {
    console.log(newInfo);
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data[index] = {
        ...template.data[index],
        ...newInfo
      };
      this._selectedTemplate.next(template);
    });
  }

  addHeaderDevice(rowIndex: number) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      if (!template.data[rowIndex].headerDevices)
        template.data[rowIndex].headerDevices = [];
      template.data[rowIndex].headerDevices.push({
        label: 'New Header Device',
        displayType: 'unselected',
        parameterName: 'parameter_name',
        endpointPlaceholder: 'endpoint_placeholder',
        readingType: 'string',
        units: '',
        alignment: 'left',
        fontColor: 'black',
        fontSizeEm: 0.9
      });
      this._selectedTemplate.next(template);
    });
  }

  removeHeaderDevice(rowIndex: number, deviceIndex: number) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].headerDevices.splice(deviceIndex, 1);
      this._selectedTemplate.next(template);
    });
  }
  editHeaderDeviceInfo(
    rowIndex: number,
    deviceIndex: number,
    newInfo: IEquipmentTemplateRowDevice
  ) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].headerDevices[deviceIndex] = newInfo;
    });
  }

  addComponent(rowIndex: number) {
    this._selectedTemplate.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].components.push({
        label: 'New Component',
        structure: {
          data: [
            {
              height: 100,
              data: [
                {
                  isNested: false,
                  data: null,
                  width: 100
                }
              ]
            }
          ],
          isNested: true,
          width: 100,
          isMainStructure: true
        },
        width: 25,
        height: 100,
        justifyContent: 'left'
      });
      this._selectedTemplate.next(template);
    });
  }

  removeComponent(rowIndex: number, componentIndex: number) {
    this._selectedTemplate.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].components.splice(componentIndex, 1);
      this._selectedTemplate.next(template);
    });
  }

  editComponentInfo(
    rowIndex: number,
    componentIndex: number,
    newInfo: IEquipmentTemplateComponent
  ) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].components[componentIndex] = newInfo;
    });
  }

  addDevice(rowIndex: number) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].devices.push({
        label: 'New Device',
        displayType: 'unselected',
        parameterName: 'parameter_name',
        endpointPlaceholder: 'endpoint_placeholder',
        readingType: 'string',
        units: '',
        alignment: 'left',
        fontColor: 'black',
        fontSizeEm: 0.9
      });
      this._selectedTemplate.next(template);
    });
  }

  removeDevice(rowIndex: number, deviceIndex: number) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].devices.splice(deviceIndex, 1);
      this._selectedTemplate.next(template);
    });
  }

  editDeviceInfo(
    rowIndex: number,
    deviceIndex: number,
    newInfo: IEquipmentTemplateRowDevice
  ) {
    this.selectedTemplate$.pipe(take(1)).subscribe(template => {
      template.data[rowIndex].devices[deviceIndex] = newInfo;
    });
  }

  addNewTemplate() {
    const id = this.newUid();
    this._equipmentTemplates.pipe(take(1)).subscribe(templates => {
      templates[id] = {
        key: id,
        label: 'New Template',
        data: []
      };
      this._equipmentTemplates.next(templates);
    });
  }

  removeTemplate(templateId: string) {
    this._equipmentTemplates.pipe(take(1)).subscribe(templates => {
      delete templates[templateId];
      this._equipmentTemplates.next(templates);
    });
  }

  editTemplateLabel(templateId: string, label: string) {
    this._equipmentTemplates.pipe(take(1)).subscribe(templates => {
      templates[templateId].label = label;
      this._equipmentTemplates.next(templates);
    });
  }

  saveAllTemplates() {
    this.equipmentTemplates$.pipe(take(1)).subscribe(templates => {
      this._idb
        .clear(EquipmentTemplateObjectStores['equipment-templates'])
        .subscribe();

      const templatesArray = [];
      Object.keys(templates).forEach(key => {
        templatesArray.push(templates[key]);
      });

      this._idb
        .set(
          EquipmentTemplateObjectStores['equipment-templates'],
          templatesArray
        )
        .subscribe();
    });
  }

  // pull data from index-db and load up in the subjects
  // code to save data is in ./app-layout-generator.component.ts
  getLocalStoredData() {
    this._idb
      .getAll<IEquipmentTemplateIndexDB>(
        EquipmentTemplateObjectStores['equipment-templates']
      )
      .subscribe(data => {
        if (data) {
          const newTemplates = {};

          data.forEach(template => {
            newTemplates[template.key] = template;
          });

          this._equipmentTemplates.next(newTemplates);
        }
      });

    this._idb
      .getAll<IGraphicsTemplateIndexDB>(
        GraphicsObjectStores['graphics-templates']
      )
      .pipe(
        map(templateArray => {
          const newArray: { label: string; key: string }[] = [];
          templateArray.forEach(template => {
            newArray.push({
              label: template.label,
              key: template.key
            });
          });
          return newArray;
        })
      )
      .subscribe(templatesArray => {
        console.log(templatesArray);
        this._graphicsTemplates.next(templatesArray);
      });
  }

  constructor(private _idb: IndexDbService) {}
}
