import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';

@Component({
  selector: 'bl-graphical-label-card-progress',
  templateUrl: './graphical-label-card-progress.component.html',
  styleUrls: ['./graphical-label-card-progress.component.css']
})
export class GraphicalLabelCardProgressComponent implements OnInit {
  @Input() reading$: Observable<{ data: any; found: boolean }>;
  @Input() label: string;
  @Input() units: string;
  @Input() range: [number, number];

  progress: number;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {
    this.progress = (this.range[0]/this.range[1])*100;

  }
}
