import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import { PopupHandlerService } from '@bertlabs-nova/utils/error-popup-handler';
import { Observable, Observer } from 'rxjs';
import {
  PopupColors,
  IAppLayoutIndexDBObject,
  ConfigurationObjectStores,
  IWrapperInfo,
  IEquipmentInfo,
  IConfigBundle,
  EquipmentTemplateObjectStores,
  GraphicsObjectStores
} from '@bertlabs-nova/types/nova-types';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationUpdateService {
  syncConfigFromServer(version: number): Observable<IConfigBundle> {
    console.log('Getting data');
    const loggedInUser = JSON.parse(window.localStorage.getItem('user'));
    // pull new config from EP Register microservice
    // and set the new config to index-db, so that when app loads
    // next time, the new config is shown automatically
    return Observable.create((observer: Observer<IConfigBundle>) => {
      // do server call here
      this._http
        .post<{ config: IConfigBundle; version: number; changed: boolean }>(
          'http://104.211.209.15:3101/',
          {
            version,
            site: loggedInUser['site'],
            client: loggedInUser['client']
          }
        )
        .subscribe(res => {
          // if updated config is recieved
          console.log("Config received", res);
          
          if (res.changed) {
            console.log(res.config);
            // set new version
            res.config.appLayout.version = +res.version;
            // set app layout
            this.idb
              .set(
                ConfigurationObjectStores['app-layout'],
                res.config.appLayout
              )
              .subscribe();
            // clear indexdb
            this.idb
              .clear(ConfigurationObjectStores['wrapper-info'])
              .subscribe();
            this.idb
              .clear(ConfigurationObjectStores['equipment-info'])
              .subscribe();
            this.idb
              .clear(EquipmentTemplateObjectStores['equipment-templates'])
              .subscribe();
            this.idb
              .clear(GraphicsObjectStores['graphics-templates'])
              .subscribe();
            // set the updated data in indexDB
            this.idb
              .set(
                ConfigurationObjectStores['wrapper-info'],
                res.config.wrapperInfo
              )
              .subscribe();
            this.idb
              .set(
                ConfigurationObjectStores['equipment-info'],
                res.config.equipmentInfo
              )
              .subscribe();
            this.idb
              .set(
                EquipmentTemplateObjectStores['equipment-templates'],
                res.config.equipmentTemplate
              )
              .subscribe();
            this.idb
              .set(
                GraphicsObjectStores['graphics-templates'],
                res.config.graphicsTemplate
              )
              .subscribe();

            observer.next(res.config);
            observer.complete();
          } else {
            observer.complete();
          }
        });
    });
  }

  checkForUpdate() {
    // do a server call with the version of config stored locally
    // if the server has updated config, it will send that other wise
    // we will get OK response

    // to get latest config irrespective of version, pass 0 as the local version
    // it means that we want to get latest version of config

    this.idb
      .get<IAppLayoutIndexDBObject>(
        ConfigurationObjectStores['app-layout'],
        'data'
      )
      .subscribe(data => {
        // if data did not exist or data.version did not exist, dont do anything
        // it will be handled by configuration service, only update if version changed
        if (data) {
          const version = data.version;

          this.syncConfigFromServer(version).subscribe({
            next: () => {
              // show popup
              // this.popupService.showPopup({
              //   message:
              //     'The app has been updated. Click here to reload to view changes',
              //   color: PopupColors.info,
              //   duration: 5000,
              //   clickAction: () => {
              //     window.location.reload();
              //   }
              // });
            }
          });
        }
      });
  }

  constructor(
    private idb: IndexDbService,
    private popupService: PopupHandlerService,
    private _http: HttpClient
  ) {}
}
