<div *ngIf="service.selectedTemplate$ | async; else selectTemplate">
  <bl-equipment-template-editer></bl-equipment-template-editer>
</div>
<ng-template #selectTemplate>
  <div class="container">
    <div>Choose a Template to edit:</div>
    <div
      class="templateObj"
      *ngFor="let template of equipmentTemplates$ | async; let i = index"
    >
      <div
        style="cursor:pointer"
        (click)="service.selectTemplate(template.key)"
      >
        {{ template.label }}
      </div>
      <button
        style="background: salmon; color: white; margin-top: 5px;"
        (click)="service.removeTemplate(template.key)"
      >
        Delete
      </button>
    </div>
    <div style="margin-top: 20px">
      <button (click)="service.addNewTemplate()">New Template</button>
      <button (click)="service.saveAllTemplates()">Save Templates</button>
    </div>
  </div>
</ng-template>
