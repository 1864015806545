import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IndexDbService } from '@bertlabs-nova/utils/index-db';
import {
  EquipmentTemplateObjectStores,
  IEquipmentTemplateIndexDB,
  IEquipmentTemplateRow,
  IEquipmentInfo
} from '@bertlabs-nova/types/nova-types';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

import { NetworkLayerService } from '@bertlabs-nova/iot-device-model/network-layer';

@Component({
  selector: 'bl-equipment-renderer',
  templateUrl: './equipment-renderer.component.html',
  styleUrls: ['./equipment-renderer.component.css']
})
export class EquipmentRendererComponent implements OnInit, OnDestroy {
  private _templateInfoSub: Subscription;
  @Input() equipmentInfo: Observable<IEquipmentInfo | null>;

  private _endpointsToRemove: { [endpointPlaceholder: string]: string } = {};

  template$ = new BehaviorSubject<IEquipmentTemplateRow[]>([]);

  constructor(
    private _idb: IndexDbService,
    private _networkService: NetworkLayerService
  ) {}

  ngOnInit(): void {
    this._templateInfoSub = this.equipmentInfo.subscribe(info => {
      if (info) {
        // fetch template from idb
        this._idb
          .get<IEquipmentTemplateIndexDB>(
            EquipmentTemplateObjectStores['equipment-templates'],
            info.equipmentTemplate
          )
          .subscribe(template => {
            this.template$.next(template.data);
          });

        this._networkService.removeActiveEndpoints(this._endpointsToRemove);

        this._endpointsToRemove = info.endpoints;

        this._networkService.addActiveEndpoints(info.endpoints);
      } else this.template$.next([]);
    });
  }

  ngOnDestroy() {
    this._templateInfoSub.unsubscribe();
    this._networkService.removeActiveEndpoints(this._endpointsToRemove);
  }
}
