<div
  class="device"
  [ngStyle]="{ 'border-bottom': '3px solid ' + themeService.theme.green }"
>
  <div class="deviceLabel" [ngStyle]="{ color: themeService.theme.secondary }">
    {{ label }}
  </div>
  <div [ngStyle]="{ color: themeService.theme.dark }">
    <span class="deviceReading">{{
      (reading$ | async)?.data?.toString().slice(0, 5)
    }}</span
    ><span class="deviceUnits">{{ units }}</span>
  </div>
</div>
