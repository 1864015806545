import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLayoutDirective } from './app-layout.directive';

@NgModule({
  declarations: [AppLayoutDirective],
  imports: [CommonModule],
  exports: [AppLayoutDirective]
})
export class AppLayoutDirectiveModule {}
