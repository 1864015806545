<div class="ahu-component">
  <div class="top-bar">
    <a class="active" style="position: fixed" href="/home">
      <!-- <img src="../assets/laurus-labs/Image 1.png" /> | -->
      <img
        src="../assets/laurus-labs/Bert Labs.png"
        style="margin-top: 3px;"
        height="50px"
        width="223px"
      />
    </a>
    <h1 style="position: fixed;left: 41%;opacity: 1;color: #3D2C5A;">
      HOME
    </h1>
    <a style="position: fixed; right: 5%;" (click)="logout()">
      <img src="../assets/laurus-labs/sign-out.png" style="margin-top: 17px;" />
    </a>
  </div>

  <div class="side-nav-bar">
    <div>
      <img src="../assets/laurus-labs/home-icon.png" class="style-home" />
    </div>
    <img
      src="../assets/laurus-labs/set-1.png"
      class="style-home"
      style="margin-top:1087%;"
    />
    <img src="../assets/laurus-labs/settings-icon.png" class="style-home" />
    <!-- <img src="../assets/laurus-labs/info-icon.png" /> -->
  </div>

  <div class="row" style="margin-top:85px;">
    <div class="col" style="margin-left:15%;">
      <a routerLink="../blending" (click)="routeToBlending()">
        <img src="../assets/laurus-labs/blending.png" />
      </a>
    </div>
    <div class="col">
      <a routerLink="../compression" (click)="routeToCompression()">
        <img src="../assets/laurus-labs/compression.png"
      /></a>
    </div>
    <div class="col">
      <a
        routerLink="../compression-tablet-coating"
        (click)="routeToCompressionAndTabletCoating()"
      >
        <img src="../assets/laurus-labs/compression-tablet-coating.png" />
      </a>
    </div>
    <div class="col">
      <a routerLink="../dry-granulation" (click)="routeToDryGranulation()">
        <img src="../assets/laurus-labs/dry-granulation.png" />
      </a>
    </div>
  </div>

  <div class="row" style="margin-top:-285%;">
    <div class="col" style="margin-left:20%;">
      <a routerLink="../granulation" (click)="routeToGranulation()">
        <img src="../../assets/laurus-labs/Group 949.png" />
      </a>
    </div>
    <div class="col" style="margin-left:-8%;">
      <a
        routerLink="../granulation-blending"
        (click)="routeToGranulationBlending()"
      >
        <img src="../../assets/laurus-labs/granulation-blending.png" />
      </a>
    </div>
    <div class="col" style="margin-left:-8%;">
      <a routerLink="../tablet-coating" (click)="routeToTabletCoating()">
        <img src="../../assets/laurus-labs/tablet-coating.png" />
      </a>
    </div>
  </div>
</div>
