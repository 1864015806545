<div class="ahu-component">
    <a class="active" href="/home"> <img src="../assets/laurus-labs/Image 1.png"> |  <img src="../assets/laurus-labs/Bert Labs.png" style="margin-top: 3px;" height="50px" width="223px"> </a>
    <img src="../assets/laurus-labs/ahu-103.png" style="margin-top: 3px;" height="100%" width="100%">
     
    <div class="side-nav-bar">
      
    </div>

    <form class="ahu-form">
        <table>
            <thead>
                <th> Limit </th>
                <th> Set </th>
            </thead>
            <tbody>
                <tr>
                    <td> <span class="font-style"> Temp. </span> </td>
                    <td> <input type="text" class="input-ll"/> </td>
                    <td> <input type="text" class="input-ll"/> </td>
                </tr>
                <tr> 
                    <td> <span class="font-style"> %RH. </span> </td>
                    <td> <input type="text" class="input-ll"/> </td>
                    <td> <input type="text" class="input-ll"/> </td>
                    <td> <a href="#"><button type="button" class="btn-submit"> Apply </button> </a></td>
                </tr>
                <tr> 
                    <td> <span class="font-style"> CMH </span> </td>
                    <td> <input type="text" class="input-ll"/> </td>
                    <td> <input type="text" class="input-ll"/> </td>
                    <td> <a href="#"><button type="button" class="btn-submit"> Cancel </button> </a></td>
                </tr>
            </tbody>
        </table>
    </form>

    <form class="ahu-status-control">
        <table >
            <thead class="header"> 
                <th> AHU STATUS & CONTROL </th>
            </thead>
            <tbody>
                <tr>
                    <td class="font-text-style"> FAN TRIP STATUS </td>
                    <td > <button type="button" class="status-green"> NORMAL </button></td>
                    <td> <button type="button" disabled class="status-reset"> RESET </button></td>
                </tr>
                <tr>
                    <td class="font-text-style"> FAN RUN STATUS </td>
                    <td> <button type="button" class="auto"> AUTO </button></td>
                    <td> <button type="button" class="status-green"> ON </button></td>
                </tr>
                <tr>
                    <td class="font-text-style"> FAN FAIL ALARM </td>
                    <td> <button type="button" class="status-green"> NORMAL </button> </td>
                    <td> <button type="button" disabled class="status-reset"> RESET </button> </td>
                </tr>
                <tr>
                    <td class="font-text-style"> BUZZER STATUS </td>
                    <td> <button class="status-green"> OFF </button></td>
                    <td> <button type="button" disabled class="status-reset"> RESET </button></td>
                </tr>
                <tr>
                    <td class="font-text-style"> BUZZER </td>
                    <td> <button type="button" class="btn-test"> TEST </button></td>
                    <td> </td>
                </tr>
                <tr>
                    <td> <button type="button" class="btn-btm"> ALARM LIMIT </button> </td>
                    <td> <button type="button" class="btn-btm"> SCHEDULING </button> </td>
                    <td> <button type="button" class="btn-btm"> TREND </button></td>
                </tr>
            </tbody>
        </table>
    </form>
</div>