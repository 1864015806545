import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoaderModule } from '@bertlabs-nova/ui/loader';
import { ErrorHandlerModule } from '@bertlabs-nova/utils/error-popup-handler';
import { IndexDBModule } from '@bertlabs-nova/utils/index-db';
import {
  AuthenticationModule,
  AuthInterceptor
} from '@bertlabs-nova/authentication';
import { PageNotFoundRoute } from '@bertlabs-nova/navigation/page-not-found';
import { AppShellModule } from './app-shell/app-shell.module';
import { NavigationRouteLoaderModule } from '@bertlabs-nova/navigation/route-loader';
import { DefaultTheme } from '@bertlabs-nova/ui/theme';
import { SentryLoggerModule } from '@bertlabs-nova/utils/sentry-logger';
import { AppShellRoutingModule } from './app-shell/app-shell-routing.module';
import { RouterModule } from '@angular/router';
import { IAppTheme } from '@bertlabs-nova/types/nova-types';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: 'http://localhost:3002', options: {} };
@NgModule({
  declarations: [AppComponent],
  imports: [
    // angular modules
    BrowserModule,
    HttpClientModule,
    // native modules
    AppShellModule,
    // library modules
    NavigationRouteLoaderModule,
    ErrorHandlerModule,
    SentryLoggerModule,
    LoaderModule,
    IndexDBModule.forRoot('hvac', 6, [
      'iot-metadata',
      'app-layout',
      'wrapper-info',
      'equipment-info',
      'equipment-templates',
      'graphics-templates'
    ]),
    // routing modules
    // root router
    RouterModule.forRoot([]),
    AppShellRoutingModule,
    AuthenticationModule.forRoot(
      'http://localhost:3100/auth/login',
      'Building Management',
      '0'
    ),
    PageNotFoundRoute,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    {
      provide: DefaultTheme,
      useValue: {
        primary: '#01295F',
        secondary: '#2660A4',
        light: '#E9EFF6',
        highlight: '#E9EFF6',
        dark: '#3D4B5C',
        background: '#E5E5E5',
        green: '#33C065',
        red: '#F15A55',
        black: '#191919',
        yellow: '#FFC107',
        grey: '#888888'
      } as IAppTheme,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
