import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Roles } from '@bertlabs-nova/types/nova-types';

@Directive({
  selector: '[blIfRole]'
})
export class IfRoleDirective {
  @Input() set blIfRole(allowedRoles: Roles[]) {
    this.authService.getUser().subscribe(user => {
      if (allowedRoles.includes(user.role)) {
        this.vcRef.createEmbeddedView(this.templateRef);
      } else {
        this.vcRef.clear();
      }
    });
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
    private authService: AuthenticationService
  ) {}
}
