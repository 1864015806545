import { Component, Input } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';  
@Component({
    selector: 'ahu-home-screen-component',
    templateUrl: './ahu-home-screen.template.html',
    styleUrls: ['../ahu-screen-108.component.css']
})

export class AHUHomeScreenComponent {

    hw_valve_optimise: any;
    chw_valve_optimise: any;
    chiller_TR_value: any;
    chiller_kW_value: any;
    chiller_pump_value: any;
    hot_water_pump_value: any;
    coal_consumption_value: any;
    @Input() tabletCoating: boolean = false;
    location_URL: any;
    image_URL: any;

    constructor(private route: ActivatedRoute, private router: Router) {} 

    routeToBlending() {
        this.router.navigate(['app/blending']);
    }

    routeToGranulation() {
        this.router.navigate(['app/ahu-granulation']);
    }

    routeToTabletCoating() {
        this.router.navigate(['app/tablet-coating']);
    }

    routeToGranulationBlending() {
        this.router.navigate(['app/ahu-granulation-and-blending']);
    }

    routeToCompression() {
        this.router.navigate(['app/ahu-compression']);
    }

    routeToDryGranulation() {
        this.router.navigate(['app/ahu-dry-granulation']);
    }

    routeToCompressionAndTabletCoating() {
        this.router.navigate(['app/compression-tablet-coating']);
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            console.log('params ***', params);
          });
        this.tabletCoating = true;
        this.location_URL = window.location.href;
    };

    logout() {
        localStorage.removeItem('user');
        location.reload();
    }

//    kpiCalculationsForSavingForm(_chwValve, _hwValve) {
//       this.chiller_TR_value = (20.85126 * (1 - this.chw_valve_optimise) * 100)/2110.11;
//       this.chiller_kW_value = (20.85126 * (1 - this.chw_valve_optimise) * 100)/2110.11;
//       this.chiller_pump_value = 
//    }
}