import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'ahu-compression-and-blending-component',
    templateUrl: './ahu-compression-blending.template.html',
    styleUrls: ['../ahu-screen-108.component.css']
})

export class AHUCompressionAndBlendingComponent {

    locationURL: any;
    imageURL: any;

    constructor(private route: ActivatedRoute, private router: Router) {} 

    ngOnInit() {
      this.locationURL = window.location.href;
    };

    ngOnChange() {
      console.log('inside ngOnChange');
    }

    ngOnUpdate() {
      console.log('inside ng On Update **');
    }

    routeToAHUNumber(ahu) {
      if(ahu === 'ahu-173') {
        this.router.navigate(['app/ahu-173']);
      }
      else {
        this.router.navigate(['app/ahu-174']);
      }
    }

    navigateBack() {
      this.router.navigate(['app/ahu-home']);
    }

    navigateToHome() {
      this.router.navigate(['app/ahu-home']);
    }

    logout() {
      localStorage.removeItem('user');
      location.reload();
    }

}