export enum PopupColors {
  'success' = 'lime',
  'critical' = 'salmon',
  'warning' = 'yellow',
  'info' = 'dodgerblue'
}

export interface IBertlabsNovaPersistedPopup {
  message: string;
  color?: string;
  clickAction?: () => void;
}

export interface IBertlabsNovaPopup {
  message: string;
  color?: PopupColors;
  duration?: number;
  clickAction?: () => void;
}
