import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@bertlabs-nova/ui/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'bl-label-card-progress',
  templateUrl: './label-card-progress.component.html',
  styleUrls: ['./label-card-progress.component.css']
})
export class LabelCardProgressComponent implements OnInit {
  @Input() label: string;
  @Input() reading$: Observable<{ data: any; found: boolean }>;

  @Input() units: string;
  @Input() range: [number, number];

  progress = this.reading$.pipe(
    map(reading => {
      const prog =
        (+reading - +this.range[0]) / (+this.range[1] - +this.range[0]);
      let progEM = 8.55 * (1 - prog) + 0.15;
      if (progEM < 0.15) progEM = 0.15;
      if (progEM > 8.7) progEM = 8.7;
      return progEM + 'em';
    })
  );

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
